import { FormLabel, Text } from "@chakra-ui/react";
import { useRef, useState } from "react";

export const Disclaimer = ({ children, ...rest }) => {
    return (
        <Text
            fontSize="0.85rem"
            fontStyle="italic"
            marginTop="2px"
            color={rest?.color ? rest.color : "gray"}
        >
            {children}
        </Text>
    )
}

export const Helper = ({ children, ...rest }) => {
    return (
        <Text
            fontSize="1rem"
            fontStyle="italic"
            marginTop="2px"
            color={rest?.color ? rest.color : "gray"}
        >
            {children}
        </Text>
    )
}
export const reactSelectOptionCreator = (arr) => {
    if (arr) {
        let sample = [];

        arr.forEach(a => {
            sample.push({
                label: a, value: a
            })
        })
        return sample;
    }

    else return null;
}

export const createSimpleArray = async (arr) => {
    let simpleArray = []
    // See if it exists and needs cleaning
    if (arr?.length > 0 && typeof arr[0] === 'object') {
        arr.forEach(item => {
            simpleArray.push(item['value'])
        })
    }
    return simpleArray;
}

export const MyFormLabel = ({ children, ...rest }) => {
    return (
        <FormLabel
            marginBottom="5px"
            {...rest}
            fontSize="0.75rem"
            color={rest['important'] ? "#125f3f" : "inherit"}
            fontWeight={rest['important'] ? "500" : "400"}
        >{children}{rest['important'] ? "*" : ""} </FormLabel>
    )
}



export const MyFormFakeLabel = ({ children, ...rest }) => {
    return (
        <Text
            fontSize="0.75rem"
            mb="2px"
            color={rest['active'] ? '#125f3f' : '#000'}
            fontWeight={rest['active'] ? '700' : '400'}
        >{children}</Text>
    )
}

export const savedSearchtoQuery = async (filters) => {
    let cleanObj = {};
    let cleaner = Object.keys(filters).forEach(key => {
        // Regions
        if (key == 'regions' && filters[key]?.length > 0) {
            // Extract descriptions and send
            let theRegionsArr = filters['regions'];
            let cleanedRegions = theRegionsArr.map(reg => {
                const { place_id } = reg;
                return place_id;
            })

            cleanObj['placeId'] = cleanedRegions;
        }

        else if (filters[key] || filters[key] == false || filters[key]?.length > 0) {
            cleanObj[key] = filters[key];
        }
    })

    return cleanObj;
}

export const internalCheck = (host) => {
    if (host?.split('.')[0].trim() === 'internal') {
        return true;
    }

    else {
        return false
    }
}

export const useDebouce = (cb, delay = 300) => {
    const [timeoutInterrupted, setTimeoutInterrupted] = useState(false)
    let timeout = useRef<NodeJS.Timeout>();
    // let timeoutInterrupted = false;
    return (...args) => {
        if (timeoutInterrupted) {
            clearTimeout(timeout.current)
        }
        !timeoutInterrupted && setTimeoutInterrupted(true)
        timeout.current = setTimeout(() => {
            cb(...args)
            setTimeoutInterrupted(false)
            // timeoutInterrupted = true;
        }, delay)
    }
}

export function removeSentencesWithEmailsAndPhoneNumbers(text) {
    // Remove sentences with emails
    text = text.replace(/.*?\S+@\S+.*?(\n|$)/g, '');
    
    // Remove sentences with phone numbers
    text = text.replace(/.*?\d{3}[-.\s]?\d{3}[-.\s]?\d{4}.*?(\n|$)/g, '');
  
    return text;
  }

export function removeContactInformation(text) {
    // Remove phone numbers
    var phoneNumberRegex = /(\+\d{1,2}\s?)?(\()?\d{3}(\))?[-.\s]?\d{3}[-.\s]?\d{4}/g;
    text = text.replace(phoneNumberRegex, '');
  
    // Remove email addresses
    var emailRegex = /\S+@\S+\.\S+/g;
    text = text.replace(emailRegex, '');
  
    return text;
  }


export function replaceAfter7th(str: string): string {
    // Convert the string to an array of characters
    let characters: string[] = str.split('');

    // Loop through the characters starting from index 7
    for (let i: number = 30; i < characters.length; i++) {
        // Replace each character with 'X'
        if (characters[i] != ' ') {
            characters[i] = 'X';
        }
    }

    // Join the characters back into a string
    return characters.join('');
}