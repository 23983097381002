import {
  Box,
  Flex,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { MinusIcon, SmallAddIcon } from "@chakra-ui/icons";

const FaqCard = ({ item: { title, body }, last }) => {
  const [showBody, setShowBody] = useState(false);

  return (
    // <AccordionItem>
    //     {
    //         ({ isExpanded }) => (
    //             <>
    //                 <AccordionHeader w={"100%"} flexDir={"row"} display={"flex"} justifyContent={"space-between"}>
    //                     <Text fontWeight={500} textAlign={"left"}>{title}</Text>
    //                     {/* <AccordionIcon /> */}
    //                     <Icon name={isExpanded ? "minus" : "small-add"} size={"20px"} w={isExpanded ? "10px" : ""} mr={isExpanded ? "5px" : ""} color={"brand.800"} ml={"20px"} />
    //                 </AccordionHeader>

    //                 <AccordionPanel pb={4}>
    //                     {body}
    //                 </AccordionPanel>
    //             </>
    //         )
    //     }
    // </AccordionItem>

    <Flex
      flexDir={"column"}
      w={{ base: "100%", lg: "80%" }}
      ml={"auto"}
      mb={last ? "0px" : "30px"}
    >
      <Box
        w={"100%"}
        flexDir={"row"}
        display={"flex"}
        justifyContent={"space-between"}
        onClick={() => setShowBody(!showBody)}
        as={"button"}
        outline={"none"}
      >
        <Text fontWeight={500} textAlign={"left"}>
          {title}
        </Text>
        {showBody ? (
          <MinusIcon
            size={"20px"}
            w={showBody ? "10px" : ""}
            mr={showBody ? "5px" : ""}
            color={"brand.800"}
            ml={"20px"}
          />
        ) : (
          <SmallAddIcon
            size={"20px"}
            w={showBody ? "10px" : ""}
            mr={showBody ? "5px" : ""}
            color={"brand.800"}
            ml={"20px"}
          />
        )}
      </Box>

      <Text
        mt={showBody ? "20px" : "0"}
        fontSize={{ lg: "15px" }}
        lineHeight={{ lg: "23px" }}
        fontWeight={"300"}
        color={"gray.600"}
        height={showBody ? "auto" : 0}
        visibility={showBody ? "visible" : "hidden"}
        transition={"ease-in"}
      >
        {body}
      </Text>
    </Flex>
  );
};

export { FaqCard };
