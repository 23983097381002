import { Button } from '@chakra-ui/react'
import React from 'react'

const PrimaryButton = ({ label, ...rest }) => {
    return (
        <Button
            fontSize={"15px"}
            color="white"
            bg="brand.900"
            rounded={"30px"}
            fontWeight={"500"}
            _hover={{
                color: "white",
                bg: "brand.300"
            }}
            px={"50px"}
            py={"25px"}
            {...rest}
        >
            {label}
        </Button>
    )
}

const OutlineButton = ({ label, ...rest }) => {
    return (
        <Button
            ml={"15px"}
            fontSize={"15px"}
            // size="md"
            variant={"outline"}
            bg={"white"}
            color={"brand.900"}
            fontWeight={"500"}
            rounded={"30px"}
            borderColor={"brand.900"}
            px={"50px"}
            py={"25px"}
            _hover={{
                color: "brand.800",
                bg: "brand.100",
                borderColor: "brand.800"
            }}
            {...rest}
        >
            {label}
        </Button>
    )
}

export { PrimaryButton, OutlineButton }