
export const mapUserData = (user) => {
  const { uid, email, xa, emailVerified, userRole, industryRole } = user
  return {
    id: uid,
    email,
    token: xa,
    emailVer: emailVerified,
    uRole: userRole,
    iRole: industryRole
  }
}
