import { Box } from '@chakra-ui/react'
import Image from 'next/image'
import React from 'react'

const TrustedCompanies = ({ img, className }) => {
    return (
        <Box mr={"25px"} className={className} w={{ base: "24%", md: "auto" }}>
            <Image src={img} objectFit={"contain"} width={70} height={40} />
        </Box>
    )
}

export { TrustedCompanies }