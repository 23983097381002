// This file configures the intialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import { Integrations } from "@sentry/tracing";
import { CaptureConsole } from "@sentry/integrations";
import { env_check, isProduction } from './utils/utilties'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
let environment =
      window.location.host === "zonado.com"
        ? "production"
          : "development";

// if (env_check) {
    Sentry.init({
        dsn: SENTRY_DSN,
        tracesSampleRate: 1.0,
        // Note: if you want to override the automatic release value, do not set a
        // `release` value here - use the environment variable `SENTRY_RELEASE`, so
        // that it will also get attached to your source maps

        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.3,
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,
        environment,
    
        integrations: [
          // new Sentry.Replay(),
            new CaptureConsole({ levels: ["error"] })],
    })
// }