import { theme } from "@chakra-ui/react";

// Let's say you want to add custom colors
const customTheme = {
    ...theme,
    fonts: {
        heading: '"Jarkarta", sans-serif',
        body: "'Segoe UI', sans-serif",
        mono: "Menlo, monospace",
    },
    fontSizes: {
        xs: "0.75rem",
        sm: "0.875rem",
        md: "1rem",
        lg: "1.125rem",
        xl: "1.25rem",
        "2xl": "1.5rem",
        "3xl": "1.875rem",
        "4xl": "2.25rem",
        "5xl": "3rem",
        "6xl": "4rem",
    },
    colors: {
        ...theme.colors,
        brand: {
            900: "#125F3F",
            800: "#135F3f",
            700: "#4CCA62",
            600: "#125F3F90",
            500: "#125F3F90",
            400: "#125F3F90",
            300: "#125F3F90",
            200: "#125F3F90",
            100: "#F8FFFA",
        },
        black: "#243443",
        transparent: "transparent",
        white: "#fff",
        black: {
            100: "#000",
          
        },
        myGray: {
            50: '#F7F7F7',
            100: "#dadce0",
            200: "#3C4043",
            300: '#CECFD0'
        },
        inboxPageGray: {
            100: "#A1A1A3",
            200: "#646466"
        },
        
        green: {
            50: "#f8faf8",
            100: "#e8efe9",
            200: "#d7e4d8",
            300: "#c5d8c6",
            400: "#39AB65",
            500: "#125f3f",
            600: "#125f3f",
            700: "#629566",
            800: "#39793e",
            900: "#0e4a13",
        },
        blue:
        {
            50: '#e9f4fb',
            100: '#ccdae6',
            200: '#acc1d1',
            300: '#8ca8c0',
            400: '#6c8fae',
            500: '#243443',
            600: '#405c74',
            700: '#2e4253',
            800: '#1a2733',
            900: '#050e15',
        },
        red: {
            100: '#e32900',
            200: '#e32900',
            300: '#e32900',
            400: '#e32900',
            500: '#e32900',
            600: '#e32900',

        },
        
        oneGreen: {
            50: "#125f3f",
            100: "#125f3f",
            200: "#125f3f",
            300: "#125f3f",
            400: "#125f3f",
            500: "#125f3f",
            600: "#125f3f",
            700: "#125f3f",
            800: "#125f3f",
            900: "#125f3f",
        },
    },
};

export default customTheme;