import styled from "styled-components";

export const Header = styled.div`
  width: 100%;
  min-width: min-content;
  padding: 8px;
  position: fixed;
  background-color: white;
  z-index: 5;

  @media (max-width: 600px) {
    flex-direction
  }
`;

export const LogoAndHam = styled.div`
  flex: 0 0 200px;
  height: 48px;
  white-space: nowrap;
  align-items: center;
  display: flex;
`;

export const HamWrapper = styled.div`
  border-radius: 50%;
  display: inline-block;
  margin: 0 4px;
  padding: 12px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  /* height: 24px;
    width: 24px; */
  flex: 0 0 auto;

  &:hover {
    background-color: rgba(60, 64, 67, 0.08);
    outline: none;
  }
`;

export const LogoWrapper = styled.a`
  width: 90px;
  height: auto;
  margin-top: 0px;
  display: flex;

  &:hover {
    cursor: pointer;
  }
`;

export const LogoImg = styled.img`
  width: 90px;
  line-height: normal;
  position: relative;
`;

export const SearchWrapper = styled.div`
  justify-content: center;
  flex: 1 1 auto;
  height: 48px;
  white-space: nowrap;
  align-items: center;
  display: flex;
`;

export const InnerSearchWrapper = styled.div`
  padding-left: ${(props) => (props.inDrawer ? "0px" : "10px")};
  padding-right: ${(props) => (props.inDrawer ? "0px" : "10px")};
  width: 100%;
  flex: 1 1 auto;
  display: inline-block;
  height: 48px;
  max-width: 720px;
  white-space: normal;
`;

export const Searcher = styled.form`
  background: #f1f3f4;
  border: 1px solid transparent;
  border-radius: 8px;
  margin-left: auto;
  margin-right: auto;
  max-width: 720px;
  position: relative;
  transition: background 100ms ease-in, width 100ms ease-out;
`;
export const SearchText = styled.input`
  height: 24px;
  flex-grow: 1;
  padding: 0;
  padding-left: 2px;
  margin-left: ${(props) => (props.badges ? "0px" : "40px")};
  overflow: hidden;
  background: #f1f3f4;

  &:active {
    outline: none;
  }

  &:focus {
    outline: none;
  }
`;

export const BadgeFlex = styled.div`
  display: flex;
  padding: 8px;
  grid-row-gap: 16px;
  grid-column-gap: 8px;
  flex-wrap: ${(props) => (props.active ? "wrap" : "no-wrap")};
`;

export const ExpandButton = styled.button`
  padding: 0 5px;
  background: #f1f3f4;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0 5px;
  line-height: 0;
  position: absolute;
  right: 0;
  top: 0;
  padding: 8px;
  /* margin-top: 6px; */
  color: #80868b;
`;

export const SearchIcon = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 8px;
  /* margin: 3px; */
  line-height: 0;
  float: left;
  position: absolute;
  top: 0;
`;

export const SideButtons = styled.ul`
  /* flex: 1 0 auto; */
  height: 48px;
  white-space: nowrap;
  align-items: center;
  display: flex;
  list-style-type: none;
  list-style: none;
  justify-content: center;
`;

export const SideButton = styled.li`
  display: flex;
  align-items: center;
  color: #80868b;

  /* @media (max-width: 520px) {
        display: none;
    } */
`;

export const SideButtonAnchor = styled.a`
  align-items: center;
  background: transparent;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 40px;
  min-width: 90px;
  position: relative;
  text-decoration: none;
`;

export const SideButtonText = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
`;

export const DrawerNavBlocks = styled.div`
  align-items: center;
  border-radius: 0 50px 50px 0;
  display: flex;
  height: 48px;
  padding: 0;
  width: 240px;
  outline: 1px solid transparent;
  color: ${(props) =>
    props.name == props.pathname && !props.feedback ? "#125F3F" : "#3c4043"};
  cursor: pointer;
  background-color: ${(props) =>
    props.name == props.pathname && !props.feedback ? "#D2E1DB" : "#fff"};

  &:hover {
    background-color: #f1f3f4;
    outline: 1px solid transparent;
  }
`;

export const IconHolder = styled.div`
  height: 24px;
  width: 24px;
  margin: 0 24px;

  & > span {
    height: 24px;
  }
`;

export const TextHolder = styled.div`
  color: inherit;
  font-weight: 400;
  font: 500 14px/20px "Jarkarta", Arial, sans-serif;
  letter-spacing: 0.25px;
`;
