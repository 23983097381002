import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import initFirebase from '../auth/initFirebase'
import {
  removeUserCookie,
  setUserCookie,
  getUserFromCookie,
} from './userCookies'
import { mapUserData } from './mapUserData'
import { onIdTokenChanged, signOut } from 'firebase/auth'
import { auth } from '../../src/config/firebase'

initFirebase()

const useUser = () => {
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const router = useRouter()

  const logout = async () => {
    return signOut(auth)
      .then(() => {
        // Sign-out successful.
        // router.push("/listings")
      })
      // .catch((e) => {
      //   clientError(e)
      // })
  }

  useEffect(() => {
    // Firebase updates the id token every hour, this
    // makes sure the react state and the cookie are
    // both kept up to date
    const cancelAuthListener = onIdTokenChanged(auth, (user) => {
      if (user) {
        const userData = mapUserData(user)
        setUserCookie(userData)
        setUser(userData)
        
        setLoading(false)
      } else {
        removeUserCookie()
        setUser(null)
        setLoading(false)
      }
    })

    const userFromCookie = getUserFromCookie()
    if (!userFromCookie) {
      // router.push('/signup')
      return
    }
    setUser(userFromCookie)

    return () => {
      cancelAuthListener()
    }

  }, [])

  return { user, loading, logout }
}

export { useUser }
