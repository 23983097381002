import React from 'react'
import { Box, Flex, Image } from '@chakra-ui/react'
import { SectionHeader, SemiHeaderText } from '../../styles/typography'
import { useAuth } from '../../utils/auth/newAuth'
import SignUpComponent from '../Auth/Signup/SignupModal2'

export const SignupBanner = ({ header, semiHeader }) => {

    const { user } = useAuth()

    // if (user) {
    //     return null
    // }
    
    return (
        <Flex flexDir={{ base: "column-reverse", md: "row" }} justifyContent={"space-between"} alignItems={"center"} my={{ base: "64px", lg: "80px" }}> 
            {!user &&
                <Flex flexDir={"column"} w={{ md: "49%" }} px={{ lg: "50px" }} py={"20px"}>
                    <Box border={"1px"} borderColor={"gray.200"} rounded={"20px"}>
                        <SignUpComponent size="small"/>
                    </Box>
                </Flex>
            }

            <Flex 
                flexDir={"column"} 
                w={!user ? { md: "42%" } : { md: "100%" }} 
                px={{ base: "0", lg: "50px" }} 
                py={{ md: "26px" }} 
                pl={{ base: "0", 
                md: "20px", 
                lg: "0" }}
                textAlign={user ? 'center' : 'inherit'}
                >
                <SectionHeader text={header} />
                <SemiHeaderText text={semiHeader} mt={"30px"} mb={"56px"} fontWeight={"400"} />

                <Image

                    src={"/images/newLanding/hiw2.png"} 
                    marginTop={"32px"} 
                    w={["100%", "350px", "100%"]} 
                    mx={"auto"} 
                    maxW={user ? '400px' : 'auto'}
                    />
            </Flex>
        </Flex>
    )
}