import styled, { css } from "styled-components";
// import Image from "next/image";

export const LandingContainer = styled.div``;

export const WrapperStyle = styled.div`
  width: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 23px;
  padding-right: 23px;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  height: 55px;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1000;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  /*
    padding-top: 30px;
    padding-bottom: 30px;
    @media (max-width: 992px) {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  */
`;

export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: 578px) {
    & > *:first-child {
      display: none;
    }
  }
`;

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderTitle = styled.h1`
  font-size: 2rem;
`;

export const MobileToggle = styled.div`
  display: none;
  top: 0;
  right: ${(props) => (!props.show ? "100%" : 0)};
  bottom: 0;
  width: 100vw;
  transition: all 0.5s ease-in-out;
  @media (max-width: 960px) {
    position: fixed;
    padding: 22px;
    background-color: white;
    z-index: 1;
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-items: center;
  }
`;

export const HomeBanner = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 766px) {
    flex-direction: column;
    margin-top: 50px;
  }
`;

export const LeftBanner = styled.div`
  width: 60%;
  padding: 0 15px 0 0;

  @media (max-width: 768px) {
    width: 45%;
  }

  @media (max-width: 766px) {
    width: 100%;
  }

  @media (max-width: 576px) {
    width: 100%;
    padding: 0;
  }
`;

export const RightBanner = styled.div`
  width: 50%;
  // height: 500px;
  padding: 0 0 0 15px;

  & > div {
    height: 100%;
  }

  @media (max-width: 766px) {
    width: 100%;
    margin-top: 70px;
    padding: 0;
  }
`;

// export const NextImage = styled.img<Image>`
//     padding: 20px;
// `

export const BrowseButton = styled.div`
  display: none;
  margin-left: 21px;
  font-size: 16px;
  letter-spacing: 0.5px;
  cursor: pointer;
  & > div {
    display: flex;
    position: relative;
    align-items: center;
    padding: 6px 16px;
    ${(props) => {
      if (props.toggle) {
        return css`
          background-color: #f2f2f2;
          color: #74c493;
        `;
      }
    }}
  }
  &:hover {
    & > div {
      background-color: #f2f2f2;
      color: #74c493;
    }
  }
  @media (min-width: 578px) {
    display: block;
  }
`;

export const BrowseContainer = styled.div`
  width: 100%;
  top: 55px;
  background-color: white;
  z-index: 1000;
  padding: 15px 40px 24px;
  display: flex;
  flex-direction: row;
  position: absolute;
  border-top: 1px solid #e6e6e6;
`;

export const BrowseContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid #e4e4e4;
  width: 100%;
  max-width: 323px;
  & > div:nth-child(${(props) => props.indexOfChosen}) {
    color: #39ab65;
    background-color: #f7f7f7;
  }
`;

export const BrowseContentLeft = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 3px 8px;
  padding: 4px 8px;
  &:hover {
    color: #39ab65;
    background-color: #f7f7f7;
  }
  & > p {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 1px;
  }
`;

export const BrowseContentRight = styled.div`
  display: flex;
  margin: 0 8px;
  padding: 2px 8px;
  color: #767676;
  & > a > p {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 1px;
  }
  &:hover {
    color: #39ab65;
    background-color: #f7f7f7;
  }
`;

export const BrowseContentTitleContainer = styled.div`
  display: flex;
  margin: 0 8px;
  padding: 4px 8px;
  align-items: center;
  &:hover {
    & > svg {
      color: #39ab65;
    }
  }
`;

export const SidebarBrowseContainer = styled.div`
  padding-top: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid #e6e6e6;
  & > div > div {
    border-top: 1px solid #e6e6e6;
  }
  & > div > div > p {
    font-family: "Jarkarta", sans-serif;
    padding: 12px 0;
    font-size: 24px;
  }
`;

export const SidebarBrowseContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16px;
  background-color: white;
  & > div:nth-child(2) > a > p {
    font-family: "Jarkarta", sans-serif;
    border-top: 1px solid #e6e6e6;
    padding: 12px 0;
    font-size: 24px;
    :last-of-type {
      border-bottom: 1px solid #e6e6e6;
    }
  }
`;
