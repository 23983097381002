
import React from 'react';
import styled from 'styled-components';
import { Input as ChakraInput, Flex, Textarea } from "@chakra-ui/react"
import * as palette from '../../../../hoc/Layout/Theme'

export const SubscribeEmail = ({ field, width, form: { touched, errors }, ...props }) => {
    return (
        <InputWrapper hasRound>
            <StyledInput width={width} hasRound {...field} {...props} />
            <Error show={errors[field.name] && touched[field.name]}>{errors[field.name]}</Error>
        </InputWrapper>
    );
}

export const ContactInput = ({ field, width, form: { touched, errors }, ...props }) => {
    return (
        <ContactWrapper hasRound>
            <CustomInput {...props}  {...field} />
            <Error show={errors[field.name] && touched[field.name]}>{errors[field.name]}</Error>
        </ContactWrapper>
    );
}

export const ContactTextArea = ({ field, width, form: { touched, errors }, ...props }) => {
    return (
        <ContactWrapper hasRound fullWidth>
            <CustomTextArea {...props}  {...field} />
            <Error show={errors[field.name] && touched[field.name]}>{errors[field.name]}</Error>
        </ContactWrapper>
    );
}

const Input = ({ field, width, form: { touched, errors }, ...props }) => {
    return (
        <InputWrapper >
            <StyledInput width={width} {...field} {...props} />
            <Error show={errors[field.name] && touched[field.name]}>{errors[field.name]}</Error>
        </InputWrapper>
    );
}


const InputWrapper = styled.div`
width: 100%;
display: flex;
flex-direction: column;
border-radius: ${props => props.hasRound ? '30px' : "2px"};
`
const ContactWrapper = ({ fullWidth, children }) => <Flex flexDir={{ base: "column" }} mb={"20px"} w={{ base: "100%", lg: fullWidth ? "100%" : "48%" }} >{children}</Flex>

const StyledInput = styled.input`
padding: 0px 4px;
height: 40px;
margin-top: 0px;
font-size: 14px;
font-weight: 400;
outline: 0 !important;
border: 1px solid #d8d8d8;
border-radius: ${props => props.hasRound ? '30px' : "2px"};
width: ${({ width }) => (width ? width : '100%')};
padding-right: ${props => props.hasRound ? '120px' : "2px"};
padding-left: ${props => props.hasRound ? '20px' : "10px"};
`;

const CustomInput = ({ ...props }) => <ChakraInput

    /* type="text" */
    /* name="name" */
    /* id="name" */
    /* w={{ lg: "48%" }} */
    rounded={"28px"}
    fontSize={"14px"}
    lineHeight={"19px"}
    _focus={{ borderColor: "teal" }}
    /* placeholder={"Full name"} */
    {...props}
/>;

const CustomTextArea = ({ ...props }) => <Textarea
    w={"100%"}
    rounded={"10px"}
    fontSize={"15px"}
    lineHeight={"19px"}
    _focus={{ borderColor: "teal" }}
    placeholder={"How can we help"}
    h={"206px"}
    alignSelf={"start"}
    {...props}
/* mb={"20px"} */
/>;

const Error = styled.div`
color: ${palette.errorRed};
font-size: 12px;
text-align: left;
padding: 5px 9px;
display: ${({ show }) => (show ? 'inline' : 'none')};
transition: all 0.3s ease;
`

export default Input;