import React, { useEffect } from 'react';
import Auxiliary from '../Auxiliary/Auxiliary';
import styled from 'styled-components';
import InternalHeader from '../../components/AppHeader/InternalHeader';
import ExternalHeader from '../../components/AppHeader/ExternalHeader';
import NewFooter from '../../components/Footer/newFooter';
import { useAuth } from '../../utils/auth/newAuth';
import { clientAnalytics } from '../../utils/utilties';

const MainWrapper = styled.div`
    width: 100%;
    min-height: 100vh;
    padding-top: 60px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    /* background-color: #F7F9FB; */
    background-color: white;
    text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`
function Layout(props) {
    const {user, profile } = useAuth()
    useEffect(() => {
        if (user) {
          clientAnalytics.identify(user.uid, {
            email: user.email,
            name: user?.displayName,
            lastRefresh: user?.reloadUserInfo?.lastRefreshAt
          })
        }
      }, [user])

      
    return (props?.internalReset) ?
        <main >
            <InternalHeader />
            {props.children}
        </main>

    :
        (
            (props?.newRoute || props?.isProfessional) ||
            (!profile && props?.componentName === 'Landing')
        ) ?
            <main className={"useFont"}>
            <ExternalHeader/>
            {props.children}

            <NewFooter faq={props?.faq} isProfessional={props?.isProfessional} />
        </main>
        :
        (props?.blankRoute) ?
            <main>
                {props.children}

            </main>
            :
        <Auxiliary>
            <main>
                <InternalHeader />
                <MainWrapper>
                    {props.children}
                </MainWrapper>
            </main>
        </Auxiliary>    
}

export default Layout;

