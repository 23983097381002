import React, { useEffect, useState } from "react";
import Layout from "../hoc/Layout/Layout";
import { FirebaseProvider, withFirebaseHOC } from "../src/config/context";
import Firebase from "../src/config/firebase";
import * as Sentry from '@sentry/nextjs'

import Head from "next/head";
import { ProvideAuth, useAuth } from "../utils/auth/newAuth";
import { GlobalStateProvider } from "../utils/contexts/QueryStateContext";
import { ChakraProvider } from "@chakra-ui/react";
import AOS from "aos";
import "aos/dist/aos.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { useRouter } from "next/router";
import "../styles/index.css";
import NProgress from "nprogress"; //nprogress module
import "nprogress/nprogress.css"; //styles of nprogress//Binding events.
import customTheme from "../theme";
import LogRocket from 'logrocket';
import dayjs from 'dayjs'
import { datadogRum } from '@datadog/browser-rum';
import { clientAnalytics, env_check, isProduction } from "../utils/utilties";
import { Integrations } from "@sentry/nextjs";
import { CaptureConsole } from "@sentry/integrations";

const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

// Track client-side page views with Segment
// router.events.on('routeChangeComplete', (url) => {
//     window?.analytics.page(url)
// });
const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

NProgress.configure({ showSpinner: false });

const MyApp = (props) => {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url, { shallow }) => { };

    router.events.on("routeChangeStart", () => NProgress.start());
    router.events.on("routeChangeComplete", () => NProgress.done());
    router.events.on("routeChangeError", () => NProgress.done());

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off("routeChangeStart", () => handleRouteChange);
      router.events.off("routeChangeComplete", () => handleRouteChange);
      router.events.off("routeChangeError", () => handleRouteChange);
    };
  }, [router]);
  const { Component, pageProps, err } = props;
  const [recordingURL, setRecordingURL] = useState(null)

  useEffect(() => {
    // internet explorer badge
    if (!!document.documentMode) {
      alert(
        "Your web browser is out of date and not supported by Zonado. Update your browser for more security, speed and the best experience."
      );
    }

    // firebase analytics watch
    if (env_check && isProduction(window.location.hostname)) {


      datadogRum.init({
        applicationId: '3233aa9b-1c30-400b-9087-156306295d04',
        clientToken: 'pub651d6e6ca25c10cb1d8d8ed8b1ed0dc9',
        site: 'datadoghq.com',
        service: 'zonado',
        env: 'prod',
        // Specify a version number to identify the deployed version of your application in Datadog 
        // version: '1.0.0',
        sampleRate: 10,
        sessionReplaySampleRate: 100,
        trackInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'allow'
      });
      datadogRum.startSessionReplayRecording();
      let environment =
      window.location.host === "zonado.com"
        ? "production"
          : "development";
    //   Sentry.init({
    //     dsn: SENTRY_DSN,
    //     tracesSampleRate: 1.0,
    //     // Note: if you want to override the automatic release value, do not set a
    //     // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    //     // that it will also get attached to your source maps

    //     // This sets the sample rate to be 10%. You may want this to be 100% while
    //     // in development and sample at a lower rate in production
    //     replaysSessionSampleRate: 0.3,
    //     // If the entire session is not sampled, use the below sample rate to sample
    //     // sessions when an error occurs.
    //     replaysOnErrorSampleRate: 1.0,
    //     environment,
    
    //     integrations: [new Sentry.Replay(),
    //         new Integrations.BrowserTracing(),
    //         new CaptureConsole({ levels: ["error"] })],
    // })
    }

    // animate on scroll package
    AOS.init();




  }, []);
  const queryClient = new QueryClient();

  // async function propertyTypesToDisplay() {
  //   await getPropTypesWithListings()
  // }
  // useEffect(() => {
  //   propertyTypesToDisplay()
  // }, [])

  return (
    <>
      <Head>
        <title key="title">
          Zonado - Canadian Businesses and Commercial Real Estate Opportunities
        </title>
        <meta
          key="description"
          name="description"
          content="Zonado transforms the way businesses and commercial real estate are bought and sold in Canada. Sign up Free Today."
        />
        <meta
          key="og:title"
          property="og:title"
          content="Zonado - Canadian Business and Commercial Real Estate Opportunities"
        />
        <meta
          key="og:description"
          property="og:description"
          content="Zonado transforms that way businesses and commercial real estate are bought and sold in Canada."
        />

        <meta
          key="og:image"
          property="og:image"
          content="https://firebasestorage.googleapis.com/v0/b/goldmine-7d9f7.appspot.com/o/compressed%2Fog_1200x1200.png?alt=media&token=6ab21097-15f5-4e6c-88cf-d4989408d62f"
        />

        {/* <meta key="og:url" property="og:url" content="https://zonado.com/" /> */}
        <meta property="og:type" content="website" />
        <meta property="article:author" content="Zonado Inc." />
        <meta property="og:site_name" content="Zonado" />

        <link
          key="fav1"
          rel="apple-touch-icon"
          sizes="180x180"
          href="favicons/apple-touch-icon.png"
        />
        <link
          key="fav2"
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="favicons/favicon-32x32.png"
        />
        <link
          key="fav3"
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="favicons/favicon-16x16.png"
        />
        <link
          key="fav5"
          rel="mask-icon"
          href="favicons/safari-pinned-tab.svg"
          color="#5bbad5"
        />
        {/* <link rel="preload" href="fonts/plus-jakarta-sans/PlusJakartaDisplay-Regular.otf" as="font" type="font/otf" crossorigin></link> */}
        <link key="fav6" rel="shortcut icon" href="favicons/favicon.ico" />
        <meta key="msFav" name="msapplication-TileColor" content="#2d89ef" />
        <meta
          name="msapplication-config"
          content="favicons/browserconfig.xml"
        />
        <meta name="theme-color" content="#ffffff" />

        {/*  Add to homescreen for Chrome on Android */}
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="application-name" content="Zonado" />

        {/* Add to homescreen for Safari on iOS */}
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="white" />
        <meta name="apple-mobile-web-app-title" content="Zonado" />
        <meta name="apple-mobile-web-app-status-bar-style" content="#ffffff" />

        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />

        <link rel="manifest" href="/manifest.json" />
        <meta
          name="facebook-domain-verification"
          content="6fklv95hhxyq9u6kshiujfrzuon4ew"
        />
      </Head>
      <QueryClientProvider client={queryClient} contextSharing={true}>
        <ProvideAuth >
          <ChakraProvider theme={customTheme}>
            <FirebaseProvider value={Firebase}>
              <GlobalStateProvider recordingURL={recordingURL}>
                <Layout
                  internalReset={Component?.internalReset}
                  blankRoute={Component?.blankRoute}
                  newRoute={Component?.newLayout}
                  componentName={Component?.componentName}
                  isProfessional={Component?.isProfessional}
                  faq={Component.faq}
                >
                  <Component {...pageProps} err={err} />
                </Layout>
              </GlobalStateProvider>
            </FirebaseProvider>
          </ChakraProvider>
        </ProvideAuth>
      </QueryClientProvider>
    </>
  );
};

export default MyApp;
