import mixpanel from 'mixpanel-browser';
import { isProduction } from '../../utils/utilties';
mixpanel.init('e626affd60c0e5c787461f16ba603bf4');

let env_check = process.env.NODE_ENV === 'production';

let actions = {
    identify: (id) => {
        if (env_check) mixpanel.identify(id);
    },
    alias: (id) => {
        if (env_check) mixpanel.alias(id);
    },
    track: (name, props) => {
        if (env_check) mixpanel.track(name, props);
    },
    people: {
        set: (props) => {
            if (env_check) mixpanel.people.set(props);
        },
        set_once: (props) => {
            if (env_check) mixpanel.people.set_once(props);
        },
        increment: (props) => {
            if (env_check) mixpanel.people.increment(props);
        },
    },
};

function getQueryParam(url, param) {
    // Expects a raw URL
    param = param.replace(/[[]/, "\[").replace(/[]]/, "\]");
    var regexS = "[\?&]" + param + "=([^&#]*)",
        regex = new RegExp(regexS),
        results = regex.exec(url);
    if (results === null || (results && typeof (results[1]) !== 'string' && results[1].length)) {
        return '';
    } else {
        return decodeURIComponent(results[1]).replace(/\W/gi, ' ');
    }
};
function campaignParams() {
    var campaign_keywords = 'utm_source utm_medium utm_campaign utm_content utm_term'.split(' ')
        , kw = ''
        , params = {}
        , first_params = {};
    var index;
    for (index = 0; index < campaign_keywords.length; ++index) {
        kw = getQueryParam(window.location, campaign_keywords[index]);
        if (kw.length) {
            params[campaign_keywords[index] + ' [last touch]'] = kw;
        }
    }
    for (index = 0; index < campaign_keywords.length; ++index) {
        kw = getQueryParam(window.location, campaign_keywords[index]);
        if (kw.length) {
            first_params[campaign_keywords[index] + ' [first touch]'] = kw;
        }
    }

    if (env_check && isProduction(window.location.hostname)) {
        window?.analytics?.identify({
            ...params
        })
        mixpanel.people.set(params);
        mixpanel.people.set_once(first_params);
        mixpanel.register(params);
        mixpanel.identify();
      }
}
if (typeof window !== 'undefined') {
    // console.log('windowloc', window.location)
    campaignParams();
}

export let Mixpanel = actions;