import styled, { css } from "styled-components";

const baseInput = css`
  margin-bottom: 10px;
`;

export const HalfContainer = styled.div`
  ${baseInput}
  width: 48%;
`;

export const FullContainer = styled.div`
  ${baseInput}
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Heading1 = styled.h1`
  font-size: 24px;
  line-height: 37px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: black;
  margin-top: 0px;
  text-align: center;
  margin-bottom: 5px;

  @media (max-width: 350px) {
    font-size: 23px;
  }
`;
export const MarginContainer = styled.div`
  margin-bottom: 10px;
  position: relative;
  ${(props) => {
    if (props.names) {
      return css`
        display: flex;
        justify-content: space-between;
      `;
    }
  }};
`;

export const Forgot = styled.a`
  color: #125f3f;
  width: 150px;

  &:hover {
    cursor: pointer;
  }
`;
