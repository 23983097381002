import { FirebaseApp, getApps, initializeApp } from 'firebase/app';
import { Analytics, getAnalytics } from 'firebase/analytics';
import { FirebaseStorage, getStorage } from 'firebase/storage'
import { 
    getAuth, 
    onAuthStateChanged,
    signOut,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    sendEmailVerification,
    getIdToken,
    Auth,
} from 'firebase/auth'
import { getFirestore, collection, setDoc, doc, Firestore, addDoc, onSnapshot } from "firebase/firestore";
import { Functions, getFunctions } from 'firebase/functions';


import getStripe from '../../utils/getStripe';
import NProgress from 'nprogress'; //nprogress module

import React, { useEffect, useState } from "react";
import { Mixpanel } from '../../components/Utility/Mixpanel'

// const functions = firebase.functions(); // needs to be converted to v9 for use in FooterBanner.js

// if (globalThis?.window?.location?.hostname?.includes("localhost") ?? false) {
//     auth.useEmulator("http://localhost:9099");
//     // firestore.useEmulator("http://localhost:8080") 
//     functions.useFunctionsEmulator("http://localhost:5001")
// }

export const config = {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_PUBLIC_API_KEY,
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
    storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,

};



// Initialize Firebase
let app: FirebaseApp;
export let firebaseAnalytics: Analytics;
export let db: Firestore
export let storage: FirebaseStorage
export let auth: Auth
export let functions: Functions

if (!getApps().length) {
    app = initializeApp(config);
    if (typeof window !== 'undefined') {
        firebaseAnalytics = getAnalytics(app)
    }
    db = getFirestore(app)
    storage = getStorage(app)
    functions = getFunctions(app, "us-central1")
    auth = getAuth(app);
}


const Firebase = {
    // auth
    loginWithEmail: (email, password) => {
        return signInWithEmailAndPassword(auth, email, password)
    },
    signupWithEmail: (email, password) => {
        return createUserWithEmailAndPassword(auth, email, password)
    },
    signOut: () => {
        return signOut(auth)
    },

    // verifyEmail: () => async {
    //     const user = auth.currentUser;
    //     await auth.currentUser.sendEmailVerification()
    // },

    current: () => {
        return auth.currentUser;
    },

    currentId: () => {
        return auth.currentUser.uid;
    },

    checkUserAuth: user => {
        return onAuthStateChanged(auth, user)
    },
    

    createNewUser: userData => {
        const userDoc = doc(db, `users/${userData.uid}`)
        // return db
        //     .collection('users')
        //     .doc(`${userData.uid}`)
        //     .set(userData)
        // firebase v9-equivalent:
        return setDoc(userDoc, userData)
    }
}



export async function verifyEmail() {
    try {
        await sendEmailVerification(auth.currentUser);
    }
    catch (err) {
        alert(err)
    }
}

export const AuthContext = React.createContext(null);

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [pending, setPending] = useState(true);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            setCurrentUser(user)
            setPending(false)
        });
    }, []);

    if (pending) {
        return <>Loading...</>
    }

    return (
        <AuthContext.Provider
            value={{
                currentUser, pending
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

// export function useAuth() {
//     const context = useContext(AuthContext)
//     return context
// };

let env_check = process.env.NODE_ENV === 'production';


// Payments
export const createCheckoutSession = async (uid, hook, annual) => {
    NProgress.start()
    Mixpanel.track("Visited Checkout Page", { "User": uid });
    const checkoutSessionsCollection = collection(db, `customers/${uid}/checkout_sessions`)
 
    const checkoutSessionRef = await addDoc(checkoutSessionsCollection, {
        price: env_check ? 'price_1J60AHELvszjRUrkhOV5N48z' : 'price_1J60BeELvszjRUrkDz2lfN3H',
        success_url: window.location.origin,
        cancel_url: window.location.origin,
        tax_rates: env_check ? ['txr_1IRMLgELvszjRUrkr6asMMUR'] : ['txr_1IRgQUELvszjRUrkhQ0rlb67'],
    })
        // db.collection('customers')
        // .doc(uid)
        // .collection('checkout_sessions')
        // .add({
        //     price: env_check ? 'price_1J60AHELvszjRUrkhOV5N48z' : 'price_1J60BeELvszjRUrkDz2lfN3H',
        //     success_url: window.location.origin,
        //     cancel_url: window.location.origin,
        //     tax_rates: env_check ? ['txr_1IRMLgELvszjRUrkr6asMMUR'] : ['txr_1IRgQUELvszjRUrkhQ0rlb67'],
        // });
    // Wait for the CheckoutSession to get attached by the extension
    onSnapshot(checkoutSessionRef, async (snap) => {
        const { error, sessionId } = snap.data();
        if (error) {
            // Show an error to your customer and 
            // inspect your Cloud Function logs in the Firebase console.
            alert(`An error occured: ${error.message}`);
            NProgress.done()
        }
        if (sessionId) {
            // We have a session, let's redirect to Checkout
            // Init Stripe
            const stripe = await getStripe();
            stripe.redirectToCheckout({ sessionId });
            NProgress.done()
        }
    })
    // checkoutSessionRef.onSnapshot(async (snap) => {
    //     const { error, sessionId } = snap.data();
    //     if (error) {
    //         // Show an error to your customer and 
    //         // inspect your Cloud Function logs in the Firebase console.
    //         alert(`An error occured: ${error.message}`);
    //         NProgress.done()
    //     }
    //     if (sessionId) {
    //         // We have a session, let's redirect to Checkout
    //         // Init Stripe
    //         const stripe = await getStripe();
    //         stripe.redirectToCheckout({ sessionId });
    //         NProgress.done()
    //     }
    // });
}


export async function getToken() {
    const token = await getIdToken(auth.currentUser, true);
    return token;
}


// export const notifs = firebase.database(notifInstance);  
export default Firebase;