import { Button, Flex, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { getAllPostsForHome } from "../../lib/api";
import { BlogCard } from "../Landing";
import Link from "next/link";
import { ArrowForwardIcon } from "@chakra-ui/icons";

export const InsightsBanner = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getAllPostsForHome(false).then((res) => {
      setData(res);
    });
  }, []);

  return (
    <Flex mt={["96px", "120px"]} flexDir={"column"}>
      <Flex
        justifyContent={"space-between"}
        flexDir={{ base: "column", md: "row" }}
      >
        <Text
          fontWeight={"500"}
          textAlign={{ base: "center", md: "start" }}
          mb={{ base: "30px", md: "0" }}
          fontSize={{ base: "28px", lg: "38px" }}
          lineHeight={{ base: "27px", lg: "36px" }}
          color={"brand.900"}
          fontStyle={"bold"}
        >
          Zonado Insights
        </Text>

        <Link href="/listings" passHref>
          <a>
            <Button
              variant={"link"}
              color={"brand.800"}
              fontWeight={"500"}
              outline={"0"}
              border={"0"}
              _focus={{ boxShadow: "0" }}
            >
              Learn More
              <ArrowForwardIcon w={"20px"} h={"20px"} ml={"2"} />
            </Button>
          </a>
        </Link>
      </Flex>

      <Flex
        flexDir={"row"}
        justifyContent={"space-between"}
        my={"50px"}
        width={"100%"}
        overflow={{ base: "auto", md: "hidden" }}
        p={"15px"}
        pb={"25px"}
      >
        {data.slice(0, 3).map((item, index) => (
          <BlogCard
            key={index}
            title={item.title}
            author={item.author.name}
            authorImg={item.author.picture.url}
            date={new Date(item.date).toLocaleDateString()}
            description={item.excerpt}
            image={item.coverImage?.url}
            slug={item.slug}
          />
        ))}
      </Flex>
    </Flex>
  );
};
