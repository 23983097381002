import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";

import SignUpComponent from "./Signup/SignupModal2";

export const AuthModal = ({ isOpen, onClose, ...rest }) => {
  const [lockClose, setLockClose] = useState(false);

  return (
    <Modal
      closeOnOverlayClick={false}
      isCentered
      closeOnEsc={false}
      isOpen={isOpen}
      onClose={onClose}
      // maxWidth="40rem"
      // paddingBottom="1.5rem"
    >
      <ModalOverlay
        // zIndex={2147483647}
        backgroundColor={lockClose ? "#fff" : "#0006"}
      />
      <ModalContent
        borderRadius={4}
        width={{ base: "25rem", md: "50rem" }}
        // minHeight="30rem"
        zIndex={2147483648}
        h="auto"
        maxW="100vw"
      >
        {!lockClose && (
          <ModalCloseButton
            _focus={{
              boxShadow: "none",
            }}
          />
        )}
        <ModalBody padding="0rem">
          <SignUpComponent
            {...rest}
            onClose={onClose}
            setLockClose={setLockClose}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export const withAuthModal = (Component) => (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <AuthModal isOpen={isOpen} onClose={onClose} type="Sign Up" />
      <Component openAuthModal={onOpen} {...props} />
    </>
  );
};
