import {
  Box,
  Flex,
  Text,
  Image as ImageComponent,
  List,
  Button,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { WrapperStyle } from "../../styles/newDesignStyles";
import { RiInstagramFill } from "react-icons/ri";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import {
  FooterLink,
  FooterLinksTitle,
  MiniFooterText,
} from "../../styles/typography";
import { FaqBanner } from "../Banner/FaqBanner";
import { FooterBanner, InsightsBanner, SignupBanner } from "../Banner";
import { useAuth } from "../../utils/auth/newAuth";
import { AuthModal } from "../Auth/withAuthModal";
import Link from "next/link";
import { EmailIcon } from "@chakra-ui/icons";

const NewFooter = ({ faq, isProfessional }) => {
  const [isAuthBoxOpen, setisAuthBoxOpen] = useState(false);
  const { user } = useAuth();

  return (
    <>
      <AuthModal
        createListing
        onClose={() => setisAuthBoxOpen(false)}
        isOpen={isAuthBoxOpen}
        
      />

      <WrapperStyle>
        {/* signup banner in professional pages */}
        {isProfessional ? (
          <SignupBanner
            header={
              "Buying, leasing or investing? Let the right opportunity find you"
            }
            semiHeader={
              "Zonado automatically matches your listing with the right active buyers. With every match, you will receive instant notifications to get the conversation started. Let our smart algorithms help you save time, and sell better."
            }
          />
        ) : (
          <></>
        )}
      </WrapperStyle>

      {/* FAQ */}
      {faq && faq.length ? <FaqBanner data={faq} /> : <></>}

      {/* professionals additions to the footer */}
      <WrapperStyle>
        {isProfessional ? (
          <>
            {/* insights banner */}
            <InsightsBanner />

            {/* footer banner */}
            <FooterBanner />
          </>
        ) : (
          <></>
        )}

        {/*  footer */}

        {/* mini */}
        <Flex
          flexDir={{ base: "column", md: "row" }}
          justifyContent={"space-between"}
          pb={"54px"}
          alignItems={"center"}
        >
          <Link href={"/"}>
            <ImageComponent
              src={"/images/newLanding/logo.svg"}
              width={"100px"}
              height={"24px"}
              objectFit={"contain"}
            />
          </Link>

          {/* <Text color={"gray.500"} w={"100%"} fontSize={"16px"} fontWeight={"28px"} display={{ base: "block", md: "none" }} mt={"30px"} mb={"50px"}>
                        Zonado combines a diverse portfolio of commercial real estate listings with an obsessive focus on technology. It enables stakeholders to buy, sell and lease better than ever before.
                    </Text> */}

          <MiniFooterText
            text={
              "Zonado is Canada's fastest growing commercial real estate and sale of business marketplace. Our goal is to help you buy, sell and lease better than ever before."
            }
            display={{ base: "block", md: "none" }}
            w={"100%"}
            mt={"30px"}
            mb={"50px"}
          />
          <Box mt="-20px" mb="50px" display={{ base: "flex", md: "none" }}>
            <Text fontSize="16px" textAlign={"center"}>
              Need help?
              <br />
              <Text fontSize={"16px"} fontWeight={700}>
                <a href="mailto:support@zonado.com">
                  <EmailIcon w="16px" h="16px" /> support@zonado.com
                </a>
              </Text>
            </Text>
          </Box>

          <Flex
            flexDir={{ base: "column", sm: "row" }}
            justifyContent={{ base: "space-evenly", md: "space-between" }}
            flexWrap={"wrap"}
            gap="20px"
          >
            <Link passHref href={"/listings?source=footer-nav"}>
              <a>
                <Button
                  fontWeight={500}
                  variant="solid"
                  colorScheme="green"
                  w="225px"
                  height="55px"
                >
                  Search listings
                </Button>
              </a>
            </Link>
            <Link passHref href={"/add-listing?source=footer-nav"}>
              <a>
                <Button
                  fontWeight={500}
                  variant="outline"
                  colorScheme="green"
                  color="#125F3F"
                  w="225px"
                  height="55px"
                >
                  Add a Free Listing
                </Button>
              </a>
            </Link>
          </Flex>
        </Flex>

        {/* full-links footer */}
        <Flex
          flexDir={"row"}
          pt={"60px"}
          pb={"37px"}
          borderBottomWidth={"1px"}
          borderBottomColor={"gray.200"}
          justifyContent={"space-between"}
        >
          <MiniFooterText
            display={{ base: "none", md: "block" }}
            text={
              "Zonado combines a diverse portfolio of commercial real estate and sale of business listings with an obsessive focus on technology. It enables you to buy, sell and lease better than ever before."
            }
          />

          <Flex
            flexDir={{ base: "column", md: "row" }}
            justifyContent={"space-between"}
            w={{ md: "55%", lg: "45%" }}
          >
            <Box>
              <FooterLinksTitle text={"Pages"} />

              <Flex justifyContent={"space-between"} flexDir={"row"}>
                <List>
                  <FooterLink text={"Home"} route={""} last={undefined} />
                  <FooterLink
                    text={"Insights"}
                    route={"insights"}
                    last={undefined}
                  />
                  <FooterLink
                    text={"Zonado Pro"}
                    route={"pro"}
                    last={undefined}
                  />
                  <FooterLink
                    text={"About Us"}
                    route={"about-us"}
                    last={undefined}
                  />
                  <FooterLink
                    text={"Contact Us"}
                    route={"contact-us"}
                    last={undefined}
                  />
                  <FooterLink text={"Smart Match"} route={"smart-match"} last />
                </List>
                <List ml={{ base: "30px", lg: "50px" }}>
                  <FooterLink
                    text={"Realtors"}
                    route={"who-we-help/realtors"}
                    last={undefined}
                  />
                  <FooterLink
                    text={"Business Brokers"}
                    route={"who-we-help/business-broker"}
                    last={undefined}
                  />
                  <FooterLink
                    text={"Business Owners"}
                    route={"who-we-help/business-owners"}
                    last={undefined}
                  />
                  <FooterLink
                    text={"Property Owners"}
                    route={"who-we-help/property-owners"}
                    last={undefined}
                  />
                  <FooterLink
                    text={"Franchisors"}
                    route={"who-we-help/franchisors"}
                    last={undefined}
                  />
                  <FooterLink
                    text={"Buyer/Investors"}
                    route={"who-we-help/investors-buyers"}
                    last
                  />
                </List>
              </Flex>
            </Box>

            <Box>
              <FooterLinksTitle text={"Zonado"} mt={{ base: "32px", md: 0 }} />
              <List>
                <FooterLink
                  text={"Browse Listings"}
                  route={"listings?source=footer-link"}
                  last={undefined}
                />
                <FooterLink
                  text={"Add a Free Listing"}
                  route={"add-listing?source=footer-link"}
                  last={undefined}
                />
              </List>
            </Box>
          </Flex>
        </Flex>

        <Flex
          flexDir={{ base: "column-reverse", md: "row" }}
          justifyContent={"space-between"}
          pt={"20px"}
          pb={"23px"}
        >
          <Text
            color={"gray.500"}
            fontWeight={"500"}
            fontSize={{ lg: "15px" }}
            lineHeight={{ lg: "26px" }}
          >
            Copyright © Zonado Inc.
          </Text>
          <Flex mb={{ base: "20px", md: "unset" }} flexDir={"row"}>
            <Text color={"gray.500"} mr={"15px"}>
              <a target="_blank" href={"https://facebook.com/zonado.inc"}>
                <FaFacebookF />
              </a>
            </Text>
            <Text color={"gray.500"} mr={"15px"}>
              <a target="_blank" href={"https://twitter.com/Zonado_cre"}>
                <FaTwitter />
              </a>
            </Text>
            <Text color={"gray.500"} mr={"15px"}>
              <a target="_blank" href={"https://instagram.com/zonado.cre"}>
                <RiInstagramFill />
              </a>
            </Text>
            <Text color={"gray.500"}>
              <a target="_blank" href={"https://linkedin.com/company/zonado"}>
                <FaLinkedinIn />
              </a>
            </Text>
          </Flex>
        </Flex>

        <Text
          color={"gray.400"}
          fontWeight={"400"}
          fontSize={{ lg: "14px" }}
          lineHeight={{ lg: "21px" }}
        >
          The REALTOR® trademark is controlled by The Canadian Real Estate
          Association (CREA) and identifies real estate professionals who are
          members of CREA. The trademarks MLS®, Multiple Listing Service® and
          the associated logos identify professional services rendered by
          REALTOR® members of CREA to effect the purchase, sale and lease of
          real estate as part of a cooperative selling system.
        </Text>
      </WrapperStyle>
    </>
  );
};

export default NewFooter;
