export const funnelTypes = ["office", "land", "multifamily", "retail", "industrial"];

export const commercialTypeOptions = [
    { value: 'Development Site', label: 'Development Site' },
    { value: 'Gas Station', label: 'Gas Station' },
    { value: 'Hospitality', label: 'Hospitality' },
    { value: 'Industrial', label: 'Industrial' },
    { value: 'Land', label: 'Land' },
    { value: 'Medical Office', label: 'Medical Office' },
    { value: 'Mixed Use', label: 'Mixed Use' },
    { value: 'Mobile Home Park', label: 'Mobile Home Park' },
    { value: 'Multifamily', label: 'Multifamily' },
    { value: 'Office', label: 'Office' },
    { value: 'Plaza', label: 'Plaza' },
    { value: 'Retail', label: 'Retail' },
    { value: 'Sale of Business with Property', label: 'Sale of Business with Property' },
    { value: 'Sale of Business without Property', label: 'Sale of Business without Property' },
    { value: 'Self Storage', label: 'Self Storage' },
    { value: 'Senior Living', label: 'Senior Living' },
    { value: 'Special Purpose', label: 'Special Purpose' },
    { value: 'Student Housing', label: 'Student Housing' },
    { value: 'Other', label: 'Other' },
]

export const subTypeOptions = [
    { label: "Office/Storefront Retail", value: "Office/Storefront Retail", group: "Office", urlValue: "Office and Storefront Retail" },
    { label: "Office Live/Work Unit", value: "Office Live/Work Unit", group: "Office", urlValue: "Office Live and Work Unit" },
    { label: "Coworking", value: "Coworking", group: "Office" },
    { label: "Creative Office", value: "Creative Office", group: "Office" },
    { label: "Executive Office", value: "Executive Office", group: "Office" },
    { label: "Medical Office", value: "Medical Office", group: "Office" },

    { label: "Refrigerated/Cold Storage", value: "Refrigerated/Cold Storage", group: 'Industrial', urlValue: "Refrigerated and Cold Storage" },
    { label: "Manufacturing", value: "Manufacturing", group: 'Industrial' },
    { label: "Flex", value: "Flex", group: 'Industrial' },
    { label: "Warehouse", value: "Warehouse", group: 'Industrial' },
    { label: "Distribution", value: "Distribution", group: 'Industrial' },
    { label: "R&D", value: "R&D", group: 'Industrial', urlValue: 'R and D' },
    { label: "Showroom", value: "Showroom", group: 'Industrial' },
    { label: "Truck Terminal", value: "Truck Terminal", group: 'Industrial' },
    { label: "Other Industrial", value: "Other Industrial", group: 'Industrial' },

    { label: "Bank", value: "Bank", group: 'Retail' },
    { label: "Day Care/Nursery", value: "Day Care/Nursery", group: 'Retail', urlValue: 'Day Care and Nursery' },
    { label: "Gas Station", value: "Gas Station", group: 'Retail' },
    { label: "Grocery/Convenience Store", value: "Grocery/Convenience Store", group: 'Retail', urlValue: "Grocery and Convenience Store" },
    { label: "Pharmacy/Drug Store", value: "Pharmacy/Drug Store", group: 'Retail', urlValue: "Pharmacy and Drug Store" },
    { label: "Showroom", value: "Showroom", group: 'Retail' },
    { label: "Restaurant", value: "Restaurant", group: 'Retail' },
    { label: "Bar", value: "Bar", group: 'Retail' },
    { label: "Storefront", value: "Storefront", group: 'Retail' },
    { label: "Storefront Retail/Residential", value: "Storefront Retail/Residential", group: 'Retail', urlValue: "Storefront Retail and Residential" },
    { label: "Storefront Retail/Office", value: "Storefront Retail/Office", group: 'Retail', urlValue: "Storefront Retail and Office" },
    { label: "Auto/Mechanic Shop", value: "Auto/Mechanic Shop", group: 'Retail', urlValue: "Auto and Mechanic Shop" },
    { label: "Other Retail", value: "Other Retail", group: 'Retail' },
    { label: "Plaza and Strip Mall", value: "Plaza and Strip Mall", group: 'Retail' },
    { label: "Shopping Centre", value: "Shopping Centre", group: 'Retail' },

    { label: "Student Housing", value: "Student Housing", group: 'Multifamily' },
    { label: "Senior Living", value: "Senior Living", group: 'Multifamily' },
    { label: "Mobile Home Park", value: "Mobile Home Park", group: 'Multifamily' },
    { label: "RV Park", value: "RV Park", group: 'Multifamily' },
    { label: "Apartment Building", value: "Apartment Building", group: 'Multifamily' },
    { label: "Duplex", value: "Duplex", group: 'Multifamily' },
    { label: "Triplex", value: "Triplex", group: 'Multifamily' },
    { label: "Multiplex", value: "Multiplex", group: 'Multifamily' },

    { label: "Religious Facility", value: "Religious Facility", group: 'Special Purpose' },
    { label: "Self-Storage", value: "Self-Storage", group: 'Special Purpose' },
    { label: "Marina", value: "Marina", group: 'Special Purpose' },
    { label: "Golf Course", value: "Golf Course", group: 'Special Purpose' },
    { label: "School", value: "School", group: 'Special Purpose' },
    { label: "Garage/Parking", value: "Garage/Parking", group: 'Special Purpose', urlValue: "Garage and Parking" },
    { label: "Car Wash", value: "Car Wash", group: 'Special Purpose' },
    { label: "Telecom/Data Center", value: "Telecom/Data Center", group: 'Special Purpose', urlValue: "Telecom and Data Center" },
    { label: "Sports/Entertainment", value: "Sports/Entertainment", group: 'Special Purpose', urlValue: "Sports and Entertainment" },
    { label: "Other Specialty", value: "Other Specialty", group: 'Special Purpose' },

    { label: "Hotel", value: "Hotel", group: 'Hospitality' },
    { label: "Motel", value: "Motel", group: 'Hospitality' },
    { label: "Casino", value: "Casino", group: 'Hospitality' },
    { label: "Bed and Breakfast", value: "Bed and Breakfast", group: 'Hospitality' },


    { label: "Truck yard", value: "Truck yard", group: 'Land' },
    { label: "Agricultural", value: "Agricultural", group: 'Land', urlValue: 'Agricultural Land' },
    { label: "Residential", value: "Residential", group: 'Land', urlValue: 'Residential Land' },
    { label: "Commercial", value: "Commercial", group: 'Land', urlValue: 'Commercial Land' },
    { label: "Industrial", value: "Industrial", group: 'Land', urlValue: 'Industrial Land' },
    { label: "Island", value: "Island", group: 'Land' },
    { label: "Farm", value: "Farm", group: 'Land', urlValue: 'Farm Land' },
    { label: "Ranch", value: "Ranch", group: 'Land' },
    { label: "Timber", value: "Timber", group: 'Land' },
    { label: "Hunting/Recreational", value: "Hunting/Recreational", group: 'Land', urlValue: 'Hunting and Recreational' },
    { label: "Outside storage", value: "Outside storage", group: 'Land' },

    { label: "Agriculture", value: "Agriculture", group: 'Sale of Business' },
    { label: "Automotive & Boat", value: "Automotive & Boat", group: 'Sale of Business', urlValue: 'Automotive and Boat' },
    { label: "Beauty & Personal Care", value: "Beauty & Personal Care", group: 'Sale of Business', urlValue: 'Beauty and Personal Care' },
    { label: "Building & Construction", value: "Building & Construction", group: 'Sale of Business', urlValue: 'Building and Construction' },
    { label: "Communication & Media", value: "Communication & Media", group: 'Sale of Business', urlValue: 'Communication and Media' },
    { label: "Education & Children", value: "Education & Children", group: 'Sale of Business', urlValue: 'Education and Children' },
    { label: "Entertainment & Recreation", value: "Entertainment & Recreation", group: 'Sale of Business', urlValue: 'Entertainment and Recreation' },
    { label: "Financial Services", value: "Financial Services", group: 'Sale of Business', urlValue: '' },
    { label: "Health Care & Fitness", value: "Health Care & Fitness", group: 'Sale of Business', urlValue: 'Health Care and Fitness' },
    { label: "Manufacturing", value: "Manufacturing", group: 'Sale of Business' },
    { label: "Online & Technology", value: "Online & Technology", group: 'Sale of Business', urlValue: "Online and Technology" },
    { label: "Pet Services", value: "Pet Services", group: 'Sale of Business' },
    { label: "Restaurants & Food", value: "Restaurants & Food", group: 'Sale of Business', urlValue: "Restaurants and Food" },
    { label: "Service Businesses", value: "Service Businesses", group: 'Sale of Business' },
    { label: "Transportation & Storage", value: "Transportation & Storage", group: 'Sale of Business', urlValue: "Transportation and Storage" },
    { label: "Travel", value: "Travel", group: 'Sale of Business' },
    { label: "Wholesale & Distributors", value: "Wholesale & Distributors", group: 'Sale of Business', urlValue: "Wholesale and Distributors" },

]

export const OfficeOptions = [
    { label: "Office/Storefront Retail", value: "Office/Storefront Retail", group: "Office" },
    { label: "Office Live/Work Unit", value: "Office Live/Work Unit", group: "Office" },
    { label: "Coworking", value: "Coworking", group: "Office" },
    { label: "Creative Office", value: "Creative Office", group: "Office" },
    { label: "Executive Office", value: "Executive Office", group: "Office" },
    { label: "Medical Office", value: "Medical Office", group: "Office" },
]

export const IndustrialOptions = [
    { label: "Distribution", value: "Distribution", group: 'Industrial' },
    { label: "R&D", value: "R&D", group: 'Industrial' },
    { label: "Refrigerated/Cold Storage", value: "Refrigerated/Cold Storage", group: 'Industrial' },
    { label: "Flex", value: "Flex", group: 'Industrial' },
    { label: "Manufacturing", value: "Manufacturing", group: 'Industrial' },
    { label: "Showroom", value: "Showroom", group: 'Industrial' },
    { label: "Truck Terminal", value: "Truck Terminal", group: 'Industrial' },
    { label: "Warehouse", value: "Warehouse", group: 'Industrial' },
    { label: "Other Industrial", value: "Other Industrial", group: 'Industrial' },
]

export const RetailOptions = [
    { label: "Auto/Mechanic Shop", value: "Auto/Mechanic Shop", group: 'Retail' },
    { label: "Bank", value: "Bank", group: 'Retail' },
    { label: "Bar", value: "Bar", group: 'Retail' },
    { label: "Day Care/Nursery", value: "Day Care/Nursery", group: 'Retail' },
    { label: "Gas Station", value: "Gas Station", group: 'Retail' },
    { label: "Grocery/Convenience Store", value: "Grocery/Convenience Store", group: 'Retail' },
    { label: "Pharmacy/Drug Store", value: "Pharmacy/Drug Store", group: 'Retail' },
    { label: "Plaza and Strip Mall", value: "Plaza and Strip Mall", group: 'Retail' },
    { label: "Restaurant", value: "Restaurant", group: 'Retail' },
    { label: "Shopping Centre", value: "Shopping Centre", group: 'Retail' },
    { label: "Showroom", value: "Showroom", group: 'Retail' },
    { label: "Storefront", value: "Storefront", group: 'Retail' },
    { label: "Storefront Retail/Office", value: "Storefront Retail/Office", group: 'Retail' },
    { label: "Storefront Retail/Residential", value: "Storefront Retail/Residential", group: 'Retail' },
    { label: "Other Retail", value: "Other Retail", group: 'Retail' },
]

export const MultifamilyOptions = [
    { label: "Duplex", value: "Duplex", group: 'Multifamily' },
    { label: "Triplex", value: "Triplex", group: 'Multifamily' },
    { label: "Multiplex", value: "Multiplex", group: 'Multifamily' },
    { label: "Apartment Building", value: "Apartment Building", group: 'Multifamily' },
    { label: "Mobile Home Park", value: "Mobile Home Park", group: 'Multifamily' },
    { label: "RV Park", value: "RV Park", group: 'Multifamily' },
    { label: "Senior Living", value: "Senior Living", group: 'Multifamily' },
    { label: "Student Housing", value: "Student Housing", group: 'Multifamily' },
]

export const SpecialPurposeOptions = [
    { label: "Car Wash", value: "Car Wash", group: 'Special Purpose' },
    { label: "Garage/Parking", value: "Garage/Parking", group: 'Special Purpose' },
    { label: "Golf Course", value: "Golf Course", group: 'Special Purpose' },
    { label: "Marina", value: "Marina", group: 'Special Purpose' },
    { label: "Religious Facility", value: "Religious Facility", group: 'Special Purpose' },
    { label: "School", value: "School", group: 'Special Purpose' },
    { label: "Self-Storage", value: "Self-Storage", group: 'Special Purpose' },
    { label: "Sports/Entertainment", value: "Sports/Entertainment", group: 'Special Purpose' },
    { label: "Telecom/Data Center", value: "Telecom/Data Center", group: 'Special Purpose' },
    { label: "Other Specialty", value: "Other Specialty", group: 'Special Purpose' },
]

export const HospitalityOptions = [
    { label: "Bed and Breakfast", value: "Bed and Breakfast", group: 'Hospitality' },
    { label: "Casino", value: "Casino", group: 'Hospitality' },
    { label: "Hotel", value: "Hotel", group: 'Hospitality' },
    { label: "Motel", value: "Motel", group: 'Hospitality' },
]

export const LandOptions = [
    { label: "Agricultural", value: "Agricultural", group: 'Land' },
    { label: "Commercial", value: "Commercial", group: 'Land' },
    { label: "Farm", value: "Farm", group: 'Land' },
    { label: "Hunting/Recreational", value: "Hunting/Recreational", group: 'Land' },
    { label: "Industrial", value: "Industrial", group: 'Land' },
    { label: "Island", value: "Island", group: 'Land' },
    { label: "Outside storage", value: "Outside storage", group: 'Land' },
    { label: "Ranch", value: "Ranch", group: 'Land' },
    { label: "Residential", value: "Residential", group: 'Land' },
    { label: "Timber", value: "Timber", group: 'Land' },
    { label: "Truck yard", value: "Truck yard", group: 'Land' },
]

export const SaleofBusinessOptions = [
    { label: "Agriculture", value: "Agriculture", group: 'Sale of Business' },
    { label: "Automotive & Boat", value: "Automotive & Boat", group: 'Sale of Business' },
    { label: "Beauty & Personal Care", value: "Beauty & Personal Care", group: 'Sale of Business' },
    { label: "Building & Construction", value: "Building & Construction", group: 'Sale of Business' },
    { label: "Communication & Media", value: "Communication & Media", group: 'Sale of Business' },
    { label: "Education & Children", value: "Education & Children", group: 'Sale of Business' },
    { label: "Entertainment & Recreation", value: "Entertainment & Recreation", group: 'Sale of Business' },
    { label: "Financial Services", value: "Financial Services", group: 'Sale of Business' },
    { label: "Health Care & Fitness", value: "Health Care & Fitness", group: 'Sale of Business' },
    { label: "Manufacturing", value: "Manufacturing", group: 'Sale of Business' },
    { label: "Online & Technology", value: "Online & Technology", group: 'Sale of Business' },
    { label: "Pet Services", value: "Pet Services", group: 'Sale of Business' },
    { label: "Restaurants & Food", value: "Restaurants & Food", urlValue: 'restaurants-food', group: 'Sale of Business' },
    { label: "Service Businesses", value: "Service Businesses", group: 'Sale of Business' },
    { label: "Transportation & Storage", value: "Transportation & Storage", group: 'Sale of Business' },
    { label: "Travel", value: "Travel", group: 'Sale of Business' },
    { label: "Wholesale & Distributors", value: "Wholesale & Distributors", group: 'Sale of Business' },
]

export const propertyTypeOptions = [
    { label: 'Office', value: 'Office' },
    { label: 'Hospitality', value: 'Hospitality' },
    { label: 'Industrial', value: 'Industrial' },
    { label: 'Land', value: 'Land' },
    { label: 'Multifamily', value: 'Multifamily' },
    { label: 'Mixed Use', value: 'Mixed Use' },
    { label: 'Retail', value: 'Retail' },
    { label: 'Special Purpose', value: 'Special Purpose' },
    { label: 'Sale of Business', value: 'Sale of Business' }
]
export const groupedSubtype = [
    { label: 'Office', options: OfficeOptions },
    { label: 'Hospitality', options: HospitalityOptions },
    { label: 'Industrial', options: IndustrialOptions },
    { label: 'Land', options: LandOptions },
    { label: 'Multifamily', options: MultifamilyOptions },
    { label: 'Mixed Use', options: [] },
    { label: 'Retail', options: RetailOptions },
    { label: 'Special Purpose', options: SpecialPurposeOptions },
    { label: 'Sale of Business', options: SaleofBusinessOptions },
]

export const AgricultureBusinessOptions = [
    { label: "Tree Farm and Orchard", value: "Tree Farm and Orchard" },
    { label: "Vineyard and Winery", value: "Vineyard and Winery" },
    { label: "Greenhouse", value: "Greenhouse" },
    { label: "Other Agriculture Business", value: "Other Agriculture Business" },
]

export const AutomotiveandBoatBusiness = [
    { label: "Auto Repair and Service Shop", value: "Auto Repair and Service Shop" },
    { label: "Car Dealership", value: "Car Dealership" },
    { label: "Car Wash", value: "Car Wash" },
    { label: "Equipment Rental and Dealer", value: "Equipment Rental and Dealer" },
    { label: "Gas Station", value: "Gas Station" },
    { label: "Junk and Salvage Yard", value: "Junk and Salvage Yard" },
    { label: "Marine/Boat Service and Dealer", value: "Marine/Boat Service and Dealer" },
    { label: "Towing Company", value: "Towing Company" },
    { label: "Truck Stop", value: "Truck Stop" },
    { label: "Other Automotive and Boat Business", value: "Other Automotive and Boat Business" },
]

export const BeautyandPersonalCareBusiness = [
    { label: "Hair Salon and Barber Shop", value: "Hair Salon and Barber Shop" },
    { label: "Massage Business", value: "Massage Business" },
    { label: "Nail Salon", value: "Nail Salon" },
    { label: "Spa", value: "Spa" },
    { label: "Tanning Salon", value: "Tanning Salon" },
    { label: "Other Beauty and Personal Care Business", value: "Other Beauty and Personal Care Business" },

]

export const BuildingandConstructionBusiness = [
    { label: "Building Material and Hardware Store", value: "Building Material and Hardware Store" },
    { label: "Concrete Business", value: "Concrete Business" },
    { label: "Electrical and Mechanical Contracting Business", value: "Electrical and Mechanical Contracting Business" },
    { label: "Heavy Construction Business", value: "Heavy Construction Business" },
    { label: "HVAC Business", value: "HVAC Business" },
    { label: "Plumbing Business", value: "Plumbing Business" },
    { label: "Other Building and Construction Business", value: "Other Building and Construction Business" },


]

export const CommunicationandMediaBusiness = [
    { label: "Magazine and Newspaper", value: "Magazine and Newspaper" },
    { label: "Production Company", value: "Production Company" },
    { label: "Other Communication and Media Business", value: "Other Communication and Media Business" },
]

export const EducationandChildRelatedBusiness = [
    { label: "Day Care and Child Care Center", value: "Day Care and Child Care Center" },
    { label: "Preschool", value: "Preschool" },
    { label: "School", value: "School" },
    { label: "Other Education and Children Business", value: "Other Education and Children Business" },
]

export const EntertainmentandRecreationBusiness = [
    { label: "Art Gallery", value: "Art Gallery" },
    { label: "Banquet Hall", value: "Banquet Hall" },
    { label: "Bowling Alley", value: "Bowling Alley" },
    { label: "Casino", value: "Casino" },
    { label: "Golf Course and Service Business", value: "Golf Course and Service Business" },
    { label: "Marina and Fishing Business", value: "Marina and Fishing Business" },
    { label: "Nightclub and Theater", value: "Nightclub and Theater" },
    { label: "Other Entertainment and Recreation Business", value: "Other Entertainment and Recreation Business" },

]

export const FinancialServiceBusiness = [
    { label: "Accounting Business and Tax Practice", value: "Accounting Business and Tax Practice" },
    { label: "Banking and Loan Business", value: "Banking and Loan Business" },
    { label: "Check Cashing Business", value: "Check Cashing Business" },
    { label: "Insurance Agency", value: "Insurance Agency" },
    { label: "Other Financial Service Business", value: "Other Financial Service Business" },

]

export const RestaurantandFoodBusiness = [
    { label: "American Restaurant", value: "American Restaurant" },
    { label: "Bakery", value: "Bakery" },
    { label: "Bar, Pub and Tavern", value: "Bar, Pub and Tavern" },
    { label: "Brewery", value: "Brewery" },
    { label: "Chinese Restaurant", value: "Chinese Restaurant" },
    { label: "Coffee Shop and Cafe", value: "Coffee Shop and Cafe" },
    { label: "Deli and Sandwich Shop", value: "Deli and Sandwich Shop" },
    { label: "Diner", value: "Diner" },
    { label: "Donut Shop", value: "Donut Shop" },
    { label: "Food Truck", value: "Food Truck" },
    { label: "Ice Cream and Frozen Yogurt Shop", value: "Ice Cream and Frozen Yogurt Shop" },
    { label: "Indian Restaurant", value: "Indian Restaurant" },
    { label: "Italian Restaurant", value: "Italian Restaurant" },
    { label: "Juice Bar", value: "Juice Bar" },
    { label: "Mexican Restaurant", value: "Mexican Restaurant" },
    { label: "Pizza Restaurant", value: "Pizza Restaurant" },
    { label: "Miscellaneous Restaurant and Bar", value: "Miscellaneous Restaurant and Bar" },
    { label: "Sushi and Japanese Restaurant", value: "Sushi and Japanese Restaurant" },
    { label: "Thai Restaurant", value: "Thai Restaurant" },
    { label: "Other Restaurant and Food Business", value: "Other Restaurant and Food Business" },

]


export const HealthCareandFitnessBusiness = [
    { label: "Assisted Living and Nursing Home", value: "Assisted Living and Nursing Home" },
    { label: "Dance, Pilates and Yoga Studio", value: "Dance, Pilates and Yoga Studio" },
    { label: "Dental Practice", value: "Dental Practice" },
    { label: "Gym and Fitness Center", value: "Gym and Fitness Center" },
    { label: "Home Health Care Business", value: "Home Health Care Business" },
    { label: "Medical Practice", value: "Medical Practice" },
    { label: "Other Health Care and Fitness Business", value: "Other Health Care and Fitness Business" },
]

export const Manufacturing = [
    { label: "Auto, Boat and Aircraft Manufacturing", value: "Auto, Boat and Aircraft Manufacturing" },
    { label: "Chemical and Related Product Manufacturing", value: "Chemical and Related Product Manufacturing" },
    { label: "Clothing and Fabric Manufacturing", value: "Clothing and Fabric Manufacturing" },
    { label: "Electronic and Electrical Equipment Manufacturing", value: "Electronic and Electrical Equipment Manufacturing" },
    { label: "Energy and Petroleum Production", value: "Energy and Petroleum Production" },
    { label: "Food and Related Product Manufacturing", value: "Food and Related Product Manufacturing" },
    { label: "Furniture and Fixture Manufacturing", value: "Furniture and Fixture Manufacturing" },
    { label: "Glass, Stone and Concrete Manufacturing", value: "Glass, Stone and Concrete Manufacturing" },
    { label: "Industrial and Commercial Machinery Manufacturing", value: "Industrial and Commercial Machinery Manufacturing" },
    { label: "Lumber and Wood Products Manufacturing", value: "Lumber and Wood Products Manufacturing" },
    { label: "Machine Shop and Tool Manufacturing", value: "Machine Shop and Tool Manufacturing" },
    { label: "Medical Device and Product Manufacturing", value: "Medical Device and Product Manufacturing" },
    { label: "Metal Product Manufacturing", value: "Metal Product Manufacturing" },
    { label: "Mining", value: "Mining" },
    { label: "Packaging Business", value: "Packaging Business" },
    { label: "Paper Manufacturers and Printing Business", value: "Paper Manufacturers and Printing Business" },
    { label: "Rubber and Plastic Products Manufacturing", value: "Rubber and Plastic Products Manufacturing" },
    { label: "Sign Manufacturing and Business", value: "Sign Manufacturing and Business" },
    { label: "Other Manufacturing", value: "Other Manufacturing" },

]

export const NonClassifiable = [
    { label: "Non-classifiable", value: "Non-classifiable" },

]

export const OnlineandTechnologyBusiness = [
    { label: "Cell Phone and Computer Repair and Service Business", value: "Cell Phone and Computer Repair and Service Business" },
    { label: "IT and Software Service Business", value: "IT and Software Service Business" },
    { label: "Graphic and Web Design Business", value: "Graphic and Web Design Business" },
    { label: "Software and App Company", value: "Software and App Company" },
    { label: "Websites and Ecommerce Business", value: "Websites and Ecommerce Business" },
    { label: "Other Online and Technology Business", value: "Other Online and Technology Business" },


]

export const PetServiceBusiness = [
    { label: "Dog Daycare and Boarding Business", value: "Dog Daycare and Boarding Business" },
    { label: "Pet Grooming Business", value: "Pet Grooming Business" },
    { label: "Pet Stores and Supply Business", value: "Pet Stores and Supply Business" },
    { label: "Other Pet Service Business", value: "Other Pet Service Business" },
]

export const RetailBusiness = [
    { label: "Bike Shop", value: "Bike Shop" },
    { label: "Clothing and Accessory Store", value: "Clothing and Accessory Store" },
    { label: "Convenience Store", value: "Convenience Store" },
    { label: "Dollar Store", value: "Dollar Store" },
    { label: "Flower Shop", value: "Flower Shop" },
    { label: "Furniture and Furnishing Store", value: "Furniture and Furnishing Store" },
    { label: "Grocery Store and Supermarket", value: "Grocery Store and Supermarket" },
    { label: "Health Food and Nutrition Business", value: "Health Food and Nutrition Business" },
    { label: "Jewelry Store", value: "Jewelry Store" },
    { label: "Liquor Store", value: "Liquor Store" },
    { label: "Nursery and Garden Center", value: "Nursery and Garden Center" },
    { label: "Pawn Shop", value: "Pawn Shop" },
    { label: "Pharmacy", value: "Pharmacy" },
    { label: "Smoke Shop", value: "Smoke Shop" },
    { label: "Vending Machine", value: "Vending Machine" },
    { label: "Other Retail Business", value: "Other Retail Business" },

]

export const ServiceBusiness = [
    { label: "Architecture and Engineering Firm", value: "Architecture and Engineering Firm" },
    { label: "Catering Company", value: "Catering Company" },
    { label: "Cleaning Business", value: "Cleaning Business" },
    { label: "Commercial Laundry Business", value: "Commercial Laundry Business" },
    { label: "Dry Cleaner", value: "Dry Cleaner" },
    { label: "Funeral Home", value: "Funeral Home" },
    { label: "Home Staging", value: "Home Staging" },
    { label: "Interior Design", value: "Interior Design" },
    { label: "Landscape and Yard Service", value: "Landscape and Yard Service" },
    { label: "Laundromat and Coin Laundry Business", value: "Laundromat and Coin Laundry Business" },
    { label: "Legal Service and Law Firm", value: "Legal Service and Law Firm" },
    { label: "Locksmith Business", value: "Locksmith Business" },
    { label: "Medical Billing Business", value: "Medical Billing Business" },
    { label: "Mobile Home Park", value: "Mobile Home Park" },
    { label: "Pest Control Business", value: "Pest Control Business" },
    { label: "Property Management Business", value: "Property Management Business" },
    { label: "Route", value: "Route" },
    { label: "Security Business", value: "Security Business" },
    { label: "Staffing Agency", value: "Staffing Agency" },
    { label: "Waste Management and Recycling Business", value: "Waste Management and Recycling Business" },
    { label: "Other Service Business", value: "Other Service Business" },

]

export const TransportationandStorageBusiness = [
    { label: "Moving and Shipping Business", value: "Moving and Shipping Business" },
    { label: "Limo and Passenger Transportation", value: "Limo and Passenger Transportation" },
    { label: "Storage Facility and Warehouse", value: "Storage Facility and Warehouse" },
    { label: "Trucking Company", value: "Trucking Company" },
    { label: "Other Transportation and Storage", value: "Other Transportation and Storage" },

]

export const TravelBusiness = [
    { label: "Bed and Breakfast", value: "Bed and Breakfast" },
    { label: "Campground and RV Park", value: "Campground and RV Park" },
    { label: "Hotel", value: "Hotel" },
    { label: "Motel", value: "Motel" },
    { label: "Travel Agency", value: "Travel Agency" },
    { label: "Other Travel Business", value: "Other Travel Business" },

]

export const WholesaleandDistributionBusiness = [
    { label: "Durable Goods Wholesale and Distribution", value: "Durable Goods Wholesale and Distribution" },
    { label: "Nondurable Goods Wholesale and Distribution", value: "Nondurable Goods Wholesale and Distribution" },
    { label: "Other Wholesale and Distribution", value: "Other Wholesale and Distribution" },

]
export const groupedBusiness = [
    // { label: 'Agriculture', options: AgricultureBusinessOptions },
    // { label: 'Automotive & Boat', options: AutomotiveandBoatBusiness },
    // { label: 'Beauty & Personal Care', options: BeautyandPersonalCareBusiness },
    // { label: 'Building & Construction', options: BuildingandConstructionBusiness },
    // { label: 'Communication & Media', options: CommunicationandMediaBusiness },    
    // { label: 'Education & Children', options: EducationandChildRelatedBusiness },
    // { label: 'Entertainment & Recreation', options: EntertainmentandRecreationBusiness },
    // { label: 'Financial Services', options: FinancialServiceBusiness },
    // { label: 'Health Care & Fitness', options: HealthCareandFitnessBusiness },
    // { label: 'Manufacturing', options: Manufacturing},
    // { label: 'Non-classifiable', options: NonClassifiable },
    // { label: 'Online & Technology', options: OnlineandTechnologyBusiness },
    // { label: 'Pet Services', options: PetServiceBusiness },
    // { label: 'Restaurants & Food', options: RestaurantandFoodBusiness },
    // { label: 'Retail Business', options: RetailBusiness},
    // { label: 'Service Businesses', options: ServiceBusiness },
    // { label: 'Transportation & Storage', options: TransportationandStorageBusiness },
    // { label: 'Travel', options: TravelBusiness },
    // { label: 'Wholesale & Distributors', options: WholesaleandDistributionBusiness },

    { value: 'Agriculture', label: 'Agriculture', options: AgricultureBusinessOptions, urlValue: 'Agriculture' },
    { value: 'Automotive & Boat', label: 'Automotive & Boat', options: AutomotiveandBoatBusiness, urlValue: 'Automotive and Boat' },
    { value: 'Beauty & Personal Care', label: 'Beauty & Personal Care', options: BeautyandPersonalCareBusiness, urlValue: 'Beauty and Personal Care' },
    { value: 'Building & Construction', label: 'Building & Construction', options: BuildingandConstructionBusiness, urlValue: 'Building and Construction' },
    { value: 'Communication & Media', label: 'Communication & Media', options: CommunicationandMediaBusiness, urlValue: 'Communication and Media' },
    { value: 'Education & Children', label: 'Education & Children', options: EducationandChildRelatedBusiness, urlValue: 'Education and Children' },
    { value: 'Entertainment & Recreation', label: 'Entertainment & Recreation', options: EntertainmentandRecreationBusiness, urlValue: 'Entertainment and Recreation' },
    { value: 'Financial Services', label: 'Financial Services', options: FinancialServiceBusiness, urlValue: 'Financial Services' },
    { value: 'Health Care & Fitness', label: 'Health Care & Fitness', options: HealthCareandFitnessBusiness, urlValue: 'Health Care and Fitness' },
    { value: 'Manufacturing', label: 'Manufacturing', options: Manufacturing, urlValue: 'Manufacturing' },
    { value: 'Non-classifiable', label: 'Non-classifiable', options: NonClassifiable, urlValue: 'Non classifiable' },
    { value: 'Online & Technology', label: 'Online & Technology', options: OnlineandTechnologyBusiness, urlValue: 'Online and Technology' },
    { value: 'Pet Services', label: 'Pet Services', options: PetServiceBusiness, urlValue: 'Pet Services' },
    { value: 'Restaurants & Food', label: 'Restaurants & Food', options: RestaurantandFoodBusiness, urlValue: 'Restaurants and Food' }, // clashes with subType of identical name
    { value: 'Retail Business', label: 'Retail Business', options: RetailBusiness, urlValue: 'Retail Business' },
    { value: 'Service Businesses', label: 'Service Businesses', options: ServiceBusiness, urlValue: 'Service Businesses' },
    { value: 'Transportation & Storage', label: 'Transportation & Storage', options: TransportationandStorageBusiness, urlValue: 'Transportation and Storage' },
    { value: 'Travel', label: 'Travel', options: TravelBusiness, urlValue: 'Travel' },
    { value: 'Wholesale & Distributors', label: 'Wholesale & Distributors', options: WholesaleandDistributionBusiness, urlValue: 'Wholesale and Distributors' },
]

export const AllBusiness = []
const AllBusinessCreator = groupedBusiness.map(gb => {
    let theArray = [];
    gb['options'].map(opt => {
        theArray.push({ ...opt, group: gb['label'] })
    })
    AllBusiness.push(...theArray);
})


export const postedByOptions = [
    { value: 'Agents', label: 'Agents' },
    { value: 'Public', label: 'Non-Agents (Principals)' },
    { value: 'Anyone', label: 'Anyone' },
]

export const postTypeOptions = [
    { value: 'Have', label: 'Haves' },
    { value: 'Want', label: 'Wants' },
]

export const transactionTypeOptions = [
    { value: 'For Sale', label: 'For Sale' },
    { value: 'For Lease', label: 'For Lease' },
]

export const transactionTypeOptionsWant = [
    { value: 'For Sale', label: 'Buy' },
    { value: 'For Lease', label: 'Rent' },
]

export const availableOptions = [
    { value: 'Realtors and Public', label: 'Realtors and Public' },
    { value: 'Public', label: 'Public Only' },
    { value: 'Realtor', label: 'REALTORS® Only' },
]

export const resTypeOptions = [
    { value: 'Townhouse', label: 'Townhouse' },
    { value: 'Semi-Detached', label: 'Semi-Detached' },
    { value: 'Detached', label: 'Detached' },
    { value: 'Linked', label: 'Linked' },
    { value: 'Farm', label: 'Farm' },
    { value: 'Other', label: 'Other' },
]

export const condoTypeOptions = [
    { value: 'Condo Apartment', label: 'Condo Apartment' },
    { value: 'Condo Townhouse', label: 'Condo Townhouse' },
    { value: 'Common Element Condo', label: 'Common Element Condo' },
    { value: 'Other', label: 'Other' },
]

export const ListingStatusOptions = [
    { value: 'Draft', label: 'Draft' },
    { value: 'Available', label: 'Available' },
    { value: 'Conditionally Sold', label: 'Conditionally Sold' },
    { value: 'Sold', label: 'Sold' },
    { value: 'Expired', label: 'Expired' },
]

export const landingPropertyTypeOptions = [
    { label: 'Commercial', value: 'Commercial' },
    { label: 'Residential', value: 'Residential' },
]

export const ownershipOptions = [
    { value: 'Condo', label: 'condo' },
    { value: 'Freehold', label: 'freehold' },
]

export const ageOptions = [
    { value: 'New', label: 'New' },
    { value: '0 - 5', label: '0 - 5' },
    { value: '6 - 10', label: '6 - 10' },
    { value: '11 - 15', label: '11 - 15' },
    { value: '16 - 20', label: '16 - 20' },
    { value: '20 - 30', label: '20 - 30' },
    { value: '31 - 50', label: '31 - 50' },
    { value: '50+', label: '50+' },
]

export const ageWantOptions = [
    { value: '', label: 'Any' },
    { value: 'New', label: 'New' },
    { value: '0 - 5', label: '0 - 5' },
    { value: '6 - 10', label: '6 - 10' },
    { value: '11 - 15', label: '11 - 15' },
    { value: '16 - 20', label: '16 - 20' },
    { value: '20 - 30', label: '20 - 30' },
    { value: '31 - 50', label: '31 - 50' },
    { value: '50+', label: '50+' },
]

export const biOptions = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
]

export const lotSizeUnitOptions = [
    { value: 'Acres', label: 'Acres' },
    { value: 'Sq Ft', label: 'Square Ft.' },
]

export const homeStyleFreeOptions = [
    { value: '1.5 Storey', label: '1.5 Storey' },
    { value: '2 Storey', label: '2 Storey' },
    { value: '2.5 Storey', label: '2.5 Storey' },
    { value: '3 Storey', label: '3 Storey' },
    { value: 'Backsplit', label: 'Backsplit' },
    { value: 'Bungalow', label: 'Bungalow' },
    { value: 'Bungaloft', label: 'Bungaloft' },
    { value: 'Other', label: 'Other' },
]

export const homeStyleCondoOptions = [
    { value: '2 Storey', label: '2 Storey' },
    { value: '3 Storey', label: '3 Storey' },
    { value: 'Apartment', label: 'Apartment' },
    { value: 'Stacked Townhouse', label: 'Stacked Townhouse' },
    { value: 'Bungalow', label: 'Bungalow' },
    { value: 'Bungaloft', label: 'Bungaloft' },
    { value: 'Other', label: 'Other' },
]

export const sqftFreeOptions = [
    { value: '<1000', label: '<1000' },
    { value: '1000-1500', label: '1000-1500' },
    { value: '1501-2000', label: '1501-2000' },
    { value: '2001-2500', label: '2001-2500' },
    { value: '2501-3000', label: '2501-3000' },
    { value: '3001-3500', label: '3001-3500' },
    { value: '3501-5000', label: '3501-5000' },
    { value: '5000+', label: '5000+' },
]


export const sqftCondoOptions = [
    { value: '0 - 500', label: '0 - 500' },
    { value: '500 - 600', label: '500 - 600' },
    { value: '601 - 700', label: '601 - 700' },
    { value: '701 - 800', label: '701 - 800' },
    { value: '801 - 900', label: '801 - 900' },
    { value: '901 - 1000', label: '901 - 1000' },
    { value: '1001 - 1200', label: '1001 - 1200' },
    { value: '1201 - 1400', label: '1201 - 1400' },
    { value: '1401 - 1600', label: '1401 - 1600' },
    { value: '1601 - 1800', label: '1601 - 1800' },
    { value: '1801 - 2000', label: '1801 - 2000' },
    { value: '2001 - 2500', label: '2001 - 2500' },
    { value: '2500+', label: '2500+' },
]

export const basementOptions = [
    { value: 'Apartment', label: 'Apartment' },
    { value: 'Walkout', label: 'Walkout' },
    { value: 'Partly Finished', label: 'Partly Finished' },
    { value: 'Separate Entrance', label: 'Separate Entrance' },
    { value: 'Unfinished', label: 'Unfinished' },
    { value: 'Finished', label: 'Finished' },
    { value: 'None', label: 'None' },
]

export const condoIncludedOptions = [
    { value: 'Heat', label: 'Heat' },
    { value: 'Water', label: 'Water' },
    { value: 'Hydro', label: 'Hydro' },
    { value: 'Central AC', label: 'Central AC' },
    { value: 'Parking', label: 'Parking' },
    { value: 'Cable TV', label: 'Cable TV' },
    { value: 'Common Elements', label: 'Common Elements' }
]

export const furnishedOptions = [
    { value: 'Yes', label: 'Yes' },
    { value: 'Partially', label: 'Partially' },
    { value: 'No', label: 'No' },
]


export const leaseTermOptions = [
    { value: 'Less than 1', label: 'Less than 1' },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '5+', label: '5+' }
]

export const bedBathOptions = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
    { value: 6, label: 6 },
    { value: 7, label: 7 },
    { value: 8, label: 8 },
    { value: 9, label: 9 },
    { value: 10, label: 10 }
]

export const leaseTypeOptions = [
    { value: 'Net', label: 'Net' },
    { value: 'NNN', label: 'NNN' },
    { value: 'Gross', label: 'Gross' },
]

export const priceTypeOptions = [
    { value: 'Month', label: 'Per month' },
    { value: 'Square Ft', label: 'Per SF' },
    { value: 'Acre', label: 'Per acre' },
]

// Want specific
export const areaTypeOptions = [
    { value: "National", label: "Canada-wide" },
    { value: "Provinces", label: "Specific provinces" },
    { value: "Cities", label: "Specific cities" },
    { value: "Custom", label: "Radius (map)" },
]

export const radiusOptions = [
    { value: "10", label: '10' },
    { value: "20", label: '20' },
    { value: "50", label: '50' },
    { value: "75", label: '75' },
    { value: "100", label: '100' },
    { value: "150", label: '150' },
    { value: "200", label: '200' },
];

export const listingTypeOptions = [
    { value: "Exclusive", label: 'Exclusive' },
    { value: "Pre-Construction", label: 'Pre-Construction' },
    { value: "Other", label: 'Other' },
];


export const staticGrid = [
    { name: 'Commercial Real Estate', subType: '' },
    { name: 'Development Site', subType: 'Development Site' },
    { name: 'Gas Station', subType: 'Gas Station' },
    { name: 'Hospitality', subType: 'Hospitality' },
    { name: 'Industrial', subType: 'Industrial' },
    { name: 'Land', subType: 'Land' },
    { name: 'Medical Office', subType: 'Medical Office' },
    { name: 'Mixed Use', subType: 'Mixed Use' },
    { name: "Mobile Home Park", subType: "Mobile Home Park" },
    { name: 'Multifamily', subType: 'Multifamily' },
    { name: 'Office', subType: 'Office' },
    { name: 'Plaza', subType: 'Plaza' },
    { name: 'Retail', subType: 'Retail' },
    { name: "RV Park", subType: "Mobile Home Park" },
    { name: 'Sale of Business with Property', subType: 'Sale of Business with Property' },
    { name: 'Sale of Business without Property', subType: 'Sale of Business without Property' },
    { name: 'Self Storage', subType: 'Self Storage' },
    { name: 'Senior Assisted Living', subType: 'Senior/Assisted Living' },
    { name: 'Special Purpose', subType: 'Special Purpose' },
    { name: 'Store with Apt or Office', subType: 'Store w/Apt/Office' },
    { name: 'Student Housing', subType: 'Student Housing' },
    { name: "Trailer Lot", subType: "Mobile Home Park" },
    { name: "Trailer Park", subType: "Mobile Home Park" },
    { name: 'Other', subType: 'Other' }
]

export const areaGrid = [
    {
        name: "Canada",
        slug: "can",
        fullName: 'Canada',
        geoBoundary: "abc",
        type: 'country',
        center: {
            lat: 52.130366,
            lng: -95.346771,
        },
        children: [
            {
                name: "Ontario",
                slug: "on",
                geoBoundary: "abc",
                type: 'province',
                center: {
                    lat: 51.2538,
                    lng: -85.3232,
                },
                children: [
                    {
                        name: "Toronto",
                        slug: "toronto-on",
                        fullName: 'Toronto, ON',
                        geoBoundary: "abc",
                        type: 'city',
                    },
                    {
                        name: "Hamilton",
                        slug: "hamilton-on",
                        fullName: 'Hamilton, ON',
                        geoBoundary: "abc",
                        type: 'city',
                    },
                    {
                        name: "Mississauga",
                        slug: "mississauga-on",
                        fullName: 'Mississauga, ON',
                        geoBoundary: "abc",
                        type: 'city',
                    },
                    {
                        name: "Brampton",
                        slug: "brampton-on",
                        fullName: 'Brampton, ON',
                        geoBoundary: "abc",
                        type: 'city',
                    },
                    {
                        name: "Ajax",
                        slug: "ajax-on",
                        fullName: 'Ajax, ON',
                        geoBoundary: "abc",
                        type: 'city',
                    },
                    {
                        name: "Barrie",
                        slug: "barrie-on",
                        fullName: 'Barrie, ON',
                        geoBoundary: "abc",
                        type: 'city',
                    },
                    {
                        name: "St Catharines",
                        fullName: 'St Catharines, ON',
                        slug: "st-catharines-on",
                        geoBoundary: "abc",
                        type: 'city',
                    },
                    {
                        name: "Niagara Falls",
                        fullName: 'Niagara Falls, ON',
                        slug: "niagara-falls-on",
                        geoBoundary: "abc",
                        type: 'city',
                    },
                    {
                        name: "London",
                        fullName: 'London, ON',
                        slug: "london-on",
                        geoBoundary: "abc",
                        type: 'city',
                    }
                ]
            },
            {
                name: "Alberta",
                slug: "ab",
                geoBoundary: "abc",
                type: 'province',
                center: {
                    lat: 53.9333,
                    lng: -116.5765,
                },
                children: [
                    {
                        name: "Edmonton",
                        slug: "edmonton-ab",
                        fullName: 'Edmonton, AB',
                        type: 'city',
                        geoBoundary: "abc"
                    },
                ]
            },
            {
                name: "Saskatchewan",
                slug: "sk",
                geoBoundary: "abc",
                type: 'province',
                center: {
                    lat: 52.9399,
                    lng: -106.4509,
                },
                children: [
                    {
                        name: "Regina",
                        slug: "regina-sk",
                        fullName: 'Regina, SK',
                        type: 'city',
                        geoBoundary: "abc"
                    },
                ]
            },
            {
                name: "British Columbia",
                slug: "bc",
                geoBoundary: "abc",
                type: 'province',
                center: {
                    lat: 53.7267,
                    lng: -127.6476,
                },
                children: [
                    {
                        name: "Vancouver",
                        slug: "vancouver-bc",
                        fullName: 'vancouver, BC',
                        type: 'city',
                        geoBoundary: "abc"
                    },
                ]
            },
            {
                name: "Manitoba",
                slug: "mb",
                geoBoundary: "abc",
                type: 'province',
                center: {
                    lat: 53.7609,
                    lng: -98.8139
                },
                children: [
                    {
                        name: "Winnipeg",
                        slug: "winnipeg-mb",
                        fullName: 'Winnipeg, MB',
                        type: 'city',
                        geoBoundary: "abc"
                    },
                ]
            },
            {
                name: "Quebec",
                slug: "qc",
                geoBoundary: "abc",
                type: 'province',
                center: {
                    lat: 52.9399,
                    lng: -73.5491
                },
                children: [
                    {
                        name: "Montreal",
                        slug: "montreal-qc",
                        fullName: 'Montreal, QC',
                        type: 'city',
                        geoBoundary: "abc"
                    },
                ]
            }
        ]
    }
]

export const titleOptions = [
    { value: 'Salesperson', label: 'Salesperson' },
    { value: 'Broker', label: 'Broker' },
    { value: 'Broker of Record', label: 'Broker of Record' },
]

export const provinceOptions = [
    { value: 'Alberta', label: 'Alberta' },
    { value: 'British Columbia', label: 'British Columbia' },
    { value: 'Manitoba', label: 'Manitoba' },
    { value: 'New Brunswick', label: 'New Brunswick' },
    { value: 'Newfoundland and Labrador', label: 'Newfoundland and Labrador' },
    { value: 'Northwest Territories', label: 'Northwest Territories' },
    { value: 'Nova Scotia', label: 'Nova Scotia' },
    { value: 'Nunavut', label: 'Nunavut' },
    { value: 'Ontario', label: 'Ontario' },
    { value: 'Prince Edward Island', label: 'Prince Edward Island' },
    { value: 'Québec', label: 'Québec' },
    { value: 'Saskatchewan', label: 'Saskatchewan' },
    { value: 'Yukon', label: 'Yukon' },
]

export const provinceSlugs = [
    { value: 'Alberta', slug: 'AB' },
    { value: 'British Columbia', slug: 'BC' },
    { value: 'Manitoba', slug: 'MB' },
    { value: 'New Brunswick', slug: 'NB' },
    { value: 'Newfoundland and Labrador', slug: 'NL' },
    { value: 'Northwest Territories', slug: 'NWT' },
    { value: 'Nova Scotia', slug: 'NS' },
    { value: 'Nunavut', slug: 'NT' },
    { value: 'Ontario', slug: 'ON' },
    { value: 'Prince Edward Island', slug: 'PEI' },
    { value: 'Québec', slug: 'QC' },
    { value: 'Saskatchewan', slug: 'SK' },
    { value: 'Yukon', slug: 'YT' },
]

export const dateUploadedOptions = [
    { value: "24 hours", label: "24 hours" },
    { value: "7 days", label: "7 days" },
    { value: "2 weeks", label: "2 weeks" },
    { value: "1 month", label: "1 month" },
    { value: "3 months", label: "3 months" },
    { value: "6 months", label: "6 months" },
    { value: "12 months", label: "12 months" },
]

export const sortByOptions = [
    { value: "Date uploaded", label: "Date uploaded" },
    { value: "Date updated", label: "Date updated" },
    { value: "Price (high to low)", label: "Price (high to low)" },
    { value: "Price (low to high)", label: "Price (low to high)" },
    { value: "Cap rate (high to low)", label: "Cap rate (high to low)" },
    { value: "Cap rate (low to high)", label: "Cap rate (low to high)" },
    { value: "Sq ft (high to low)", label: "Sq ft (high to low)" },
    { value: "Sq ft (low to high)", label: "Sq ft (low to high)" },
    { value: "Lot size (high to low)", label: "Lot size (high to low)" },
    { value: "Lot size (low to high)", label: "Lot size (low to high)" },
]

export const industryRoleOptions = [
    { value: "Realtor", label: "Realtor" },
    { value: "Principal", label: "Owner/Investor" },
    { value: "Service Provider", label: "Service Provider" },
]

export const BuildingClassOptions = [
    { value: "A", label: "A" },
    { value: "B", label: "B" },
    { value: "C", label: "C" },

]

export const SaleConditionOptions = [
    { value: "Sale leaseback", label: "Sale leaseback" },
    { value: "Redevelopment Project", label: "Redevelopment Project" },
    { value: "Building in Shell Condition", label: "Building in Shell Condition" },

]

export const SaleTypeOptions = [
    { value: "For investors", label: "For investors" },
    { value: "For owner-users", label: "For owner-users" },
    { value: "Ideal for both investors and owner-users", label: "Ideal for both investors and owner-users" },

]

export const tenancyOptions = [
    { value: "Single", label: "Single" },
    { value: "Multiple", label: "Multiple" },
]

export const permittedUsesOptions = [
    { label: "Agribusiness", value: "Agribusiness" },
    { label: "Agricultural", value: "Agricultural" },
    { label: "Airplane Hangar", value: "Airplane Hangar" },
    { label: "Airport ", value: "Airport " },
    { label: "Amusement Park", value: "Amusement Park" },
    { label: "Apartment Units", value: "Apartment Units" },
    { label: "Auto Dealership", value: "Auto Dealership" },
    { label: "Auto Repair", value: "Auto Repair" },
    { label: "Auto Salvage Facility", value: "Auto Salvage Facility" },
    { label: "Bank ", value: "Bank " },
    { label: "Bar ", value: "Bar " },
    { label: "Sports Field", value: "Sports Field" },
    { label: "Bowling Alley", value: "Bowling Alley" },
    { label: "Car Wash", value: "Car Wash" },
    { label: "Casino", value: "Casino" },
    { label: "Cement/Gravel Plant", value: "Cement/Gravel Plant" },
    { label: "Cemetery/Mausoleum", value: "Cemetery/Mausoleum" },
    { label: "Commercial", value: "Commercial" },
    { label: "Community Centre", value: "Community Centre" },
    { label: "Contractor Storage Yard", value: "Contractor Storage Yard" },
    { label: "Convenience Store", value: "Convenience Store" },
    { label: "Correctional Facility", value: "Correctional Facility" },
    { label: "Day Care Centre", value: "Day Care Centre" },
    { label: "Department Store", value: "Department Store" },
    { label: "Distribution", value: "Distribution" },
    { label: "Drug Store", value: "Drug Store" },
    { label: "Fast Food", value: "Fast Food" },
    { label: "Food Processing", value: "Food Processing" },
    { label: "Funeral Home", value: "Funeral Home" },
    { label: "Garden Centre", value: "Garden Centre" },
    { label: "General Freestanding", value: "General Freestanding" },
    { label: "Golf Course/Driving Range", value: "Golf Course/Driving Range" },
    { label: "Health Care", value: "Health Care" },
    { label: "Hold for Development", value: "Hold for Development" },
    { label: "Hold for Investment", value: "Hold for Investment" },
    { label: "Horse Stables", value: "Horse Stables" },
    { label: "Hospital", value: "Hospital" },
    { label: "Hospitality", value: "Hospitality" },
    { label: "Hotel", value: "Hotel" },
    { label: "Industrial", value: "Industrial" },
    { label: "Industrial Live/Work Unit", value: "Industrial Live/Work Unit" },
    { label: "Industrial Park", value: "Industrial Park" },
    { label: "Landfill", value: "Landfill" },
    { label: "Lodge/Meeting Hall", value: "Lodge/Meeting Hall" },
    { label: "Loft/Creative Space", value: "Loft/Creative Space" },
    { label: "Lumberyard", value: "Lumberyard" },
    { label: "Manufactured Homes/Mobile Home Park", value: "Manufactured Homes/Mobile Home Park" },
    { label: "Marina", value: "Marina" },
    { label: "Master Planned Community", value: "Master Planned Community" },
    { label: "Medical", value: "Medical" },
    { label: "Mixed Use", value: "Mixed Use" },
    { label: "Motel", value: "Motel" },
    { label: "Movie Theater", value: "Movie Theater" },
    { label: "Movie/Radio/TV Studio", value: "Movie/Radio/TV Studio" },
    { label: "Multifamily", value: "Multifamily" },
    { label: "Neighborhood Centre", value: "Neighborhood Centre" },
    { label: "Office", value: "Office" },
    { label: "Office Park", value: "Office Park" },
    { label: "Open Space", value: "Open Space" },
    { label: "Outlet Centre", value: "Outlet Centre" },
    { label: "Parking Garage", value: "Parking Garage" },
    { label: "Parking Lot", value: "Parking Lot" },
    { label: "Pasture/Ranch", value: "Pasture/Ranch" },
    { label: "Planned Unit Development", value: "Planned Unit Development" },
    { label: "Police/Fire Station", value: "Police/Fire Station" },
    { label: "Post Office", value: "Post Office" },
    { label: "Public Library", value: "Public Library" },
    { label: "Public Park", value: "Public Park" },
    { label: "Public Swimming Pool", value: "Public Swimming Pool" },
    { label: "R&D ", value: "R&D " },
    { label: "Race Track", value: "Race Track" },
    { label: "Radio/TV", value: "Radio/TV" },
    { label: "Railroad Yard", value: "Railroad Yard" },
    { label: "Recycling Centre", value: "Recycling Centre" },
    { label: "Refrigeration/Cold Storage", value: "Refrigeration/Cold Storage" },
    { label: "Regional Mall", value: "Regional Mall" },
    { label: "Rehabilitation Centre", value: "Rehabilitation Centre" },
    { label: "Religious Facility", value: "Religious Facility" },
    { label: "Restaurant ", value: "Restaurant " },
    { label: "Retail", value: "Retail" },
    { label: "Retail Warehouse", value: "Retail Warehouse" },
    { label: "Schools", value: "Schools" },
    { label: "Self Storage", value: "Self Storage" },
    { label: "Service Station", value: "Service Station" },
    { label: "Shipyard", value: "Shipyard" },
    { label: "Single Family Development", value: "Single Family Development" },
    { label: "Single Family Residence", value: "Single Family Residence" },
    { label: "Skating Rink", value: "Skating Rink" },
    { label: "Specialty/Festival/Entertainment ", value: "Specialty/Festival/Entertainment " },
    { label: "Storefront Retail/Residential", value: "Storefront Retail/Residential" },
    { label: "Storefront", value: "Storefront" },
    { label: "Storefront Retail/Office", value: "Storefront Retail/Office" },
    { label: "Strip Centre", value: "Strip Centre" },
    { label: "Supermarket ", value: "Supermarket " },
    { label: "Theater/Concert Hall ", value: "Theater/Concert Hall " },
    { label: "Timberland", value: "Timberland" },
    { label: "Trailer/Camper Park", value: "Trailer/Camper Park" },
    { label: "Truck Stop", value: "Truck Stop" },
    { label: "Truck Terminal", value: "Truck Terminal" },
    { label: "Veterinarian/Kennel", value: "Veterinarian/Kennel" },
    { label: "Warehouse", value: "Warehouse" },
    { label: "Water Retention Facility", value: "Water Retention Facility" },
    { label: "Water Treatment Facility", value: "Water Treatment Facility" },
    { label: "Winery/Vineyard", value: "Winery/Vineyard" },

]

export const BusinessRealEstateOptions = [
    { label: "Owned", value: "Owned" },
    { label: "Leased", value: "Leased" }
];

export const BuildingTypes = ["Office", "Industrial", "Retail", "Multifamily", "Special Purpose", "Hospitality"]

export const NonBusiness = ["Office", "Industrial", "Retail", "Multifamily", "Special Purpose", "Hospitality", "Land"]

export const OfficeIndustrial = ["Office", "Industrial"]

export const OfficeIndustrialRetail = ["Office", "Industrial", "Retail"]

export const leaseAgreementOptions = [
    { label: "Direct", value: "Direct" },
    { label: "Sublease", value: "Sublease" },

]

export const RoomBased = ["Multifamily", "Hospitality",]

export const MaybeInvestment = ["Office", "Industrial", "Retail", "Special Purpose", "Land"]

export const MultiUnit = ["Office", "Multifamily", "Retail", "Industrial", "Mixed Use", "Sepcial Purpose", "Hospitality"]

export const DetailsFields = [
    { value: 'businessType', label: 'Business Type' },
    { value: 'yearBuilt', label: 'Year built' },
    { value: 'yearRenovated', label: 'Year renovated' },
    { value: 'yearEstablished', label: 'Year established' },
    { value: 'totalBuildingSize', label: 'Total building size (sq. ft.)' },
    { value: 'typicalFloorSize', label: 'Typical floor size (sq. ft.)' },
    { value: 'buildingClass', label: 'Building class' },
    { value: 'units', label: 'Units' },
    { value: 'zoning', label: 'Zoning' },
    { value: 'parkingSpaces', label: 'Parking spaces' },

    { value: 'floors', label: 'Floors' },
    { value: 'saleType', label: 'Sale type' },
    { value: 'tenancy', label: 'Tenancy' },
    { value: 'far', label: 'Floor Area Ratio (Floor Space Index)' },
    { value: 'employees', label: 'Employees' },
    { value: 'realEstate', label: 'Real estate' },
    { value: 'beds', label: 'Beds' },
    { value: 'baths', label: 'Baths' },
    { value: 'docks', label: 'Docks' },
    { value: 'driveInBays', label: 'Drive-in bays' },
    { value: 'crossDocks', label: 'Cross docks' },
    { value: 'railAccess', label: 'Rail access' },
    { value: 'powerSupply', label: 'Power supply' },
]

export const RealtorVisibleFields = [
    { value: 'commission', label: 'Commission' },
]

export const ListFields = [
    { value: 'permittedUses', label: 'Permitted uses' },

]

export const MoneyFields = [
    { value: 'noi', label: 'Net operating income' },
    { value: 'rent', label: 'Rent' },
    { value: 'inventory', label: 'Inventory' },
    { value: 'ebitda', label: 'EBITDA' },
    { value: 'grossRevenue', label: 'Gross revenue' },
    { value: 'ffande', label: 'Furnitures, Fixtures, and Equipment' },
]

export const PercentFields = [
    { value: 'percentLeased', label: 'Percent leased' },
    { value: 'cap', label: 'Cap rate' }
]

export const largeBusinessFields = [
    { value: 'competition', label: 'Competition' },
    { value: 'growth', label: 'Growth and expansion potential' },
    { value: 'support', label: 'Support and training for new ownership' },
    { value: 'reason', label: 'Reason for selling' },
    { value: 'currentImprovements', label: 'Current improvements' },

]

export const SavedSearchFrequencyOptions = [
    { value: 'Daily', label: 'Daily' },
    { value: 'Weekly', label: 'Weekly' },
    { value: 'No alerts', label: 'No alerts' },

]

export const WantAlertFrequencyOptions = [
    { value: 'Daily', label: 'Daily' },
    { value: 'Weekly', label: 'Weekly' },

]

export const availabilityOptions = [
    { value: 'Available', label: 'Currently Available' },
    { value: 'Not available', label: 'Not available' },

]