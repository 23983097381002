import { initializeApp, getApps } from 'firebase/app'

const config = {
  apiKey: process.env.FIREBASE_PUBLIC_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  appId: process.env.FIREBASE_APP_ID,
}

// const devConfig = {
//   apiKey: process.env.NEXT_PUBLIC_FIREBASE_PUBLIC_API_KEY_DEV,
//   authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN_DEV,
//   databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL_DEV,
//   projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID_DEV,
//   measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID_DEV,
//   storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET_DEV,
//   appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID_DEV,
// }



export default function initFirebase() {
  if (!getApps().length) {
    // console.log('initializing firebase init firebase file');
    // let production = false;
    // if (typeof window !== 'undefined') {
    //   const hostname = window.location.hostname;
    //   hostname == 'zonado.com' ? production = true : null;
    //   console.log(production ? config : devConfig);
    // }
    initializeApp(config)
  }
}
