/**
 * This is a singleton to ensure we only instantiate Stripe once.
 */
import { loadStripe } from '@stripe/stripe-js';

let env_check = process.env.NODE_ENV === 'production';


let stripePromise;
const getStripe = () => {
    if (!stripePromise) {

        stripePromise = loadStripe(env("STRIPE_PUBLISHABLE_DEV_KEY"));
    }


    return stripePromise;
};

export default getStripe;
