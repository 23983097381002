import { ListItem, Text } from "@chakra-ui/react";
import Link from "next/link";
import React from "react";
import { EmailIcon } from "@chakra-ui/icons";

// faq
export const FAQHeader = ({ text }) => (
  <Text
    fontSize={"16px"}
    textAlign={{ base: "center", md: "start" }}
    lineHeight={"15px"}
    fontWeight={"500"}
    color={"brand.900"}
    mb={{ base: "20px", md: "16px" }}
  >
    {text}
  </Text>
);

export const FAQTitle = ({ text }) => (
  <Text
    fontSize={{ base: "26px", lg: "38px" }}
    textAlign={{ base: "center", md: "start" }}
    lineHeight={{ base: "34px", lg: "51px" }}
    fontWeight={"500"}
    color={"brand.black"}
  >
    {text}
  </Text>
);

// footer
export const MiniFooterText = ({ text, ...rest }) => (
  <>
    <Text
      color={"gray.500"}
      w={"40%"}
      fontSize={"16px"}
      fontWeight={{ md: "24px", lg: "28px" }}
      {...rest}
    >
      {text}
      <Text
        fontSize="16px"
        mt="25px"
        color={"#1A202C"}
        display={{ base: "none", md: "block" }}
      >
        Need help? <br />
        <Text fontSize={"16px"} fontWeight={700} mt="20px">
          <a href="mailto:support@zonado.com">
            <EmailIcon w="16px" h="16px" /> support@zonado.com
          </a>
        </Text>
      </Text>
    </Text>
  </>
);

export const FooterLinksTitle = ({ text, ...rest }) => (
  <Text
    fontWeight={"500"}
    fontSize={"20px"}
    lineHeight={"19px"}
    mb={{ base: "20px", lg: "38px" }}
    color={"brand.black"}
    {...rest}
  >
    {text}
  </Text>
);

// links
export const HeaderLink = ({ text, route, ...rest }) => (
  <Text color="gray.500" fontSize={"15px"} fontWeight={500} {...rest}>
    <Link href={route} prefetch={false}>
      {text}
    </Link>
  </Text>
);

export const FooterLink = ({ text, route, last }) => (
  <ListItem
    mb={last ? 0 : { base: "22px", md: "15px", lg: "21px" }}
    color={"gray.500"}
    listStyleType={"none"}
    fontSize={{ base: "15px" }}
    lineHeight={"25px"}
  >
    <Link href={`/${route}`} prefetch={false}>
      {text}
    </Link>
  </ListItem>
);

// headings
export const HeaderText = ({ text }) => (
  <Text
    as={"h1"}
    fontSize={{ base: "34px", lg: "50px" }}
    lineHeight={{ base: "44px", lg: "65px" }}
    fontWeight={"500"}
  >
    {text}
  </Text>
);

export const SemiHeaderText = ({ text }) => (
  <Text
    as={"h4"}
    fontSize={{ base: "14px", lg: "16px" }}
    lineHeight={{ base: "23px", lg: "24px" }}
    fontWeight={"normal"}
    mt={"24px"}
    color={"gray.500"}
  >
    {text}
  </Text>
);

export const SectionHeader = ({ text, ...rest }) => (
  <Text
    fontSize={{ base: "24px", lg: "30px" }}
    lineHeight={{ base: "36px", lg: "48px" }}
    color={"brand.black"}
    fontWeight={500}
    {...rest}
  >
    {text}
  </Text>
);

export const HowItWorksHeader = ({ text }) => (
  <Text
    fontSize={{ base: "24px", lg: "38px" }}
    lineHeight={{ base: "23px", lg: "36px" }}
    fontWeight={"500"}
    color={"brand.black"}
  >
    {text}
  </Text>
);

export const WhoWeHelpHeader = ({ text }) => (
  <Text
    as={"h1"}
    mb={{ base: "64px", md: "0" }}
    color={"brand.black"}
    fontSize={{ base: "24px", lg: "60px" }}
    lineHeight={{ base: "26px", lg: "76px" }}
    fontWeight={"500"}
    textAlign={"center"}
  >
    {text}
  </Text>
);

export const AboutSectionHeader = ({ text, ...rest }) => (
  <Text
    color={"brand.black"}
    mb={"48px"}
    fontSize={{ base: "24px", lg: "34px" }}
    lineHeight={{ base: "36px", lg: "37px" }}
    fontWeight={"500"}
    {...rest}
  >
    {text}
  </Text>
);

export const RealtorsHeader = ({ text, ...rest }) => (
  <Text
    color={"brand.black"}
    textAlign={"center"}
    fontWeight={"500"}
    fontSize={{ base: "24px", md: "30px" }}
    lineHeight={{ base: "36px", md: "40px" }}
    {...rest}
  >
    {text}
  </Text>
);

export const RealtorsSemiHeader = ({ text, ...rest }) => (
  <Text
    textAlign={"center"}
    fontWeight={"400"}
    fontSize={"16px"}
    lineHeight={"28px"}
    color={"gray.500"}
    mt={"8px"}
    w={{ md: "70%" }}
    mx={"auto"}
    {...rest}
  >
    {text}
  </Text>
);

export const AboutUsHeader = ({ text }) => (
  <Text
    as={"h1"}
    color={"brand.black"}
    fontSize={{ base: "34px", lg: "60px" }}
    lineHeight={{ base: "44px", lg: "78px" }}
    fontWeight={"500"}
  >
    {text}
  </Text>
);

export const AboutUsSemiHeader = ({ text, ...rest }) => (
  <Text
    as={"h3"}
    color={"gray.500"}
    mt={{ base: "24px", md: "20px", lg: "32px" }}
    fontSize={{ base: "14px", lg: "16px" }}
    lineHeight={{ md: "24px", lg: "27px" }}
    fontWeight={"400"}
    {...rest}
  >
    {text}
  </Text>
);

// body
export const SectionBody = ({ text, ...rest }) => (
  <Text
    fontSize={{ base: "14px", lg: "18px" }}
    lineHeight={{ base: "23px", lg: "29px" }}
    color={"gray.500"}
    fontWeight={500}
    mt={"20px"}
    {...rest}
  >
    {text}
  </Text>
);

export const TrustPilotRatings = ({ children }) => (
  <Text
    fontSize={"14px"}
    lineHeight={"23px"}
    fontWeight={500}
    color={"gray.500"}
    mt={"6px"}
    alignItems={"center"}
    display={"flex"}
  >
    {children}
  </Text>
);

export const WhoWeHelpSemiHeader = ({ text }) => (
  <Text
    as={"h2"}
    color={"gray.500"}
    fontSize={{ md: "14px", lg: "18px" }}
    display={{ base: "none", md: "block" }}
    w={"80%"}
    mx={"auto"}
    lineHeight={{ md: "20px", lg: "26px" }}
    fontWeight={"400"}
    textAlign={"center"}
    mt={{ md: "12px", md: "24px" }}
    mb={{ md: "50px", lg: "96px" }}
  >
    {text}
  </Text>
);
