import { Box, Flex } from '@chakra-ui/react'
import React from 'react'
import { WrapperStyle } from '../../styles/newDesignStyles'
import { FAQHeader, FAQTitle } from '../../styles/typography'
import { FaqCard } from '../Landing'

export const FaqBanner = ({ data }) => {
    return (
        <Box backgroundColor={"brand.100"} py={{ base: "50px", lg: "60px" }} mb={{ base: "70px", lg: "90px" }}>
            <WrapperStyle>
                <Flex flexDir={{ base: "column", md: "row" }} justifyContent={"space-between"}>

                    <Box w={{ md: "40%" }} mb={{ base: "50px", md: 0 }}>
                        <FAQHeader text={"FAQ"} />
                        <FAQTitle text={"Frequently Asked Questions"} />
                    </Box>

                    <Flex w={{ md: "50%" }} flexDir={"column"} justifyContent={"center"}>
                        {
                            data.map(({ id, ...rest }, index) =>
                                <FaqCard
                                    key={id}
                                    item={rest}
                                    last={index === (data.length - 1)}
                                />
                            )
                        }
                    </Flex>

                </Flex>
            </WrapperStyle>
        </Box >
    )
}