import { Box, Text } from '@chakra-ui/react'
import React from 'react'

export const AuxiliaryBanner = ({ title, body, routeAction }) => {
    return (
        <Box backgroundColor={"brand.100"} pt={{ base: "60px", lg: "20px" }} pb={"47px"} mb={"96px"}>
            <Text w={"80%"} mx={"auto"} mb={"17px"} fontSize={{ base: "20px", lg: "24px" }} fontWeight={"500"} lineHeight={{ base: "26px", lg: "31px" }} textAlign={"center"}>{title}</Text>
            
            <a href={routeAction}>
                <Text color={"brand.900"} textAlign={"center"} fontSize={{ base: "16px", lg: "20px" }} lineHeight={{ base: "21px", lg: "26px" }}>{body}</Text>
            </a>
        </Box>
    )
}