import {
  Menu,
  Avatar,
  Button,
  Image as ImageComponent,
  MenuButton,
  MenuGroup,
  MenuList,
  MenuItem,
  MenuDivider,
  Text,
  Flex,
  Box,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import Image from "next/image";
import Link from "next/link";
import React, { useEffect, useState, useRef } from "react";
import * as Sentry from "@sentry/nextjs";
import { useQuery } from 'react-query'
import {
  Header,
  HeaderRight,
  WrapperStyle,
  HeaderWrapper,
  HeaderLeft,
  BrowseButton,
  BrowseContainer,
  BrowseContentContainer,
  BrowseContentLeft,
  BrowseContentRight,
  SidebarBrowseContainer,
  SidebarBrowseContentContainer,
  BrowseContentTitleContainer,
} from "../../styles/newDesignStyles";
import { useAuth } from "../../utils/auth/newAuth";
import { AuthModal } from "../Auth/withAuthModal";
import {
  ArrowForwardIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  TriangleDownIcon,
} from "@chakra-ui/icons";


import slugify from "slugify";
import useWindowSize from "../Utility/windowSize";

const NewHeader = () => {
  const { user, profile, signout } = useAuth();
  const [isAuthBoxOpen, setIsAuthBoxOpen] = useState(false);
  const [toggleBrowse, setToggleBrowse] = useState(false);
  const [showPropType, setShowPropType] = useState("Land");
  const [propTypes, setPropTypes] = useState(null);
  const { isOpen: openDrawer, onOpen, onClose } = useDisclosure();
  const {
    isOpen: openInnerDrawer,
    onOpen: onOpenInnerDrawer,
    onClose: onCloseInnerDrawer,
  } = useDisclosure();
  const size = useWindowSize();
  const handleToggleBrowse = () => {
    setToggleBrowse(!toggleBrowse);
  };

  const handlePropType = (propType) => {
    setShowPropType(propType);
  };



  function useOutsideBrowseClose(ref) {
    useEffect(() => {
      // Close Browse menu if clicked outside of it
      function handleClickOutside(event) {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          browseButtonRef.current &&
          !browseButtonRef.current.contains(event.target)
        ) {
          setToggleBrowse(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousdown", handleClickOutside);
      };
    }, [ref]);
  }

  const browseButtonRef = useRef(null);
  const browseRef = useRef(null);
  useOutsideBrowseClose(browseRef);

  return (
    <Header>
      <WrapperStyle>
        <HeaderWrapper>
          <HeaderLeft>
            <ImageComponent
              src={"/images/newLanding/hamburger.svg"}
              width={22}
              height={22}
              mr="15px"
              onClick={onOpen}
              className={"cursor-pointer"}
            />
            <Link href={"/"} passHref>
              <Image
                src={"/images/newLanding/logo.svg"}
                width={100}
                height={24}
                objectFit={"contain"}
                className={"cursor-pointer"}
              />
            </Link>
          </HeaderLeft>

          <HeaderRight>
            {user ? (
              <>
                {/* {profile && !profile?.pro &&
                <Link href={"/pro?source=externalHeader"} passHref>
                  <a>
                    <Text color="#39AB65">Zonado Pro</Text>
                  </a>
                </Link>
              } */}
                <Link href={"/listings"} passHref>
                  <a>
                    <Button
                      mr={"15px"}
                      ml={"15px"}
                      fontSize={"13px"}
                      lineHeight={"16px"}
                      size="sm"
                      variant={"outline"}
                      bg={"white"}
                      color={"brand.900"}
                      fontWeight={"500"}
                      rounded={"22px"}
                      rightIcon={<ArrowForwardIcon />}
                      borderColor={"brand.900"}
                      _hover={{
                        color: "brand.800",
                        bg: "brand.100",
                        borderColor: "brand.800",
                      }}
                    >
                      My account
                    </Button>
                  </a>
                </Link>
                <Menu

                      // @ts-ignore
                  zIndex={200}
                // animation="none"
                // isOpen={true}
                >
                  <MenuButton
                    ml="15px"
                      // @ts-ignore
                    rightIcon={<ChevronDownIcon />}
                    transition="all 0.2s"
                    _focus={{ outline: 0 }}
                    display="flex"
                    alignItems="center"
                  >
                    <Avatar
                      size="sm"
                      name=""
                      src={profile && profile["photoURL"]}
                    />
                    <ChevronDownIcon
                      focusable={true}
                      // @ts-ignore
                      size="24px"
                      color="brand.900"
                    />
                  </MenuButton>
                  <MenuList zIndex={7} animation="none">
                    <MenuGroup title="Account">
                      <Link href={`/settings`} prefetch={false} passHref>
                        <a href="">
                          <MenuItem>Settings </MenuItem>
                        </a>
                      </Link>

                      {profile?.slug &&
                        profile?.slug !== "" &&
                        profile?.industryRole == "Realtor" && (
                          <Link
                            href={`/profile/${profile.slug}`}
                            prefetch={false}
                            passHref
                          >
                            <a href="">
                              <MenuItem>View My Profile </MenuItem>
                            </a>
                          </Link>
                        )}
                    </MenuGroup>

                    <MenuDivider />
                    <Button
                      onClick={() => signout()}
                      rightIcon={<ArrowForwardIcon />}
                      colorScheme="red"
                      variant="ghost"
                      size="sm"
                      ml="5px"
                    >
                      Sign Out
                    </Button>
                  </MenuList>
                </Menu>
              </>
            ) : (
              <>
                
                    <Button
                      onClick={() => setIsAuthBoxOpen(true)}
                      cursor={'pointer'}
                      size="md"
                      rounded={'full'}
                      colorScheme='green'
                      fontWeight={'normal'}
                      display={{base:'block',md:'none'}}
                    >
                      Sign in
                    </Button>
                  
                    <Box
                      display={{ base: "none", md: "flex" }}
                    >
                      <Flex>
                        <Text
                          onClick={() => setIsAuthBoxOpen(true)}
                          mr="34px"
                          cursor={'pointer'}
                          p="8px 10px"
                          borderRadius={'4px'}
                          _hover={{
                            background: 'rgba(0,0,0,0.2)'
                          }}
                        >
                          Register or Sign In
                        </Text>
                      </Flex>
                      <Link href={"/add-listing?source=ExternalHeader"} passHref>
                        <Button
                          // mx={size.width >= 586 ? "15px" : "0px"}
                          as="a"
                          fontSize={"13px"}
                          lineHeight={"16px"}
                          size="md"
                          variant="solid"
                          fontWeight={"500"}
                          colorScheme='green'
                        >
                          Add Listing
                        </Button>
                      </Link>
                    </Box>
                  
              </>
            )}
          </HeaderRight>
        </HeaderWrapper>
      </WrapperStyle>
      {size.width < 578 && (
        <Drawer
          isOpen={openDrawer}
          placement="left"
          onClose={onClose}
          size="full"
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader>
              <DrawerCloseButton />
            </DrawerHeader>
            <DrawerBody
                fontFamily={"'Jarkarta', sans-serif"}

            >
              <Box>
              <Box borderTop={"1px solid #e6e6e6"} mt="8px">
                  <Text
                    onClick={()=> { 
                      setIsAuthBoxOpen(true);
                    }
                    }
                    py="15px"
                    fontSize={"24px"}
                  >
                    Browse Listings
                  </Text>
                </Box>
                <Box borderTop={"1px solid #e6e6e6"} mt="8px">
                  <Link passHref href={"/add-listing"}>
                    <a>
                      <Text
                        py="15px"
                        fontSize={"24px"}
                      >
                        Add Listing
                      </Text>
                    </a>
                  </Link>
                </Box>
                <Box border={"1px #e6e6e6"} borderStyle={"solid none"}>
                  <Link passHref href={"/pro?source=drawerButton"}>
                    <a>
                      <Text
                        py="15px"
                        fontSize={"24px"}
                      >
                        Zonado Pro
                      </Text>
                    </a>
                  </Link>

                </Box>
                {!user && (
                  <Flex justifyContent={"center"}>
                    <Link href={"/signup"} passHref>
                      <a>
                        <Button
                          m="0 auto"
                          mt="40px"
                          p="20px 80px"
                          fontSize={"13px"}
                          lineHeight={"16px"}
                          size="md"
                          variant="solid"
                          bg="#39AB65"
                          fontWeight={"500"}
                          rounded={"22px"}
                          color="white"
                          _hover={{
                            bg: "#74C493",
                          }}
                          onClick={() => {
                            setIsAuthBoxOpen(true);
                          }}
                        >
                          Sign in
                        </Button>
                      </a>
                    </Link>
                  </Flex>
                )}
              </Box>
            </DrawerBody>
          </DrawerContent>
      
        </Drawer>
      )}
      <AuthModal
        onClose={() => {
          setIsAuthBoxOpen(false);
        }}
        isOpen={isAuthBoxOpen}
        messageToSend={undefined}
        returnToListing={undefined}
        createListing={undefined}
        goTo={undefined}
        creatingWant={undefined}
        type={undefined}
        onSubmit={undefined}
        values={undefined}
        listingToActivate={undefined}
      />
    </Header>
  );
};

export default NewHeader;