import React, { useState } from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import Input from '../../UI/Forms/Input/Input'
import { withFirebaseHOC } from '../../../src/config/context'
import { sendPasswordResetEmail, getAuth } from "firebase/auth";
import {
    Alert,
    AlertIcon,
    FormLabel,
    Button
} from "@chakra-ui/react";
import { clientError } from '../../../utils/utilties';


const RecoverSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email.')
        .required('The email is required.')
})


const RecoverPassword = (props) => {

    const { email } = props;

    const [recoverStatus, setRecoverStatus] = useState('idle')
    const [errorMessage, setErrorMessage] = useState('')
    const auth = getAuth()

    const onRecoverButtonPressed = async values => {
        setRecoverStatus('loading')
        sendPasswordResetEmail(auth, email)
            .then(function () {
                // Email sent.
                setRecoverStatus('success')
            })
            .catch(function (error) {
                clientError(error);;
                setRecoverStatus('error')
                setErrorMessage(error.message)
            });
    }


    return ( 
        <Formik
            initialValues={{
                email: email
            }}

            validationSchema={RecoverSchema}

            onSubmit={(values, { setSubmitting }) => {
                // event.preventDefault();

                setTimeout(() => {
                    onRecoverButtonPressed(values);
                }, 1000);

            }}>

            {({ isSubmitting, isValid, handleChange, values, handleSubmit }) => (
                <div>
                    {/* <Heading1>
                        Recover your password
                    </Heading1>

                    <Heading size="p">
                        Enter your email to recover password
                    </Heading> */}

                    <form onSubmit={handleSubmit}>
                        <FormLabel
                            fontSize="12px"
                            fontWeight="400"
                        >Email</FormLabel>
                        <Field
                            type='email'
                            name='email'
                            component={Input}
                        />

                    {(recoverStatus !== 'success') &&
                        <Button 
                        width="100%"
                        isLoading={recoverStatus == 'loading'}
                        loadingText='Sending recovery email'
                        mt="8px"
                        colorScheme="green"
                        type='submit' isDisabled={!isValid}>Recover password</Button>
                        }

                    </form>
                
                {(recoverStatus === 'success') &&
                    <Alert 
                    status="success" 
                    variant="solid"
                    marginTop="20px">
                        <AlertIcon />
                            Recovery email sent. Be sure to check your spam/junk folder!
                        </Alert>
                }

                {(recoverStatus === 'error') &&
                    <Alert 
                    status="error"
                    marginTop="20px">
                        <AlertIcon />
                        {errorMessage}
                    </Alert>
                }
                </div>
            )}
        </Formik>
     );
}
 

export default withFirebaseHOC(RecoverPassword);