import styled from 'styled-components';


export const Logo = styled.a`
    width: 100px;
    height: 24px;
    float: left;
    margin-top: 0px;
    display: block;

    &:hover {
        cursor: pointer;
    }
`

export const LogoImg = styled.img`
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    width: 90px;
`

export const Wrapper = styled.div`
    box-sizing: border-box;
    height: 60px;
    width: 100%;
    /* max-width: 1900px; */
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 16px;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;   
    background-color: white;
    z-index: 15;
    border-bottom: 1px solid #d8d8d8;
`

export const StyledLink = styled.a`
    text-decoration: none;
    padding: 10px 30px;
    border: 1px solid #125F3F;
    box-sizing: border-box;
    border-radius: 5px;
    display: block;
    background-color: ${({ main }) => (main ? '#125F3F' : 'white')};
    color: ${({ main }) => (main ? 'white' : '#125F3F')};
    transition: all 0.3s ease;
    margin-left: 10px;
    font-size: 17px;
    line-height: 17px;

    &:hover {
        transform: translateY(-2px);
        cursor: pointer;
    }

     &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }

    @media (max-width: 550px) {
        padding: 10px 20px;
    }
`

export const UnStyledLink = styled.a`
    text-decoration: none;
    padding: 10px 15px;
    display: block;
    transition: all 0.3s ease;
    margin-left: 10px;
    font-size: 17px;
    line-height: 17px;

    &:hover {
        transform: translateY(-2px);
        cursor: pointer;
    }

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
`
export const SideButtons = styled.div`
    display: flex;
    align-items: center;

    @media (max-width: 500px) {
        display: none;
    }

`

export const Header = styled.header`
    background-color: white;

    @media (max-width: 725px) {
        display: flex;
        flex-direction: column;
    }
 
`

export const AreaSearch = styled.div`
    width: 224px;
    flex-grow: 1;
`

export const TypeHolder = styled.div`
    width: 130px;
`

export const Searcher = styled.form`
	display: flex;
	justify-content: space-between;
	max-width: 360px;
    margin-left: 20px;

    @media (max-width: 1040px) {
        display: none;
    }
`

export const HitSearch = styled.a`
    background-color: #125F3F;
    border: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom: 2px solid white;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;

    &:hover {
        cursor: pointer;
    }
`

export const LogoAndSearch = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: auto;
`

export const SideAuth = styled.div`
    display: none;

    @media (max-width: 500px) {
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        align-items: center;
    }

    &:hover {
        cursor: pointer;
    }
`

export const RightSide = styled.div`
    display: flex;

    @media (max-width: 725px) {
        display: none;
    }
`

export const SmallScreen = styled.div`
    display: none;

    @media (max-width: 725px) {
        display: block;
    }
`

export const BottomSearcher = styled.form`
	display: flex;
	justify-content: space-between;
    width: 100%;
`

export const SearchWrapper = styled.div`
	display: none;
	justify-content: space-between;
    position: fixed;
    top: 53px;
    right: 0;
    left: 0;   
    background-color: white;
    z-index: 2;
    width: 100%;
    padding: 8px 16px;
    @media (max-width: 1040px) {
        display: flex;
    }
`

export const BottomAreaSearch = styled.div`
    width: 174px;
    flex-grow: 1;
`

export const SearchWrapperAbsolute = styled.div`
	justify-content: space-between;
    position: fixed;
    background-color: white;
    z-index: 15;
    left: 120px;
    width: 340px;
    top: 3px;

    padding: 8px 16px;
    @media (max-width: 1040px) {
        top: 53px;
        right: 0;
        left: 0;
        width: 100%;
    }
`
