import {
  MdMenu,
  MdSearch,
  MdAdd,
  MdChatBubbleOutline,
  MdRssFeed,
  MdSettings,
  MdLogout,
} from "react-icons/md";
import { CgMenuGridR, CgProfile } from "react-icons/cg";
import {
  HamWrapper,
  Header,
  LogoAndHam,
  LogoWrapper,
  SideButtons,
  SideButton,
  SideButtonAnchor,
  SideButtonText,
  DrawerNavBlocks,
  IconHolder,
  TextHolder,
} from "./NewHeaderElements";
import img from "../../public/images/logo.svg";
import {
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
  MenuDivider,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  useToast,
  Box,
  Flex,
  Text,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  useDisclosure,
  Badge,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Textarea,
  Divider,
  Portal,
} from "@chakra-ui/react";
import Link from "next/link";
import { useAuth } from "../../utils/auth/newAuth";
import { LogoImg } from "./HeaderElements";
import { useRouter } from "next/router";
import useWindowSize from "../Utility/windowSize";
import { AuthModal } from "../Auth/withAuthModal";
import { useRef, useState, useMemo } from "react";
import { useUser } from "../../utils/auth/useUser";
import { BsArrowUpCircle, BsFillBookmarkFill, BsFillPeopleFill, BsPeopleFill, BsViewList } from "react-icons/bs";
import { RiFeedbackLine, RiBuildingLine } from "react-icons/ri";
import { AddIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { clientAnalytics } from "../../utils/utilties";
import { useMutation } from "react-query";
import BigSearcher from "../BigSearcher/BigSearcher";
import Image from "next/image";
import { IoPersonCircleOutline } from "react-icons/io5";
import { AiOutlineUser } from "react-icons/ai";

const NewHeader = () => {
  const size = useWindowSize();
  const router = useRouter();
  const { userId, profile, loading, signout } = useAuth();
  const [isAuthBoxOpen, setIsAuthBoxOpen] = useState(false);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [openFeedback, setOpenFeedback] = useState(false);
  const btnRef = useRef();
  const pro = profile ? profile["pro"] : null;

  const [feedbackValue, setFeedbackValue] = useState("");
  const [sendFeedback, setSendFeedback] = useState("idle");
  // If true, when the user clicks on properties/businesses suggestions we take the user to the client-side rendered page; otherwise, we take the user to the static page
  const isOnListingsPage = useMemo(() => {
    if (!router.pathname.includes("/listings")) {
      return false;
    }
    // Gets the id param and checks if it's a number (listingId) to see if the current page is an individual listing page
    const idParam = router.asPath.split("/")[2];
    const idIsNotANumber = isNaN(Number(idParam));
    return idIsNotANumber;
  }, [router.pathname]);

  let handleFeedbackChange = (e) => {
    let inputValue = e.target.value;
    setFeedbackValue(inputValue);
  };

  let childAddTimer;

  const { user } = useUser();

  const triggerAuth = (hook) => {
    clientAnalytics.track("Sign up attempted", {
      hook,
    });

    // Mixpanel.track(hook);
    setIsAuthBoxOpen(true);
  };

  const onFeedbackClose = () => {
    setOpenFeedback(false);
  }
  async function receiveFeedback() {
    setSendFeedback("loading");
    // console.log(value);
    const response = await fetch("/api/feedback", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ feedback: feedbackValue, userId }),
    });

    const data = await response.json();

    return data.status;
  }

  const feedbackMutation = useMutation(receiveFeedback, {
    onError: (error) => {
      // An error happened!
      toast({
        title: "Error",
        description: `An error occured. Please email as us at hello@zonado.com instead`,
        status: "error",
        duration: 10000,
        isClosable: true,
      });
    },

    onSuccess: (data) => {
      if (data) {
        setSendFeedback("success");

        toast({
          status: "success",
          description: "Thank you so much for sharing your thoughts.",
          duration: 5000,
          title: "Your feedback was submitted",
          variant: "subtle",
        });

        onFeedbackClose();
      } else throw "error";
    },
  });


  const onAuthBoxClose = () => {
    setIsAuthBoxOpen(false);
  };

  const MainOptions = (
    <>
      {profile && !profile?.pro && size.width > 1000 &&
        <Button
          colorScheme='blue'
          variant='outline'
          rounded={"22px"}

          // Don't show on md if on listings
          display={router.pathname.includes('/listings') ? { base: "none", lg: "flex" } : 'flex'}
          size="sm"
          onClick={() => {
            clientAnalytics.track({
              userId: userId,
              event: 'Interest in upgrade hook',
              properties: {
                reason: 'Upgrade button in header',
              }
            });

            router.push('/pro')
          }}
          mr='10px'
          rightIcon={<ArrowForwardIcon />}
        >
          Upgrade
        </Button>
      }

      <SideButton>
        <Link href="/add-listing" prefetch={false} passHref>
          <SideButtonAnchor>
            <MdAdd size="24px" />
            <SideButtonText>Add listing</SideButtonText>
          </SideButtonAnchor>
        </Link>
      </SideButton>


      <SideButton>
        <Link href="/inbox" prefetch={false} passHref>
          <SideButtonAnchor>
            <MdChatBubbleOutline size="24px" />
            <SideButtonText>Messages</SideButtonText>
            {profile?.unreadCounter > 0 && (
              <Flex
                position="absolute"
                top="-7px"
                right="25px"
                cursor="pointer"
                justifyContent="center"
                alignItems="center"
              // backgroundColor="white"
              >
                <Text
                  display="block"
                  width="20px"
                  height="18px"
                  color="white"
                  textAlign="center"
                  borderRadius="50%"
                  backgroundColor="red.500"
                  fontSize="11px"
                  fontWeight="700"
                >
                  {profile?.unreadCounter}
                </Text>
              </Flex>
            )}
          </SideButtonAnchor>
        </Link>
      </SideButton>
    </>
  );

  return (
    <Header id="top">
      <Box
        display="flex"
        justifyContent="space-between"
        gap="20px"
      >
        <LogoAndHam>
          <HamWrapper ref={btnRef} onClick={onOpen}>
            <MdMenu size="24px" />
          </HamWrapper>

          <Drawer
            preserveScrollBarGap
            isOpen={isOpen}
            placement="left"
            onClose={onClose}
            initialFocusRef={btnRef}
          >
            <DrawerOverlay />
            <DrawerContent zIndex={2147483647}>
              <DrawerHeader h="64px">
                <Link href={user ? "/listings" : "/"} passHref>
                  <LogoWrapper tabIndex={-1}>
                    <LogoImg tabIndex={-1} src={img} alt="Zonado Logo" />
                  </LogoWrapper>
                </Link>
              </DrawerHeader>

              <DrawerBody padding="0px">
                <Flex
                  ref={btnRef}
                  justifyContent="space-between"
                  flexDirection="column"
                  height="100%"
                >
                  <Flex flexDir="column" flex="1 0 auto">
                    {profile &&
                    <Link href="/">
                      <DrawerNavBlocks
                        name="/"
                        pathname={router.pathname}
                      >
                        <IconHolder>
                          <Image
                            src={router.pathname === '/' ? "/images/For_you_active.svg" : "/images/For_you_inactive.svg"}
                            height="24px"
                            width="24px"
                          />
                        </IconHolder>

                        <TextHolder>For you</TextHolder>
                      </DrawerNavBlocks>
                    </Link>
                  }

                    <Link href="/listings">
                      <DrawerNavBlocks
                        name="/listings"
                        pathname={router.pathname}
                      >
                        <IconHolder>
                          <BsViewList color="inherit" size="24px" />
                        </IconHolder>

                        <TextHolder>New listings</TextHolder>
                      </DrawerNavBlocks>
                    </Link>

                    <Link href="/active-wants">
                      <DrawerNavBlocks
                        name="/active-wants"
                        pathname={router.pathname}
                      >
                        <IconHolder>
                          <MdRssFeed color="inherit" size="24px" />
                        </IconHolder>

                        <TextHolder>Active wants</TextHolder>
                      </DrawerNavBlocks>
                    </Link>

                    <Link href="/agents">
                      <DrawerNavBlocks
                        name="/agents"
                        pathname={router.pathname}
                      >
                        <IconHolder>
                          <BsFillPeopleFill color="inherit" size="24px" />
                        </IconHolder>

                        <TextHolder>Find an agent</TextHolder>
                      </DrawerNavBlocks>
                    </Link>
                  


                    {profile && 
                    <>

                      <Link href="/my-listings">
                        <DrawerNavBlocks name="/my-listings" pathname={router.pathname}>
                          <IconHolder>
                            <RiBuildingLine color="inherit" size="24px" />
                          </IconHolder>

                          <TextHolder>My listings</TextHolder>
                        </DrawerNavBlocks>
                      </Link>

                      <Link href="/my-wants">
                        <DrawerNavBlocks
                          name="/my-wants"
                          pathname={router.pathname}
                        >
                          <IconHolder>
                            <MdSearch color="inherit" size="24px" />
                          </IconHolder>

                          <TextHolder>My wants</TextHolder>
                        </DrawerNavBlocks>
                      </Link>

                        <Link href="/saved">
                          <DrawerNavBlocks name="/saved" pathname={router.pathname}>
                            <IconHolder>
                              <BsFillBookmarkFill color="inherit" size="24px" />
                            </IconHolder>

                            <TextHolder>Saved</TextHolder>
                          </DrawerNavBlocks>
                        </Link>

                      {profile?.slug && (
                        <Link href={`/profile/${profile?.slug}`}>
                          <DrawerNavBlocks
                            name={`/profile/${profile?.slug}`}
                            pathname={router.pathname}
                          >
                            <IconHolder>
                              <CgProfile color="inherit" size="24px" />
                            </IconHolder>

                            <TextHolder>My profile</TextHolder>
                          </DrawerNavBlocks>
                        </Link>
                      )}

                        <Link href="/settings">
                          <DrawerNavBlocks
                            name="/settings"
                            pathname={router.pathname}
                          >
                            <IconHolder>
                              <MdSettings color="inherit" size="24px" />
                            </IconHolder>

                            <TextHolder>Settings</TextHolder>
                          </DrawerNavBlocks>
                        </Link>
                      </>
                    }


                    <Divider borderColor="#DADCE0" />
                    {(!profile || !profile.pro) &&
                      <DrawerNavBlocks
                        feedback
                        onClick={() => {
                          clientAnalytics.track({
                            userId: userId,
                            event: 'Interest in upgrade hook',
                            properties: {
                              reason: 'Upgrade button in side drawer',
                            }
                          });

                          router.push('/pro')
                        }}
                      >
                        <IconHolder>
                          <BsArrowUpCircle color="inherit" size="24px" />
                        </IconHolder>

                        <TextHolder>Upgrade</TextHolder>
                      </DrawerNavBlocks>
                    }

                    <DrawerNavBlocks
                      onClick={() => setOpenFeedback(!openFeedback)}
                      feedback
                    >
                      <IconHolder>
                        <RiFeedbackLine color="inherit" size="24px" />
                      </IconHolder>

                      <TextHolder>Share feedback</TextHolder>
                    </DrawerNavBlocks>

                  </Flex>
                </Flex>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
          <Box>

            <Link href={user ? "/listings" : "/"} passHref>
              <LogoWrapper>
                <LogoImg src={img} alt="Zonado Logo" />
                {pro && (
                  <Box>

                    <Badge ml="6px" variant="subtle" colorScheme="green">
                      Pro
                    </Badge>
                  </Box>
                )}
              </LogoWrapper>
            </Link>
          </Box>
          {/* {!user && */}
          {!isOnListingsPage &&
          <Flex
            alignItems={"center"}
            ml="32px"
            display={{ base: "none", md: "flex", lg: "none" }}
            columnGap={'8px'}
          >
             <SideButton>
              <Link href="/listings" prefetch={false} passHref>
                <SideButtonAnchor>
                  <BsViewList size="24px" />
                  <SideButtonText>
                  New Listings
                  </SideButtonText>
                </SideButtonAnchor>
              </Link>
            </SideButton>

              <SideButton>
              <Link href="/active-wants" prefetch={false} passHref>
                <SideButtonAnchor>
                  <MdRssFeed size="24px" />
                  <SideButtonText>
                  Active Wants
                  </SideButtonText>
                </SideButtonAnchor>
              </Link>
            </SideButton> 
            {!router.pathname.includes("agents") &&        
              <SideButton>
              <Link href="/agents" prefetch={false} passHref>
                <SideButtonAnchor>
                  <BsFillPeopleFill size="24px" />
                  <SideButtonText>
                  Find an Agent
                  </SideButtonText>
                </SideButtonAnchor>
              </Link>
            </SideButton>            
            }
           
          </Flex>
            }
          {/* // } */}
        </LogoAndHam>

        {/* {
          size.width > 830 &&
          !router.pathname.includes("signup") &&
          !isOnListingsPage && 
          <BigSearcher
            showOverlay
          />
        } */}


        {!router.pathname.includes("signup") && (
          <SideButtons>
            {/* Only visible on smaller screen sizes */}
            {size.width < 520 && user && (
              <Popover
                // @ts-ignore
                zIndex={35}
                // placement="bottom"
                closeOnEsc={true}
                closeOnBlur={true}
              >
                <PopoverTrigger>
                  <HamWrapper>
                    <CgMenuGridR size="24px" />
                    {profile?.unreadCounter > 0 && (
                      <Flex
                        position="absolute"
                        top="5px"
                        right="5px"
                        cursor="pointer"
                        justifyContent="center"
                        alignItems="center"
                      // backgroundColor="white"
                      >
                        <Text
                          display="block"
                          width="20px"
                          height="18px"
                          color="white"
                          textAlign="center"
                          borderRadius="50%"
                          backgroundColor="red.500"
                          fontSize="11px"
                          fontWeight="700"
                        >
                          {profile?.unreadCounter}
                        </Text>
                      </Flex>
                    )}
                  </HamWrapper>
                </PopoverTrigger>
                <Portal>
                  <Box zIndex="popover">
                    <PopoverContent

                      _focus={{
                        boxShadow: "0 0 0 2px #125f3f",
                      }}
                      color="black"
                      borderColor="green"
                      // color="white"
                      bg="white"
                      // boxShadow="0 1px 3px 0 rgba(60,64,67,.3),0 4px 8px 3px rgba(60,64,  67,.15)"
                      maxH="calc(100vh - 134px)"
                    >
                      <PopoverArrow />
                      <PopoverBody overflow="auto"

                      >
                        <SideButtons>{MainOptions}</SideButtons>
                      </PopoverBody>
                    </PopoverContent>
                  </Box>
                </Portal>
              </Popover>
            )}
            {user
              ? size.width >= 1165 && (
                <Button
                  leftIcon={<RiFeedbackLine style={{ marginBottom: "2px" }} />}
                  variant="ghost"
                  size="sm"
                  color="#80868B"
                  mr="10px"
                  onClick={() => setOpenFeedback(!openFeedback)}
                >
                  Share feedback
                </Button>
              )
              : size.width >= 1006 && (
                <Button
                  leftIcon={<RiFeedbackLine />}
                  variant="ghost"
                  size="sm"
                  color="#80868B"
                  mr="10px"
                  onClick={() => setOpenFeedback(!openFeedback)}
                >
                  Share feedback
                </Button>
              )}
            {user ? (
              <>
                {size.width > 520 && MainOptions}

                <Popover
                // @ts-ignore
                // placement="bottom"
                closeOnEsc={true}
                closeOnBlur={true}
                
              >
                <PopoverTrigger>
                <Box
                    ml="10px"
                    // rightIcon="chevron-down"
                    transition="all 0.2s"
                    _focus={{ outline: 0 }}
                    display="flex"
                    cursor={"pointer"}
                    alignItems="center"
                  >
                    <Avatar
                      size="sm"
                      name=""
                      src={profile && profile["photoURL"]}
                    />
                    {/* <Icon
                            name="chevron-down"
                            focusable="true"
                            size="24px"
                            color="#125f3f" /> */}
                  </Box>
                </PopoverTrigger>
                <Portal>
                  <Box zIndex="popover">
                    <PopoverContent

                      _focus={{
                        boxShadow: "0 0 0 2px #125f3f",
                      }}
                      color="black"
                      borderColor="green"
                      // color="white"
                      bg="white"
                      // boxShadow="0 1px 3px 0 rgba(60,64,67,.3),0 4px 8px 3px rgba(60,64,  67,.15)"
                      maxH="calc(100vh - 134px)"
                    >
                      <PopoverArrow />
                      <PopoverBody overflow="auto"

                      >
                        <SideButtons>
                          <SideButton>
                            <Link href="/settings" prefetch={false} passHref>
                              <SideButtonAnchor>
                                <MdSettings size="24px" />
                                <SideButtonText>Settings</SideButtonText>
                              </SideButtonAnchor>
                            </Link>
                          </SideButton>
                        {profile?.slug &&
                          profile?.slug !== "" && (
                            <SideButton>
                            <Link href={`/profile/${profile.slug}`} prefetch={false} passHref>
                              <SideButtonAnchor>
                                <CgProfile size="24px" />
                                <SideButtonText>View Profile</SideButtonText>
                              </SideButtonAnchor>
                            </Link>
                          </SideButton>
                          )}

                              <SideButton onClick={signout}>
                              <SideButtonAnchor>

                                <MdLogout size="24px" />
                                <SideButtonText>Sign Out</SideButtonText>
                              </SideButtonAnchor>

                          </SideButton>
                        </SideButtons>
                      </PopoverBody>
                    </PopoverContent>
                  </Box>
                </Portal>
              </Popover>

                {/* <Menu>
                 
                  <Portal>
                  <Box zIndex="popover">
                  <MenuList>
                    <MenuGroup title="Account">
                      <Link href={`/settings`} prefetch={false} passHref>
                        <a href="">
                          <MenuItem>Settings </MenuItem>
                        </a>
                      </Link>

                      {profile?.slug &&
                        profile?.slug !== "" && (
                          <Link
                            href={`/profile/${profile.slug}`}
                            prefetch={false}
                            passHref
                          >
                            <a href="">
                              <MenuItem>View My Profile </MenuItem>
                            </a>
                          </Link>
                        )}
                    </MenuGroup>

                    <MenuDivider />
                    <Button
                      onClick={() => signout()}
                      rightIcon={<ArrowForwardIcon />}
                      colorScheme="red"
                      variant="ghost"
                      size="sm"
                      ml="5px"
                    >
                      Sign Out
                    </Button>
                  </MenuList>
                  </Box>
                  </Portal>
                </Menu> */}
              </>
            ) : (
              <>
                 {!router.pathname.includes("add-listing") && (
                  <Link href="/add-listing" passHref>
                    <a>
                      <Button
                        fontSize={{ base: "12px", md: "13px" }}
                        lineHeight={{ base: "13px", md: "16px" }}
                        display={{ base: "none", sm: "inline-block" }}
                        mr={"10px"}
                        variant="outline"
                        colorScheme="green"
                        color="green.500"
                        rounded={"22px"}
                        fontWeight={"500"}
                      >
                        Add a Free Listing
                      </Button>
                    </a>
                  </Link>
                )}
                {!router.pathname.includes("login") &&
                  !router.pathname.includes("signup") && (
                    <Button
                      onClick={() => triggerAuth("Header")}
                      fontSize={{ base: "12px", md: "13px" }}
                      lineHeight={{ base: "13px", md: "16px" }}
                      mr={"0px"}
                      variant="solid"
                      colorScheme="green"
                      rounded={"22px"}
                      fontWeight={"500"}
                    >
                      Log in
                    </Button>
                  )}
              </>
            )}
          </SideButtons>
        )}
      </Box>
      {/* {
        (
          size.width <= 830 &&
          !router.pathname.includes('/inbox') &&
          !router.pathname.includes("signup")
          && !router.pathname.includes("add-listing")

          && !router.pathname.includes("active-wants")

        ) &&
        <Box mt="6px">
          {!isOnListingsPage &&
          <BigSearcher showOverlay />
          }
        </Box>
      } */}

      <Modal isOpen={openFeedback} isCentered
        closeOnOverlayClick={true}
        onClose={onFeedbackClose}
      >
        <ModalOverlay />
        <ModalContent>

          <ModalHeader>Feedback</ModalHeader>
          <ModalBody>
            <Textarea
              h={150}
              autoFocus={true}
              focusBorderColor={"#125F3F"}
              value={feedbackValue}
              onChange={handleFeedbackChange}
              placeholder="Help us improve Zonado by sharing your feedback here..."
              background={"white"}
            />
          </ModalBody>
          <ModalFooter display={"flex"} flexDir="column" gap="20px">
            <Flex justifyContent={"space-between"} w="100%">
              <Button
                variant={"outline"}
                borderColor="#B2B5B7"
                background={"white"}
                mr={3}
                onClick={() => onFeedbackClose()}
              >
                Close
              </Button>
              <Button
                variant="solid"
                colorScheme={"green"}
                onClick={() => {
                  feedbackMutation.mutate();
                  setFeedbackValue("");
                }}
                isLoading={sendFeedback == "loading"}
                loadingText="Submitting"
              >
                Send feedback
              </Button>
            </Flex>
            <Box>
              <Text>
                <b>Have a specific issue?</b> Call us toll-free via{" "}
                <a href="tel:+18447540007">
                  <u>(844) 754-0007</u>
                </a>{" "}
                or send us an email at{" "}
                <a href="mailto:hello@zonado.com" target={"_blank"}>
                  <u>hello@zonado.com</u>
                </a>
              </Text>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AuthModal
        onClose={onAuthBoxClose}
        isOpen={isAuthBoxOpen}
        messageToSend={undefined}
        returnToListing={undefined}
        createListing={undefined}
        goTo={undefined}
        creatingWant={undefined}
        type={undefined}
        onSubmit={undefined}
        values={undefined}
        listingToActivate={undefined}
      />
    </Header>
  );
};

export default NewHeader;
