import styled, { css } from "styled-components";
import * as palette from "../hoc/Layout/Theme";

export const PrimaryTag = styled.div`
  text-decoration: none;
  padding: 10px 30px;
  border: 1px solid #0079d3;
  border: ${({ main }) =>
    main ? "#1px solid #0079D3" : "1px solid #00000080"};
  box-sizing: border-box;
  border-radius: 5px;
  display: block;
  background-color: white;
  color: ${({ main }) => (main ? "#0079D3" : "#00000080")};
  transition: all 0.3s ease;
  margin-right: 20px;
  font-size: 17px;
  line-height: 17px;
  display: inline-block;
  width: auto;
  font-weight: medium;

  @media (max-width: 500px) {
    margin-bottom: 10px;
  }
`;
export const SectionDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #d8d8d8;
  margin-bottom: 5px;
  border-radius: 3px;
`;
export const ListingPanel = styled.div`
  width: 100%;
  padding: 0px 0px 15px;
  max-height: auto;
  display: flex;
  justify-content: center;
`;
export const ListingPadder = styled.div`
  padding: 15px 17px 15px;
  width: 100%;
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
`;

export const ImagePanel = styled.div`
  /* width: 40%; */
  /* display: ${(props) =>
    props.postType !== "Have" || !props.images ? "none" : "flex"}; */
  display: none;
  flex-direction: column;
  max-height: calc(100vh - 60px);

  @media (max-width: 1100px) {
    width: 100%;
  }
`;

export const Overall = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 96px;
  font-family: "Segoe UI", sans-serif;

  @media (max-width: 1023px) {
    margin-left: 0px;
  }

  // @media (max-width: 830px) {
  //   padding-top: 55px;
  // }
`;

export const MapHolder = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
`;
export const OverallinModal = styled.div`
  width: 100%;
  padding: 0%;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  scroll-behavior: smooth;

  @media (max-width: 1100px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;
export const Subject = styled.h1`
  font-size: 1.5rem;
  line-height: 2.25rem;
  font-weight: 500;
  font-family: "Jarkarta", sans-serif;
`;
export const HeaderLine = styled.h3`
  font-size: 1.2rem;
  line-height: 1.8rem;
  margin: 35px 0px 3px;
  font-weight: 400;
  font-family: "Jarkarta", sans-serif;
`;

export const LinkHeaderLine = styled.a`
  font-size: 1.2rem;
  line-height: 1.8rem;
  margin: 35px 0px 3px;
  font-weight: 500;
  color: #1a202c;
  text-decoration: underline;
  font-family: "Jarkarta", sans-serif;
`;

export const FancyText = styled.p`
  font-family: "Jarkarta", sans-serif;
  margin-bottom: 8px;
`;
export const StickyContact = styled.div`
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 2;
  padding: 16px;
  background: white;

  @media (min-width: 900px) {
    display: none;
  }
`;
export const ListingandContact = styled.div`
  display: flex;
  justify-content: center;
`;
export const LineBasis = css`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;
export const DataLine = styled.div`
  ${LineBasis}
  justify-content: space-between;

  @media (max-width: 500px) {
    display: block;
  }
`;

export const SmallLine = styled.div`
  margin-bottom: 8px;
  color: gray;
  font-weight: 500;
`;

export const SmallFancy = styled.p`
  font-family: "Jarkarta", sans-serif;
  font-size: 1rem;
`;

export const SmallFancyBold = styled.p`
  font-weight: 500;
  font-family: "Jarkarta", sans-serif;
  font-size: 1rem;
`;
export const TagLine = styled.div`
  ${LineBasis}
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const TopGrid = styled.div`
  display: grid;
  grid-row-gap: 8px;
  grid-template-columns: minmax(0, 1fr);
  margin-bottom: 16px;
  margin-top: 12px;
`;

export const ImgHolder = styled.div`
  /* max-height: 650px;
    max-width: 960px; */
  position: relative;
  margin-bottom: 10px;
  /* height: calc(100vh - 280px); */
  height: auto;
  @media (max-width: 1500px) {
    height: auto;
  }
`;

export const BlurAdder = styled.div`
  /* display: ${(props) => (props.user ? "none" : "block")}; */
  filter: ${(props) => (props.user ? "none" : "blur(10px)")};
`;

export const SignUpWall = styled.div`
  position: absolute;
  bottom: 0px;
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextBox = styled.div`
  background: white;
  z-index: 2;
  width: 500px;
  padding: 30px;
  border-radius: 5px;
  text-align: center;

  @media (max-width: 620px) {
    width: 300px;
    padding: 20px;
  }
`;

export const TopLine = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 620px) {
    justify-content: center;
  }
`;

export const Reasons = styled.ul`
  margin-left: 15px;
  margin-top: 15px;
  text-align: left;

  @media (max-width: 620px) {
    display: none;
  }
`;

export const Reason = styled.li`
  font-size: 12px;
  margin-top: 5px;
`;

export const ReasonText = styled.span`
  font-size: 16px;
`;

export const MainText = styled.p`
  margin-right: 10px;
  font-size: 20px;
  font-weight: 510;
`;

export const ImgBasis = css`
  width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Img = styled.img`
  ${ImgBasis}
`;

export const AlertHider = styled.div`
  display: ${(props) => (props.postType === "Have" ? "inherit" : "none")};
  flex-grow: 1;
  /* The left panel is hidden until 1100px. Listings without images dont have a left panel so the mobile alert needs to be visible instead */
  @media (max-width: 1100px) {
    display: none;
  }
`;

export const AlertforMobile = styled.div`
  /* Past 1100px, alert for mobile should never be visible  */
  /* display: ${(props) =>
    props.postType === "Have" && !props.images ? "inherit" : "none"}; */
  display: none;
  margin-top: 20px;

  /* Up until 1100 pixels, only show alert if its a have */
  @media (max-width: 1100px) {
    display: ${(props) => (props.postType === "Have" ? "inherit" : "none")};
  }
`;

export const AgentInfoforMobile = styled.div`
  display: none;
  margin-top: 20px;

  @media (max-width: 900px) {
    display: block;
  }
`;

export const AgentInfoforDesktop = styled.div`
  /* flex-grow: 1; */
  width: ${(props) => (props.size == "desktop" ? "350px" : "100%")};
  margin-top: ${(props) => (props.size == "desktop" ? "0px" : "2rem")};
  /* display: block; */
  margin-left: ${(props) => (props.size == "desktop" ? "16px" : "0px")};
  display: ${(props) => (props.size == "desktop" ? "block" : "none")};

  @media (max-width: 900px) {
    display: ${(props) => (props.size == "desktop" ? "none" : "block")};
  }
`;

export const ShareHolder = styled.div`
  display: block;
  width: 100%;
  & > * {
    width: 50%;
    display: inline-block;
    padding: 12px !important;
    margin-bottom: 4px;
  }
`;
export const PriceLine = styled.div`
  ${LineBasis}
  text-align: center;
  justify-content: center;
  border-bottom: 1px solid #0079d3;
  margin: 0% 10%;
  margin-top: 60px;
`;

export const ContactBorder = styled.div`
  border: 1px solid #dddddd;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  padding: 13px;
  width: 100%;
  max-width: 440px;
  margin: 7px auto 12px;
  display: ${(props) => (props.size == "mobile" ? "flex" : "block")};
  flex-direction: column;
  align-items: center;
`;
export const PosterCard = styled.div`
  border: 1px solid #d8d8d8;
  background-color: white;
  /* margin: 5% 8%; */
  padding: 2%;
  /* max-width: 400px; */
`;

export const PosterCardTop = styled.div`
  border: 1px solid #d8d8d8;
  background-color: white;
  margin: 20px 0%;
  padding: 3%;
  display: none;

  @media (max-width: 1100px) {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr;
    gap: 10px 30px;
  }

  @media (max-width: 600px) {
    display: grid;
    grid-template-rows: 0.5fr 1fr;
    grid-template-columns: none;
    gap: 15px 0px;
  }
`;
export const PosterCardSide = styled.div`
  border: 1px solid #d8d8d8;
  background-color: white;
  padding: 10px;
  /* display: flex; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 10px 30px;
  height: 100%;

  /* @media (max-width: 1100px) {
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-template-rows: 1fr;
        gap: 10px 30px;
    } */

  @media (max-width: 600px) {
    display: grid;
    grid-template-rows: 0.5fr 1fr;
    grid-template-columns: none;
    gap: 15px 0px;
  }
`;
/* .Message { grid-area: Message; }

` */

export const PosterInfo = styled.div`
  display: flex;
`;
export const ShareContainer = styled.div`
  margin: 5% 0%;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const GridInputContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  margin-bottom: 16px;

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    grid-row-gap: 16px;
  }
`;

export const GridChildInput = styled.div`
  width: 100%;
`;

export const PosterPhoto = styled.div`
  width: 82px;
  height: 82px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PosterImg = styled.img`
  ${ImgBasis}
  border-radius: 50%;
  object-fit: contain;
`;

export const PosterDetails = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
`;

export const ProfileLink = styled.a`
  color: #0079d3;
`;

export const FlexBox = styled.div`
  display: flex;
`;

export const PhoneNumber = styled.p`
  font-size: 20px;
`;

export const PhoneLine = styled.div`
  ${LineBasis}
  justify-content: center;
  margin-top: 10px;
`;

export const FakeButton = styled.div`
  margin-top: 15px;
  background-color: ${palette.primaryColor};
  background-color: ${({ secondary }) =>
    secondary ? "white" : `${palette.primaryColor}`};
  color: ${({ secondary }) =>
    secondary ? `${palette.primaryColor}` : "white"};
  border: 1px solid ${palette.primaryColor};
  padding: 0 20px;
  height: 40px;
  line-height: 38px;
  border-radius: 5px;
  transition: all 0.3s ease;
  font-weight: 400;
  font-size: 14px;
  text-align: center;

  &:hover {
    cursor: pointer;
    background-color: ${({ secondary }) =>
      secondary ? "white" : `${palette.primaryHover}`};
    color: ${({ secondary }) =>
      secondary ? `${palette.primaryHover}` : "white"};
    border: ${({ secondary }) =>
      secondary
        ? `1px solid ${palette.primaryHover}`
        : `1px solid ${palette.primaryColor}`};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #333;
  }
`;

export const Area = styled.p`
  white-space: pre-line;
  word-break: break-word;

  > a {
    color: #125f3f;
    font-weight: 500;
  }
`;

export const Attributes = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 10px 30px !important;

  @media (max-width: 520px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

export const BlueHighlight = styled.span`
  color: #125f3f;
`;

export const LatestHaveHeaderText = styled.h1`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  @media (max-width: 520px) {
    font-size: 1.2rem;
    line-height: 1.4;
  }
`;

export const LatestHaveBodyText = styled.p`
  margin-bottom: 2rem;
  line-height: 1.4;
  color: grey;

  @media (max-width: 520px) {
    font-size: 0.8rem;
  }
`;

export const LatestHaveInput = styled.input`
  margin-right: 5px;
  flex-grow: 1;
  width: 100%;
  flex: 1;
  padding-left: 10px;
  padding-right: 10px;
  background-color: rgba(196, 196, 196, 0.3);

  @media (max-width: 520px) {
    font-size: 0.8rem;
    line-height: 1.4;
  }
`;

export const NewListingBox = styled.div`
  padding: 0px 4rem 4rem 4rem;

  @media (max-width: 520px) {
    padding: 0px 2rem 2rem 2rem;
  }
`;

export const ListingContents = styled.div`
  width: 100%;
  @media (min-width: 900px) {
    width: calc(100% - 366px);
  }
`;

export const ImageButtons = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 45%;
  width: 100%;
  padding: 0px 10px;
`;

export const YoutubeVideo = styled.div`
  height: 500px;
  width: 100%;
  position: relative;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  background-image: url("https://d3e54v103j8qbb.cloudfront.net/static/youtube-placeholder.2b05e7d68d.svg");
  background-size: cover;
  background-position: 50% 50%;

  @media (max-width: 1600px) {
    height: 400px;
  }

  @media (max-width: 1400px) {
    height: 350px;
  }

  @media (max-width: 1100px) {
    height: 50vw;
  }
`;

export const ListingPhotoHolder = styled.div`
  width: 100%;
  height: auto;
`;

export const BoldedSpan = styled.span`
  font-weight: 600;
`;

export const MapToggleHolder = styled.div`
  display: none;
  position: fixed;
  bottom: 20px;
  justify-content: center;
  width: 100%;

  @media (max-width: 768px) {
    display: flex;
  }
`;

export const NavButton = styled.button`
  display: inline-flex;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.08);
  outline: rgb(34, 34, 34) none medium;
  margin: 0px;
  padding: 20px;
  color: rgb(34, 34, 34);
  background-color: rgba(255, 255, 255, 0.9);
  cursor: pointer;
  touch-action: manipulation;
  align-items: center;
  justify-content: center;
  background-clip: padding-box;
  box-shadow: transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px,
    rgba(0, 0, 0, 0.18) 0px 2px 4px;
  transition: transform 0.25s ease 0s, transform 0.25s ease 0s,
    transform 0.25s ease 0s;
  width: 32px;
  height: 32px;

  &:active {
    background-color: #ceedff;
  }

  &:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.6);
  }
`;

export const MultifamilyBanner = styled.div`
  width: 100%;
  background-color: #1a1a1a;
  padding: 37px 25px;
  margin-top: 25px;
  border-radius: 5px;
`;

export const AskAQuestionContainer = styled.div`
  margin: 30px auto;
  padding: 40px 25px;
  background-color: #F9F9F9;
  display: flex;
  justify-content: space-between;
  column-gap: 30px;
  align-items: center;

  @media screen and (max-width: 700px) {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 20px;
  }
` 

export const EditPanel = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  margin-bottom: 20px;


  `

export const EditButtons = styled.div`
  display: flex;
  grid-column-gap: 12px;
  alignItems: center;
  flex-wrap: wrap;
`

export const MobileRoundCTAs = styled.div`
display: grid;
justify-content: space-evenly;
align-items: center;
// column-gap: 20px;
width: 90%;
margin: 20px auto;
grid-template-columns: repeat(auto-fit, minmax(calc(25% - 1rem), 1fr));
    grid-auto-flow: row dense;
    gap: 1em;
`

export const MobileRoundCTAIcon = styled.div`
background: ${props => props.active ? "rgba(108, 185, 153, 0.1)" : "#E9EBEE" };
width: 48px;
height: 48px;
border-radius: 50%;
display: flex;
justify-content: center;
align-items: center;

@media screen and (max-width: 900px) {
  width: 35px;
  height: 35px;
}
`

export const RoundCTAsHolder = styled.div`
  font-size: 1rem;
  font-family: Inter, sans-serif;
  max-width: 440px;
  margin: 0 auto;
`

export const ContactsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(308px,1fr));
  grid-gap: 8px;
`

export const AboutTheAgentHolder = styled.div`
  /* border: 1px solid #ddd;
  border-radius: 4px; */
`

export const AboutAgentHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`

export const AboutAgentHeaderLine = styled.h3`
  /* font-size: 1.2rem; */
  /* line-height: 1.8rem; */
  text-transform: uppercase;
  font-family: "Jarkarta", sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0.085em;
  margin-bottom: 20px;
`

export const AboutAgentLeftBio = styled.div`
  margin-right: 30px;
  min-width: 183px;
`

export const AboutAgentRightBio = styled.div`
  width: 100%;
`

export const AboutAgentTitle = styled.h5`
  font-size: 22px;
  font-weight: 500;
  letter-spacing: -.1px;
  line-height: 33px;
  /* padding-bottom: 6px; */
  /* margin: 3px 0 6px; */
  font-family: 'Jarkara', sans-serif;
  /* border-bottom: 1px solid #d2d2d2; */
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 46px;

  @media (max-width: 700px) {
    justify-content: center;
  }
`

export const AboutAgentBioText = styled.div`
    font-size: 18px;
    line-height: 1.6;
    -webkit-column-count: 2;
    font-weight: 100;
    column-count: 2;
    -webkit-column-gap: 30px;
    column-gap: 30px;
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: pre-line;
    font-family: 'Jarkarta', sans-serif;

    @media (max-width: 800px) {
      column-count: 1;
    }
`

export const AboutAgentOverallFlex = styled.div` 
  display: flex;
  flex-direction: row;
  
  @media (max-width: 700px) {
    flex-direction: column;
    justify-content: center;
  }
`

export const AboutAgentBrokerageHolder = styled.div`
  width: 161px;
  margin-top: 16px;
  height: 45px;

  @media (max-width: 700px) {
    margin: 16px auto 0px;
  }
`