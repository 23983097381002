import { createUserWithEmailAndPassword, onAuthStateChanged, sendPasswordResetEmail, signInWithEmailAndPassword, signOut, updateEmail } from 'firebase/auth';
import { useRouter } from 'next/router';
import React, { createContext, useState, useEffect, useMemo } from 'react'
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import swal from 'sweetalert';
import { auth } from './firebase';
import * as Sentry from '@sentry/nextjs'

const FirebaseContext = createContext({});

export const FirebaseProvider = FirebaseContext.Provider
export const FirebaseConsumer = FirebaseContext.Consumer

export const withFirebaseHOC = Component => props => {
    const [user, setUser] = useState(null);
    const [check, setCheck] = useState(true);
    const router = useRouter()
    const [isLoading, setIsLoading] = useState(true)
    // const { user } = useUser();
    
    const lockCheck = () => {
        setCheck(false);
    }
    
    const unlockCheck = () => {
        setCheck(true);
    }
    const [loading, setLoading] = useState(false);
    const [profile, setProfile] = useState(null);


    useEffect(() => {
        // 
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setLoading(true)
            if (user) {
                setUser(user);


                setLoading(false)

            } else {
                setUser(false);
                setLoading(false)
            }
        });

        return () => unsubscribe();
    }, []);


    async function fetchUserRequest() {
        
        const profileResponse = await fetch(`/api/users/${user.uid}?type=auth`, {
        method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        });

        const data = await profileResponse?.json();
        const { currentUser } = data;        
        return currentUser;
    }
    
    useEffect(() => {
        (async function() {
            if (!!user && !!check) {
               const currentUser = await fetchUserRequest();
               if (!!currentUser) {
                   setProfile(currentUser)
               }
            }
            // setProfile(false)
        })()
    }, [user, check])

    useEffect(() => {
        setIsLoading(false)
    }, [profile])

  
    // const { data: currentUser, status, refetch } = useQuery(['myProfile', user], fetchUserRequest, {
    //     enabled: !!user && !!check,
    //     retry: false,
    //     refetchOnWindowFocus: false,
    //     onSuccess: (data)=> {
    //         // if (data) {
    //         //     // console.log('current user from onSuccess', data)
    //         //     setProfile(data); // only set profile when the request succeeds
    //         // }

    //     //     else {
    //     //         new Error("Couldn't get profile data")
    //     //     }
    //     // },
    //     // onError: (error) => {
    //     //     Sentry.captureException(error)
    //     }
    // });
    // useEffect(() => {
    //     (async function() {
    //         const currentUserData = await currentUser
    //         if (currentUserData) {
    //             // console.log('current user from effect', currentUserData)
    //             setProfile(currentUserData);
    //         }
    //     })()
    // }, [currentUser])


    const signin = (email, password) => {
        return signInWithEmailAndPassword(auth, email, password)
            .then((response) => {
                // setUser(response.user);
                return response.user;
            });
    };

    const signup = (email, password) => {
        return createUserWithEmailAndPassword(auth, email, password)
            .then((response) => {
                // setUser(response.user);
                return response.user;
            });
    };

    const signout = () => {
        return signOut(auth)
            .then(() => {
                router.push('/');
                // setUser(false);
            });
    };

    const _sendPasswordResetEmail = (email) => {
        return sendPasswordResetEmail(auth, email)
            .then(() => {
                return true;
            });
    };

    // const confirmPasswordReset = (password, code) => {
    //     const resetCode = code || getFromQueryString('oobCode');

    //     return firebase
    //         .auth()
    //         .confirmPasswordReset(resetCode, password)
    //         .then(() => {
    //             return true;
    //         });
    // };

    const changeEmail = (email, password, newEmail, handleEmailChangeStatus) => {
        return signInWithEmailAndPassword(auth, email, password)
            .then(function (userCredential) {
                updateEmail(userCredential.user, newEmail)
                // onClose()
                handleEmailChangeStatus('success')
            }).catch(err => {
                handleEmailChangeStatus('error')
                swal("Error", err, "error")
                Sentry.captureException(err)
            })
    };
    
    const authProps = {
        user: user && user,
        userId: user && user.uid,
        emailVer: user && user.emailVerified,
        profile: user && profile,
        // profileRefetch: refetch,
        // profile,
        // token: user.xa,
        loading,
        lockCheck: lockCheck,
        unlockCheck: unlockCheck,
        signin,
        signup,
        signout,
        changeEmail,
        sendPasswordResetEmail: _sendPasswordResetEmail,
        // confirmPasswordReset
    };

    if (isLoading) {
        return <p>Loading auth status....</p>
    }

    return (
        <Component {...authProps} {...props} />
    )
}
