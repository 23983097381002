import { Box, Flex, Text } from '@chakra-ui/react'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'

const BlogCard = ({ title, date, description, image, slug }) => {
    return (
        <Box width={{ base: "unset", md: "32%" }} mr={{ base: "20px", md: "0" }} className={"insightItem"}>
        <Link href={`/insights/${slug}`} passHref>
            <a>
                <Flex p={"18px"} bg={"white"} shadow={"lg"} width={{ base: "312px", md: "100%" }} height={"100%"} flexDir={"column"} justifyContent={"space-between"}>
                    <Box flexDir={"column"}>
                        <Image src={image} height={"206px"} width={"340px"}  />
                        <Text py={"15px"} borderBottomWidth={"1px"} color={"gray.500"} fontSize={"14px"}>{date}</Text>
                        <Text fontSize={{ base: "18px", lg: "22px" }} lineHeight={{ base: "24px", lg: "28px" }} fontWeight={"500"} my={"15px"}>{title}</Text>
                        <Text fontSize={{ base: "14px", lg: "16px" }} lineHeight={{ base: "20px", lg: "22px" }} color={"gray.400"}>{String(description).substr(0, 120) + "..."}</Text>
                    </Box>
                </Flex>
            </a>
        </Link>
        </Box>
    )
}

export { BlogCard }