import React, { useState, useEffect, useContext, createContext } from 'react';
import swal from 'sweetalert';
import { useQuery } from 'react-query';
import * as Sentry from '@sentry/nextjs'
import { useRouter } from "next/router";
import { auth } from '../../src/config/firebase';
import { createUserWithEmailAndPassword, onAuthStateChanged, sendPasswordResetEmail, signInWithEmailAndPassword, signOut, updateEmail } from 'firebase/auth';
import { clientError } from '../utilties';

const authContext = createContext(null);

export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
    return useContext(authContext);
};

function useProvideAuth() {
    const [user, setUser] = useState(null);
    const [check, setCheck] = useState(true);
    const router = useRouter()

    // const { user } = useUser();
    
    const lockCheck = () => {
        setCheck(false);
    }
    
    const unlockCheck = () => {
        setCheck(true);
    }
    const [loading, setLoading] = useState(false);
    const [profile, setProfile] = useState(null);


    useEffect(() => {
        // 
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setLoading(true)
            if (user) {
                setUser(user);


                setLoading(false)

            } else {
                setUser(false);
                setLoading(false)
            }
        });

        return () => unsubscribe();
    }, []);


    async function fetchUserRequest() {
        
        const profileResponse = await fetch(`/api/users/${user.uid}?type=auth`, {
        method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        });

        const data = await profileResponse?.json();
        const { currentUser } = data;        
        return currentUser;
    }
    

  
    const { data: currentUser, status, refetch } = useQuery(['myProfile', user], fetchUserRequest, {
        enabled: !!user && !!check,
        retry: false,
        refetchOnWindowFocus: false,
        onSuccess: (data)=> {
            // if (data) {
            //     // console.log('current user from onSuccess', data)
            //     setProfile(data); // only set profile when the request succeeds
            // }

        //     else {
        //         new Error("Couldn't get profile data")
        //     }
        // },
        // onError: (error) => {
        //     clientError(error)
        }
    });
    useEffect(() => {
        if (currentUser && status === 'success') {
            // console.log('current user from effect', currentUser)
            setProfile(currentUser);
        }
    }, [currentUser])


    const signin = (email, password) => {
        return signInWithEmailAndPassword(auth, email, password)
            .then((response) => {
                // setUser(response.user);
                return response.user;
            });
    };

    const signup = (email, password) => {
        return createUserWithEmailAndPassword(auth, email, password)
            .then((response) => {
                // setUser(response.user);
                return response.user;
            });
    };

    const signout = () => {
        return signOut(auth)
            .then(() => {
                setProfile(null)
                router.push('/');
                // setUser(false);
            });
    };

    const _sendPasswordResetEmail = (email) => {
        return sendPasswordResetEmail(auth, email)
            .then(() => {
                return true;
            });
    };

    // const confirmPasswordReset = (password, code) => {
    //     const resetCode = code || getFromQueryString('oobCode');

    //     return firebase
    //         .auth()
    //         .confirmPasswordReset(resetCode, password)
    //         .then(() => {
    //             return true;
    //         });
    // };

    const changeEmail = (email, password, newEmail, handleEmailChangeStatus) => {
        return signInWithEmailAndPassword(auth, email, password)
            .then(function (userCredential) {
                updateEmail(userCredential.user, newEmail)
                // onClose()
                handleEmailChangeStatus('success')
            }).catch(err => {
                handleEmailChangeStatus('error')
                swal("Error", err, "error")
                clientError(err)
            })
    };


    return {
        user: user && user,
        userId: user && user.uid,
        emailVer: user && user.emailVerified,
        profile: user && profile,
        profileRefetch: refetch,
        // profile,
        // token: user.xa,
        loading,
        lockCheck: lockCheck,
        unlockCheck: unlockCheck,
        signin,
        signup,
        signout,
        changeEmail,
        sendPasswordResetEmail: _sendPasswordResetEmail,
        // confirmPasswordReset
    };
}
