import {
  Alert,
  AlertIcon,
  Box,
  Button,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useToast,
  Image,
  Stack,
  Flex,
  Spinner,
  ButtonGroup,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Select,
  FormControl,
} from "@chakra-ui/react";
import { useRouter } from "next/router";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useAuth } from "../../../utils/auth/newAuth";
import {
  BottomExplainer,
  LeftGrid,
  LeftSide,
  Overall,
  RightSide,
  TermsLink,
  TopExplainer,
  TopHeader,
  TopTextGrid,
  TrustedText,
  BottomSpan,
  ImportantExplainer,
} from "./SignupModal2Styles";
import { FcGoogle } from "react-icons/fc";
import RecoverPassword from "../RecoverPassword/RecoverPassword";
import { Formik, Field, useFormikContext } from "formik";
import * as Yup from "yup";
import { MarginContainer } from "../../../styles/SignupStyles";
import {
  industryRoleOptions,
  titleOptions,
} from "../../Listing/data";
import Link from "next/link";
import { useMutation, useQuery } from "react-query";
import { getToken, auth } from "../../../src/config/firebase";
import { createUserWithEmailAndPassword, deleteUser, GoogleAuthProvider, isSignInWithEmailLink, sendSignInLinkToEmail, signInWithEmailAndPassword, signInWithEmailLink, signInWithPopup, updateProfile } from "firebase/auth";
import { FaHeart } from "react-icons/fa";
import { SiMicrosoftoutlook, SiGmail } from "react-icons/si";

import { internalCheck, MyFormLabel } from "../../../utils/clientUtilities";
import * as Sentry from "@sentry/nextjs";
import { SmallFancy } from "../../../styles/ListingStyles";
import { ChevronLeftIcon, CheckIcon, LinkIcon } from "@chakra-ui/icons";
import { Value, formatPhoneNumberIntl, isPossiblePhoneNumber } from "react-phone-number-input";
import 'react-phone-number-input/style.css'
import { clientAnalytics, clientError, env_check, isProduction, PhoneNumberFormat } from "../../../utils/utilties";
import dynamic from "next/dynamic";
import CreatableSelect from 'react-select/creatable';
const PhoneNumberInput = dynamic(() => import("../../PhoneNumberInput/PhoneNumberInput"))

const SignUpComponent = props => {
  const { listingToActivate, listingType, size, header, signUpMethod, authModalEmail, authModalEmailType, authModalPhoneType } = props;

  const { userId, profileRefetch, lockCheck, unlockCheck } = useAuth();
  const router = useRouter();
  const toast = useToast();
  const [signupStatus, setSignupStatus] = useState("idle");
  const [fetchingUser, setFetchingUser] = useState(false);
  const [googleUserValue, setGoogleUserValue] = useState(null);
  const [fetchingUid, setFetchingUid] = useState(false);
  const [emailType, setEmailType] = useState<"existing" | "new" | "">(authModalEmailType ||  "");
  const [phoneType, setPhoneType] = useState<"existing" | "new" | "">(authModalPhoneType ||  "");
  const [existingAccountEmail, setExistingAccountEmail] = useState("")
  const [method, setMethod] = useState(signUpMethod || "");
  const [emailValue, setEmailValue] = useState(authModalEmail || "");
  const [passwordValue, setPasswordValue] = useState("");
  const [loginStatus, setLoginStatus] = useState("idle");
  const [completeStatus, setCompleteStatus] = useState("idle");
  const [errorMessage, setErrorMessage] = useState(null);
  const [show, setShow] = useState(false);
  const [phoneData, setPhoneData] = useState('' as Value);
  const [magicLinkStatus, setMagicLinkStatus] = useState("idle");
  const [magicLinkEmailConfStatus, setMagicLinkEmailConfStatus] =
    useState("idle");
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [suggestedCorrection, setSuggestedCorrection] = useState(null);
  const initialFocusRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const [validateStatus, setValidateStatus] = useState("idle");
  const [validate, setValidate] = useState(false);
  const [checkCode, setCheckCode] = useState(false);
  const [codeIncorrect, setCodeIncorrect] = useState(false);
  const [resendCode, setResendCode] = useState(false);
  const [verifyCode, setVerifyCode] = useState(undefined);
  const [userValues, setUserValues] = useState({});
  const [validityProblem, setValidityProblem] = useState(null);
  const [googleAuth, setGoogleAuth] = useState(false);
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [internal, setInternal] = useState(false);
  const [brokerageFinderText, setBrokerageFinderText] = useState(null);
  const [googleConnecting, setGoogleConnecting] = useState(false);

  useEffect(() => {
    if (props.email) {
      setMethod("email");
      setEmailValue(props.email);
      setFetchingUser(true);
    }}, [props.email]);

  useEffect(()=> {
    if (props.GoogleAuth == true ) {
      signinWithGoogle();
    }
  }, [props.GoogleAuth])

  // const [listingToActivate, setListingToActivate] = useState(null)

  // Will try to use previously entered email, defaults to an empty string
  // const [email, setEmail] = useState(
  //     window.localStorage.getItem("emailForSignIn") || ""
  // );
  const [errorResponse, setErrorResponse] = useState("");

  // When this component renders
  useEffect(() => {

    // Get the saved email
    const saved_email = window.localStorage.getItem("emailForSignIn");

    // Verify the user went through an email link and the saved email is not null
    if (isSignInWithEmailLink(auth, window.location.href)) {
      if (!!saved_email) {
        // Sign the user in
        setMethod("magicSignin");
        signInWithEmailLink(auth, saved_email, window.location.href)
          .then(() => {
            clientAnalytics.track("Signed in with magic link", {
              device: "The same",
            });
          })
          .catch((e) => {
            console.error(e);
            clientError(e);
            setMethod("");
            router.replace("/signup", undefined, { shallow: true });
            toast({
              title: "Error",
              description: `Oops! An error occured while signing you in magically. Please try again.`,
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          });
      }

      // User using new device
      else {
        setMethod("magicSigninConfirmEmail");
      }
    }
  }, []);

  const clearError = () => {
    if (errorResponse != "") {
      setErrorResponse("");
    }
  };

  /**
   * The React.ChangeEvent<HTMLInputElement> is from typescript and just shows
   * what value is getting passed in, so you dont have to remember
   * in this case its a "ChangeEvent" coming from "onChange"
   */
  const updateEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearError();
    setEmailValue(e.target.value);
  };

  const sendMagicLink = async () => {
    // If the user is re-entering their email address but already has a code
    // const saved_email = window.localStorage.getItem("emailForSignIn");

    if (
      isSignInWithEmailLink(auth, window.location.href) &&
      !!emailValue
    ) {
      // Sign the user in

      setMagicLinkEmailConfStatus("loading");
        signInWithEmailLink(auth, emailValue, window.location.href)
        .then(() => {
          setMagicLinkEmailConfStatus("success");
          clientAnalytics.track("Signed in with magic link", {
            device: "Not the same",
          });
        })
        .catch((err) => {
          switch (err.code) {
            default:
              setErrorResponse("An unknown error has occured");
          }
        });
    } else {
      setMagicLinkStatus("loading");
        sendSignInLinkToEmail(auth, emailValue, {
          // url: "https://feature-zonado.vercel.app/signup",
          url:
            typeof window !== "undefined"
              ? "https://" + window?.location?.hostname + "/signup"
              : "https://zonado.com/signup",
          handleCodeInApp: true,
        })
        .then(() => {
          // Save the users email to verify it after they access their email
          window.localStorage.setItem("emailForSignIn", emailValue);
          setMagicLinkStatus("success");
          clientAnalytics.track("Sent magic sign in link", {
            email: emailValue,
          });
        })
        .catch((err) => {
          switch (err.code) {
            default:
              setErrorResponse("An unknown error has occured");
              setMagicLinkStatus("idle");
          }
        });
    }
  };
  const handleChange = (event) => setEmailValue(event.target.value);
  const handleClick = () => setShow(!show);

  useEffect(() => {
    const internalChecker = internalCheck(window?.location?.host);
    setInternal(internalChecker);
    // setInternal(true);
  }, []);

  async function findUidRequest() {
    const userResponse = await fetch(
      `/api/users?checkUidExists=true&uid=${googleUserValue?.uid}`,
      {
        method: "GET",
      }
    );
    const receivedUser = await userResponse.json();
    // const receivedUser = await response.json();
    const { userExists } = receivedUser;
    return userExists;
  }
  async function findUserRequest() {
    setEmailInvalid(false);
    setSuggestedCorrection(null);
    const response = await fetch(`/api/users?checkEmailExists=true`, {
      method: "GET",
      headers: {
        email: emailValue,
      },
    });
    const receivedUser = await response.json();
    return receivedUser;
  }

  const { data, status, error } = useQuery("check exists", findUserRequest, {
    refetchOnWindowFocus: false,
    enabled: !!fetchingUser,
    onSuccess: (data) => {
      if (data) {
        if (data["userExists"]) {
          setEmailType("existing");
        } else {
          // If result was valid:
          const {
            validity: {
              response: { result, status, suggested_correction },
            },
          } = data;
          // Request didn't go through or it did and we know that email is valid
          if (
            status != "success" ||
            result == "valid" ||
            result == "unknown" ||
            result == "catchall"
          ) {
            setEmailType("new");
            clientAnalytics.track("Valid email, signup form displayed");
          }

          // Request did go through and result isn't valid
          else {
            setEmailInvalid(true);
            suggested_correction?.length > 0
              ? setSuggestedCorrection(suggested_correction)
              : null;
            clientAnalytics.track("Email flagged as invalid", {
              email: emailValue,
            });
          }
        }
        setFetchingUser(false);
      } else {
        setFetchingUser(false);
        throw "Error fetching account";
      }
    },
    onError: (error) => {
      clientError(error);
      toast({
        title: "Error",
        description: `An error occured trying to get your account. Please try again later.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const {} = useQuery("check_uid_exists_signupmodal", findUidRequest, {
    refetchOnWindowFocus: false,
    enabled: !!fetchingUid && !!googleUserValue,
    onSuccess: (data) => {
      if (data) {
        setFetchingUid(false);
        clientAnalytics.track('Signing in with google')

        clientAnalytics.identify(googleUserValue?.uid);
        // Close the modal or push to listings
        loginEnder(googleUserValue);
      } else {
        setMethod("social");
        clientAnalytics.track('Signing up with google')
        setEmailType("new")
        setPhoneType("new")
        setFetchingUid(false);
        const { displayName, uid, email, photoURL } = googleUserValue;
        clientAnalytics.track("User created", {
          type: "Google",
          uid: data["uid"],
          email: email,
        });
        clientAnalytics.identify(uid, {
          name: displayName,
          avatar: photoURL,
          phone: phoneData,
          email: email,
        });

        // setMethod("social");
        // setEmailType("new");
        // props?.setLockClose ? props?.setLockClose(true) : null;
        // // googleUserValue.sendEmailVerification();

        // // props?.setLockClose ? props.setLockClose(true) : null;
        // lockCheck();
        // googleUserValue.sendEmailVerification();

        const userData = {
          firstName: displayName.split(" ")[0],
          lastName: displayName.split(" ")[1],
          fullName: displayName,
          email,
          uid,
          photoURL,
        };
        mutation.mutate(userData);
      }
    },
    onError: (error) => {
      setFetchingUid(false);
      clientError(error);
      toast({
        title: "Error",
        description: `An error occured trying to get your account. Please try again later.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
    onSettled: () => {
      setFetchingUid(false);
      setGoogleUserValue(null);
    },
  });

  const loginEnder = (user) => {
    if (listingToActivate) {
      activateMutation.mutate({
        status: "Available",
        uid: user["uid"],
      });
    } else if (
      props.noRedirect ||
      router.pathname.includes("/listings") ||
      router.pathname.includes("/agents") ||
      router.pathname.includes("/dashboard") ||
      router.pathname.includes("/add-listing") ||
      router.pathname.includes("/inbox") ||
      router.pathname.includes("/active-wants") ||
      router.pathname.includes("/my-wants") ||
      router.pathname.includes("/my-listings") ||
      router.pathname === "/"
    ) {
      props.onClose ? props.onClose() : null;
      setLoginStatus("success");
    } else {
      router.replace("/listings");
      props.onClose ? props.onClose() : null;
    }
  };

  const updateUserRequest = async (passedData) => {
    // setDeleteProcessLoading(true);
    const response = await fetch(`/api/users/${userId}/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: await getToken(),
        userId: userId,
      },
      body: JSON.stringify({ updatedData: passedData }),
    });

    const data = await response.json();
    const { user } = data;

    return user;
  };

  const updateMutation = useMutation(updateUserRequest, {
    onError: (error) => {
      toast({
        title: "Error",
        description: `An error occured. Please try again later.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      clientError(error);
      router.pathname.includes("signup") || router.pathname.includes("login")
        ? router.replace("/listings")
        : props.onClose
        ? props.onClose()
        : null;
      setCompleteStatus("error");
      setErrorMessage(error["message"]);
    },

    onSuccess: (data) => {
      if (data) {
        clientAnalytics.identify(userId, {
          industryRole: data["industryRole"],
          phone: phoneData,
        });

        if (listingToActivate) {
          activateMutation.mutate({
            status: "Available",
            uid: userId,
          });
        } else {
          router.pathname.includes("signup") ||
          router.pathname.includes("login")
            ? router.replace("/listings")
            : props.onClose
            ? props.onClose()
            : null;
          profileRefetch();
        }

        unlockCheck();
      } else throw "error";
    },
  });

  const logInUser = async () => {
    setLoginStatus("loading");
    try {
      const response = await signInWithEmailAndPassword(auth, emailValue, passwordValue);
      if (response.user) {
        clientAnalytics.track('Signing in with email')
        clientAnalytics.identify(response.user["uid"], {
          email: emailValue,
        });

        loginEnder(response.user);
      }
    } catch (error) {
      setLoginStatus("error");
      setErrorMessage(error.message);
    }
  };
  async function createUserRequest(userData) {
    let IPAddress;
    try {
      const IPresponse = await fetch("https://jsonip.com", { mode: "cors" });
      const IPobj = await IPresponse.json();
      IPAddress = IPobj ? IPobj["ip"] : null;
    } catch (e) {
      clientError(e);
    }

    const response = await fetch("/api/users/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: await getToken(),
        ip: IPAddress ? IPAddress : null,
      },
      body: JSON.stringify({ user: userData }),
    });

    const data = await response.json();
    const { user } = data;

    return user;
  }

  const listingActivator = async (passedData) => {
    const response = await fetch(
      listingType && listingType == "want"
        ? `/api/wants/${listingToActivate}/update`
        : `/api/listings/${listingToActivate}/update`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: await getToken(),
        },
        body: JSON.stringify({ updatedData: passedData }),
      }
    );

    const data = await response.json();
    const { listing } = data;

    return listing;
  };

  const activateMutation = useMutation(listingActivator, {
    onError: (error) => {
      toast({
        title: "Error",
        description: `An error occured. Please try submitting your listing again.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      clientError(error);
      setSignupStatus("error");
      setErrorMessage(error["message"]);
    },
    onSuccess: (data) => {
      if (data) {
        router.push(
          listingType && listingType == "want" ? "/my-wants" : "/my-listings"
        );

        // If this a want, and they're not on my wants push
        if (listingType && listingType == "want") {
          router.pathname.includes("my-wants")
            ? router.reload()
            : router.push("my-wants");
        } else {
          router.push("/my-listings");
        }
        toast({
          title:
            listingType && listingType == "want"
              ? "Want added"
              : "Listing added",
          description: "We've secured your account for you.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        profileRefetch();
      } else throw "error";
    },
  });

  const mutation = useMutation(createUserRequest, {
    onError: (error) => {
      toast({
        title: "Error",
        description: `An error occured. Please try again later.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      clientError(error);
      setSignupStatus("error");
      setErrorMessage(error["message"]);
    },

    onSuccess: async (data) => {
      if (data) {
        // Do the following if the method used was email
        if (method == "email" || method == "verify") {


          clientAnalytics.track("User created", {
            type: "Email",
            uid: data["uid"],
            industryRole: data["industryRole"],
          });
          clientAnalytics.identify(data["uid"], {
            name: `${data["firstName"]} ${data["lastName"]}`,
            email: emailValue,
            phone: phoneData,
            industryRole: data["industryRole"],
            brokerage: data["brokerage"],
          });

          if (listingToActivate) {
            // Listing update api call to change status and uid
            activateMutation.mutate({
              status: "Available",
              uid: userId,
            });
          } else {
            toast({
              title: "Account created",
              description: "We've secured your account for you.",
              status: "success",
              duration: 5000,
              isClosable: true,
              variant: "subtle",
            });
            if (router.pathname.includes("signup")) {
              router.replace("/listings");
            } else {
              props.onClose ? props.onClose() : null;
            }
            // setSignupStatus('success')
            // setValidateStatus('success')
          }
        }

        if (method == 'social') { 
          if (router.pathname.includes("signup")) {
            router.replace("/listings");
          } else {
            props.onClose ? props.onClose() : null;
          }
        }

        profileRefetch();
      }

      // User record didn't go through
      else {
        // Throw error
        // Delete user record from firebase
        props.onClose ? props.onClose() : null;
        await deleteUser(auth.currentUser);

        throw "Error creating user after Google auth";

        // Set method back to blank
        setMethod("");
      }
    },
  });

  const Tester = () => {
    const { errors, values, setFieldValue, isValid } = useFormikContext();

    useEffect(() => {}, [isValid]);

    return null;
  };
  const signUpUser = async (values) => {
    console.log('starting sign up user function')
    if (signupStatus !== "loading") {
      setSignupStatus("loading");
    }

    Object.keys(values).map((key) => {
      if (values[key] === "" || values[key] === null) {
        delete values[key];
      }
    });

    // Removing phone from object since formatting is off
    const { password, phone, ...rest } = values;

    // Temp - putting before firebase create user

    try {
      const response = await createUserWithEmailAndPassword(auth, emailValue, password);
      // If user is on the sign up page when signing up
      if (response.user) {
        const { uid } = response.user;
        // Don't send password up to the server
        const firstName = values["firstName"];
        const lastName = values["lastName"];
        const userData = {
          email: emailValue,
          firstName,
          lastName,
          fullName: firstName + " " + lastName,
          uid,
          ...rest,
        };
        isPossiblePhoneNumber(phoneData) ? (userData["phone"] = phoneData) : null;

        await updateProfile(auth.currentUser, {
          displayName: `${values["firstName"]} ${values["lastName"]}`,
        })
        mutation.mutate(userData);
      }
    } catch (error) {
      setSignupStatus("error");
      setErrorMessage(error.message);
      clientError(error);
    }
  };

  const emailButtons = (
    <ButtonGroup
      display={"flex"}
      tabIndex={-1}
      justifyContent={"space-evenly"}
      mb="12px"
      mt="6px"
      w={"100%"}
    >
      <a
        href="https://mail.google.com/mail/u/0/"
        rel="noopener noreferrer"
        target="_blank"
      >
        <Button
          variant="link"
          size="sm"
          colorScheme="red"
          leftIcon={<SiGmail />}
        >
          Open Gmail
        </Button>
      </a>

      <a
        href="https://outlook.live.com/mail/0/inbox"
        rel="noopener noreferrer"
        target="_blank"
      >
        <Button
          leftIcon={<SiMicrosoftoutlook />}
          variant="link"
          colorScheme="blue"
          size="sm"
        >
          Open Outlook
        </Button>
      </a>
    </ButtonGroup>
  );

  const sendCodeSuccess = () => {

    if (resendCode) {
      clientAnalytics.track("Phone verification code re-sent");

      toast({
        title: "Verification code resent",
        description:
          "Please be sure to check that the number entered is correct",
        status: "success",
        position: "top",
        variant: "subtle",
        duration: 5000,
      });
      setResendCode(false);
    } else {
      clientAnalytics.track("Phone verification code sent");
      toast({
        title: `Verification code sent`,
        description: "Please enter the code that was sent to you via SMS",
        status: "success",
        position: "top",
        variant: "subtle",
        duration: 5000,
      });

      // If method is social, set up a flag
      if (method == "social") {
        setGoogleAuth(true);
      }

      setMethod("verify");
    }

    setValidateStatus("idle");
  };

  const validateInfoRequest = async () => {
    setValidateStatus("loading");
    // MAKE LISTING NUMBER DYNAMIC
    const response = await fetch(`/api/verifyPhone`, {
      method: "GET",
      headers: {
        phone: phoneData,
      },
    });
    const data = await response.json();
    // setValidate(false);
    const { result } = data;
    return result;
  };
  const {
    data: verifData,
    status: verifStatus,
    error: verifError,
    refetch: verifFetch,
  } = useQuery("verifyPhoneFunc", validateInfoRequest, {
    refetchOnWindowFocus: false,
    enabled: false,

    onError: (e) => {
      console.error(e);
      toast({
        title: "Error",
        description: `An error occured trying to send you a verification code. Please try again later.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  async function findBrokerageRequest() {
    const response = await fetch(
      `/api/brokerages?phrase=${brokerageFinderText}`,
      {
        method: "GET",
      }
    );
    const receivedData = await response.json();
    const { brokerageOptions } = receivedData;
    return brokerageOptions;
  }

  const { data: foundBrokerages, error: userError, status: brokerageStatus } = useQuery(
    ["brokerageFinder", brokerageFinderText],
    findBrokerageRequest,
    {
      refetchOnWindowFocus: false,
      enabled: !!brokerageFinderText,
      onSuccess: (data) => {
        if (!data) {
          throw "error";
          // setStatus("error");
        } 
      },
      onError: (error) => {
        clientError(error);
      },
    }
  );
  async function findUserByPhone()  {
    // setMessageStatus("loading");
    setValidateStatus("loading")

    try {
        const response = await fetch(`/api/users?checkPhoneExists=true`, {
            method: "GET",
            headers: { phone: phoneData },
        });
        const data = await response.json();
        if (data['userExists']) {
            // Tell user to log in instead
            setValidateStatus("")
            setExistingAccountEmail(data.accountEmail)
            setPhoneType("existing")
        }
        else {
          phoneType === 'existing' && setPhoneType(null)
          // verifFetch()
          //  If user is using Google, update the existing user
           if (method == 'social') {
            updateMutation.mutate({ ...userValues, phone: phoneData});
            setGoogleAuth(false);
          }

          // Else create the user
          else {
            signUpUser(userValues);
          }
        }
    } catch (error) {
      toast({
        title: "Error",
        description: `An unexpected error occured. Please try again later.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
}

  useEffect(() => {
    if (verifStatus == "success" && validateStatus == "loading") {
      if (verifData) {
        const { status } = verifData;
        if (status == "pending") {
          sendCodeSuccess();
        } else if (status == 400) {
          clientAnalytics.track("Invalid phone number entered", {
            error: "Invalid number",
          });

          setValidateStatus("phoneInvalid");
          setValidityProblem("Please enter a valid phone number.");
          // toast({
          //     title: "Verification code resent",
          //     description: "Please be sure to check that the number entered is correct",
          //     status: "success",
          //     variant: "subtle",
          //     duration: 5000,
          // })
        } else if (status == 403) {
          clientAnalytics.track("Invalid phone number entered", {
            error: "Landline number",
          });
          setValidateStatus("phoneInvalid");
          setValidityProblem(
            "Landline numbers don't support SMS. Please enter a cell phone number."
          );
        } else if (status == 429) {
          clientAnalytics.track("User rate limited for phone verification");

          toast({
            title: "Error",
            description: `You have reached the maximum number of attempts`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else {
          clientAnalytics.track("Problem sending verification code");
          setValidateStatus("idle");

          throw "Couldn't send verification code";
        }
      } else {
        toast({
          title: "Error",
          description: `An error occured trying to send you a verification code. Please try again later.`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setValidateStatus("idle");
      }
    }

    if (verifStatus == "error") {
      clientError(verifError);
      toast({
        title: "Error",
        description: `An error occured trying to send you a verification code. Please try again later.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setValidateStatus("idle");
    }
  }, [verifData]);
  const checkPhoneVerification = async () => {
    setValidateStatus("loading");
    clientAnalytics.track("Phone verifiction code entered");
    // MAKE LISTING NUMBER DYNAMIC
    const response = await fetch(`/api/verifyPhoneCode`, {
      method: "GET",
      headers: {
        phone: phoneData,
        code: verifyCode,
      },
    });
    const data = await response.json();
    setCheckCode(false);
    const { result } = data;

    return result;
  };
  const {} = useQuery("checkPhVerification", checkPhoneVerification, {
    refetchOnWindowFocus: false,
    enabled: !!checkCode,
    onSuccess: (data) => {
      if (data) {
        //    If successful, create user
        const { status, valid } = data;
        if (status == "approved" && valid) {
          clientAnalytics.track(
            "Phone verifiction code valid, creating user"
          );
          setValidateStatus("idle");

          // If user is using Google, update the existing user
          if (googleAuth) {
            updateMutation.mutate({ ...userValues, phoneVerified: true });
            setGoogleAuth(false);
          }

          // Else create the user
          else {
            signUpUser({ ...userValues, phoneVerified: true });
          }
        } else {
          clientAnalytics.track("Phone verifiction code invalid");
          toast({
            title: "Code incorrect",
            description: "Please try entering the code again",
            position: "top",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          setCodeIncorrect(true);
          setValidateStatus("idle");
        }

        // Else, ask them to try again
      } else {
        setValidateStatus("idle");
        throw "Error validating information";
      }
    },
    onError: (e) => {
      setValidateStatus("idle");
      console.error(e);
      toast({
        title: "Error",
        description: `An error occured trying to set up your account. Please try again later.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  useEffect(() => {
    if (resendCode == true) {
      // setValidate(true);
      verifFetch();
    }
  }, [resendCode]);

  const signinWithGoogle = async () => {
    clientAnalytics.track("Google auth flow started");
    return signInWithPopup(auth, new GoogleAuthProvider())
      .then(async (response) => {
        setGoogleUserValue(response.user);
        setFetchingUid(true);
      })
      .catch((error) => {
        if (error["code"] != "auth/popup-closed-by-user") {
          clientError(error);

          toast({
            title: "Error",
            description: `An error occured trying to connect to Google. Please try using your email instead or try again later.`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      })
      .finally(() => {
        setFetchingUser(false)
      });
  };

  const EmailSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("Please enter your first name")
      .min(2, "First name must be at least 2 characters")
      .max(50, "First name must be less than 50 characters"),

    lastName: Yup.string()
      .required("Please enter your last name")
      .min(2, "Last name must be at least 2 characters")
      .max(50, "Last name must be less than 50 characters"),

    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),

    industryRole: Yup.string().required("Industry role missing"),

    brokerage: Yup.string().min(
      5,
      "Brokerage name must be longer than 5 characters"
    ),
  });

  const SocialCompleteSchema = Yup.object().shape({
    industryRole: Yup.string().required("Industry role missing"),

    brokerage: Yup.string().min(
      5,
      "Brokerage name must be longer than 5 characters"
    ),
  });

  const JustEmailSchema = Yup.object().shape({
    email: Yup.string().required("Email is required"),
  });

  const phoneIsInvalid = useMemo(() => (
    !phoneData.length || !isPossiblePhoneNumber(phoneData)
  ), [phoneData])

  return (
    <Overall size={size}>
      <LeftSide>
        <LeftGrid>
          <TopTextGrid>
            {(method === "email" ||
              method === "forgot" ||
              method == "verify") && (
              <Flex justifyContent={"flex-start"}>
                <Button
                  mr="5px"
                  onClick={() => {
                    setValidateStatus("idle");
                    setLoginStatus("");
                    setMagicLinkStatus("idle");
                    setMagicLinkEmailConfStatus("idle");

                    if (phoneType === 'existing') {
                      setPhoneType(null) // removes the "phone exists " text
                    }

                    if (method == "verify") {
                      if (googleAuth) {
                        setMethod("social");
                      }
                      else {
                        setMethod("email");

                      }
                      setEmailType("new");
                    } 

                    else {
                      setMethod("");
                      setEmailType("");
                    }
                  }}
                  colorScheme="green"
                  color="green.500"
                  variant="ghost"
                  aria-label="Go back"
                  leftIcon={<ChevronLeftIcon />}
                >
                  Go back
                </Button>
              </Flex>
            )}
            <TopHeader>
              {method === ""
                ? header
                  ? header
                  : "Log in or sign up in seconds"
                : ""}

              {
                method === "email" && authModalEmailType === 'existing' ?
                "Looks like you already have an account" : 
                method === "email" &&
                "Continue with email"
              }

              {method === "forgot" && "Recover your password"}

              {method === "social" && emailType === "new" && "Confirm phone number to continue"}

              {method == "magicSignin" && "Magically signing you in..."}

              {method == "magicSigninConfirmEmail" &&
                "Confirm your email for magic sign in"}

              {method == "verify" && `Verify your phone number`}
            </TopHeader>

            {/* {(emailType === '' || emailType === 'new') && */}
            <TopExplainer>
              {method === "" &&
                "Use Google or your email to continue. Yes, it's free."}

              {method === "email" &&
                emailType === "" &&
                "Enter your email and hit continue"}

              {method === "forgot" && "Enter your email to recover password"}

              {method === "social" && "This step is important to ensure that you are a real person."}

              {method == "email" && emailType !== "" && `Using ${emailValue}`}

              {/* {emailType === 'new' && `Using ${emailValue}`} */}

              {method === "verify" && (
                <p>
                  Please enter the code we just sent to{" "}
                  <strong>
                    <PhoneNumberFormat phone={phoneData} />
                  </strong>
                </p>
              )}
            </TopExplainer>
            {/* // } */}
          </TopTextGrid>

          {method === "" && (
            <Button
              onClick={() => signinWithGoogle()}
              backgroundColor="white"
              color="gray.900"
              variant="outline"
              isLoading={fetchingUid}
              loadingText={"Connecting with Google"}
              fontWeight="medium"
              leftIcon={<FcGoogle />}
              _hover={{ bg: "gray.100" }}
              _active={{
                bg: "gray.100",
                transform: "scale(0.95)",
              }}
            >
              Continue with Google
            </Button>
          )}

          {((method == "email" && emailType === "") ||
            method == "magicSigninConfirmEmail") && (
            <>
              <Formik
                initialValues={{
                  email: emailValue,
                }}
                validationSchema={JustEmailSchema}
                onSubmit={async (values, { setSubmitting }) => {
                  // If the user is here to confirm their email after getting the code
                  if (method == "magicSigninConfirmEmail") {
                    sendMagicLink();
                  }

                  // If the user is just continuing with email
                  else {
                    setFetchingUser(true);
                  }
                }}
              >
                {({
                  isSubmitting,
                  isValid,
                  handleChange,
                  values,
                  handleSubmit,
                  errors,
                  touched,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Tester />

                    <Popover
                      initialFocusRef={
                        suggestedCorrection ? initialFocusRef : emailRef
                      }
                      placement="bottom"
                      closeOnBlur={false}
                      isOpen={emailInvalid}
                    >
                      <PopoverTrigger>
                        <MarginContainer>
                          <MyFormLabel>Email</MyFormLabel>
                          <Field name="email">
                            {({ field, form }) => (
                              <Input
                                {...form}
                                {...field}
                                autoFocus
                                _focus={{
                                  borderColor: "#125f3f",
                                  boxShadow: "0 0 0 1px #125f3f",
                                }}
                                type="email"
                                id="email"
                                name="email" 
                                autoComplete="email"
                                ref={emailRef}
                                isRequired
                                value={emailValue}
                                onChange={(e) => {
                                  handleChange("email")(e.target.value);
                                  setEmailValue(e.target.value);
                                  emailInvalid ? setEmailInvalid(false) : null;
                                }}
                                placeholder="julie@example.com"
                                size={"md"}
                              />
                            )}
                          </Field>
                        </MarginContainer>
                      </PopoverTrigger>
                      <PopoverContent zIndex={4} borderColor="blue.800">
                        <PopoverHeader pt={4} fontWeight="bold" border="0">
                          Hmm, that email doesn't look right
                        </PopoverHeader>
                        <PopoverArrow />
                        <PopoverCloseButton
                          onClick={() => {
                            setEmailInvalid(false);
                          }}
                        />
                        <PopoverBody>
                          {suggestedCorrection ? (
                            <p>
                              Did you mean{" "}
                              <strong>{suggestedCorrection}</strong> instead?
                            </p>
                          ) : (
                            `Please try entering your email again`
                          )}
                        </PopoverBody>
                        <PopoverFooter
                          border="0"
                          d="flex"
                          alignItems="center"
                          justifyContent="flex-end"
                          pb={4}
                        >
                          {suggestedCorrection && (
                            <ButtonGroup
                              size="sm"
                              display="flex"
                              justifyContent="flex-end"
                            >
                              <Button
                                variant="solid"
                                colorScheme="green"
                                leftIcon={<CheckIcon />}
                                onClick={() => {
                                  setEmailValue(suggestedCorrection);
                                  setEmailInvalid(false);
                                }}
                                ref={initialFocusRef}
                              >
                                Yes, that's right
                              </Button>
                            </ButtonGroup>
                          )}
                        </PopoverFooter>
                      </PopoverContent>
                    </Popover>

                    {Object.keys(errors).map((key) => {
                      if (touched[key]) {
                        return (
                          <Box>
                            <Text color="tomato" fontSize="12px">
                              {errors[key]}
                            </Text>
                          </Box>
                        );
                      }
                    })}
                    <Button
                      mt="6px"
                      tabIndex={2}
                      w="100%"
                      colorScheme="green"
                      isDisabled={!isValid}
                      isLoading={
                        fetchingUser || magicLinkEmailConfStatus == "loading"
                      }
                      type="submit"
                    >
                      Continue
                    </Button>

                    {/* {signupStatus == 'error' &&
                                        <Alert status="error">
                                            <AlertIcon />
                                            {errorMessage}
                                        </Alert>
                                    } */}
                  </form>
                )}
              </Formik>
            </>
          )}

          {/* {method === 'email' && emailType !== '' &&
                        <TopExplainer
                        >
                            {emailType === 'existing' && `Using ${emailValue}`}

                            {emailType === 'new' && `Using ${emailValue}`}

                        </TopExplainer>
                    } */}

          {method === "email" && emailType === "existing" && (
            <Box>
              <FormLabel fontSize="12px" fontWeight="400">
                Password
              </FormLabel>
              <InputGroup>
                <Input
                  autoFocus
                  value={passwordValue}
                  onChange={(e) => {
                    setPasswordValue(e.target.value);
                  }}
                  isRequired
                  focusBorderColor="green.500"
                  pr="4.5rem"
                  type={show ? "text" : "password"}
                  placeholder="Enter password"
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    tabIndex={-1}
                    size="sm"
                    onClick={handleClick}
                  >
                    {show ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </Box>
          )}

          {
          (
            method === "email" && 
            emailType === "new" && 
            phoneType !== 'existing'
          ) && (
            <Formik
              initialValues={{
                email: userValues["email"] ? userValues["email"] : "",
                firstName: userValues["firstName"]
                  ? userValues["firstName"]
                  : "",
                lastName: userValues["lastName"] ? userValues["lastName"] : "",
                industryRole: userValues["industryRole"]
                  ? userValues["industryRole"]
                  : "",
                brokerage: userValues["brokerage"]
                  ? userValues["brokerage"]
                  : "",
                title: userValues["title"] ? userValues["title"] : "",
                password: userValues["password"] ? userValues["password"] : "",
              }}
              validationSchema={EmailSchema}
              onSubmit={async (values, { setSubmitting }) => {
                // To avoid user initiating function twice
                if (signupStatus !== "loading") {
                  setTimeout(() => {
                    // signUpUser(values);
                    setUserValues(values);
                    // Validate phone and email
                    // setValidate(true);
                    // if (internal || !isProduction(window.location.host)) {
                      signUpUser(values);
                    // } 
                    // else {
                    //   findUserByPhone()
                    //   // verifFetch();
                    // }
                  }, 100);
                }

                setSubmitting(false);
              }}
            >
              {({
                isSubmitting,
                isValid,
                handleChange,
                values,
                handleSubmit,
                errors,
                touched,
              }) => (
                <form onSubmit={handleSubmit}>
                  <MarginContainer names>
                    <Box>
                      <FormControl w={"97%"}>
                        <MyFormLabel>First name</MyFormLabel>
                        <Field name="firstName">
                          {({ field, form }) => (
                            <Input
                              autoFocus
                              {...form}
                              autoComplete="given-name"
                              placeholder="Julie"
                              data-hj-allow
                              focusBorderColor="green.500"
                              {...field}
                              id="firstName"
                              type="text"
                            />
                          )}
                        </Field>
                      </FormControl>
                    </Box>
                    <Box>
                      <FormControl w={"97%"} float="right">
                        <MyFormLabel>Last name</MyFormLabel>
                        <Field name="lastName">
                          {({ field, form }) => (
                            <Input
                              {...form}
                              autoComplete="family-name"
                              placeholder="Stevens"
                              data-hj-allow
                              focusBorderColor="green.500"
                              {...field}
                              id="lastName"
                              type="text"
                            />
                          )}
                        </Field>
                      </FormControl>
                    </Box>
                  </MarginContainer>

                  <Tester />
                  <MarginContainer>
                    <MyFormLabel>Create a password</MyFormLabel>
                    <Field name="password">
                      {({ field, form }) => (
                        <InputGroup>
                          <Input
                            {...form}
                            {...field}
                            isRequired
                            id="password"
                            name="password"
                            focusBorderColor="green.500"
                            pr="4.5rem"
                            type={show ? "text" : "password"}
                            placeholder="Enter password"
                          />
                          <InputRightElement width="4.5rem">
                            <Button
                              h="1.75rem"
                              tabIndex={-1}
                              size="sm"
                              onClick={handleClick}
                            >
                              {show ? "Hide" : "Show"}
                            </Button>
                          </InputRightElement>
                        </InputGroup>
                      )}
                    </Field>
                  </MarginContainer>
                  <MarginContainer>
                    <MyFormLabel>Industry role</MyFormLabel>
                    <Select
                      focusBorderColor="green.500"
                      placeholder="Select industry role"
                      name="industryRole"
                      value={values["industryRole"]}
                      onChange={(e) => {
                        handleChange("industryRole")(e.target.value);
                      }}
                    >
                      {industryRoleOptions?.map((i, index) => (
                        <option key={index} value={i["value"]}>
                          {i["label"]}
                        </option>
                      ))}
                    </Select>
                  </MarginContainer>

                  <Popover
                    placement="bottom"
                    initialFocusRef={phoneRef}
                    closeOnBlur={false}
                    isOpen={validateStatus == "phoneInvalid"}
                  >
                    <PopoverTrigger>
                      <MarginContainer>
                        <MyFormLabel>
                          Phone number (for verification)
                        </MyFormLabel>
                        <PhoneNumberInput
                          value={phoneData}
                          onChange={(phone) => {
                            validateStatus == "phoneInvalid"
                              ? setValidateStatus("idle")
                              : null;
                            setPhoneData(!!phone ? phone : "")
                          }}
                        />
                        {/* <Field
                          type="tel"
                          name="phone"
                          phone
                          ref={phoneRef}
                          component={NumberInput}
                          value={phoneData}
                          onValueChange={(inpValues) => {
                            validateStatus == "phoneInvalid"
                              ? setValidateStatus("idle")
                              : null;
                            const { floatValue } = inpValues;
                            floatValue
                              ? setPhoneData(floatValue.toString())
                              : setPhoneData("");
                          }}
                        /> */}
                      </MarginContainer>
                    </PopoverTrigger>
                    <PopoverContent zIndex={4} borderColor="blue.800">
                      <PopoverHeader pt={4} fontWeight="bold" border="0">
                        Invalid phone number
                      </PopoverHeader>
                      <PopoverArrow />
                      <PopoverCloseButton
                        onClick={() => {
                          setValidateStatus("idle");
                        }}
                      />
                      <PopoverBody>{validityProblem}</PopoverBody>
                    </PopoverContent>
                  </Popover>

                  {(values.industryRole === "Realtor" ||
                    values.industryRole === "Business Broker") && (
                    <>
                      <MarginContainer>
                        <MyFormLabel>Brokerage name</MyFormLabel>
                        <CreatableSelect
                          theme={(theme) => ({
                            ...theme,  
                            colors: {
                              ...theme.colors,
                              primary: "#125F3F",
                              primary25: "#b1cab3",
                              primary50: "#b1cab3",
                            },
                          })}
                          filterOption={(options, filter, current_values) => { return options; }}
                          name="brokerage"
                          onInputChange={(e) => {
                            e.length > 0 ? setBrokerageFinderText(e) : null;
                          }}
                          onChange={(e) => {
                            handleChange("brokerage")(e.value);
                          }}
                          noOptionsMessage={() =>
                            brokerageStatus == 'loading'
                              ? "Loading"
                              : brokerageFinderText?.length < 1
                              ? "Begin typing"
                              : "No options"
                          }
                          // cacheOptions
                          // defaultOptions
                          options={foundBrokerages}
                        />
                        {/* <Field name="brokerage">
                          {({ field, form }) => (
                            <Input
                              type="text"
                              name="brokerage"
                              placeholder="Re/Max Realty Pros Inc."
                              id="brokerage"
                              data-hj-allow
                              focusBorderColor="green.500"
                              {...field}
                            />
                          )}
                        </Field> */}
                      </MarginContainer>

                      <MarginContainer>
                        <MyFormLabel>Select title</MyFormLabel>
                        <Select
                          placeholder="Select title"
                          name="title"
                          value={values["title"]}
                          onChange={(e) => {
                            handleChange("title")(e.target.value);
                          }}
                        >
                          {titleOptions?.map((i, index) => (
                            <option key={index} value={i["value"]}>
                              {i["label"]}
                            </option>
                          ))}
                        </Select>
                      </MarginContainer>
                    </>
                  )}

                  {Object.keys(errors).map((key) => {
                    if (touched[key]) {
                      return (
                        <Box>
                          <Text color="tomato" fontSize="12px">
                            {errors[key]}
                          </Text>
                        </Box>
                      );
                    }
                  })}
                  <Button
                    mt="6px"
                    w="100%"
                    colorScheme="green"
                    tabIndex={1}
                    // Phone length requirement only when not internal
                    isDisabled={
                      !isValid ||
                      (!internal && phoneIsInvalid) ||
                      ((values["industryRole"] === "Realtor" ||
                        values["industryRole"] === "Business Broker") &&
                        (values["title"] === "" || values["brokerage"] === ""))
                    }
                    isLoading={
                      isSubmitting ||
                      signupStatus === "loading" ||
                      validateStatus == "loading"
                    }
                    type="submit"
                  >
                    Verify Phone
                  </Button>

                  {signupStatus == "error" && errorMessage?.length > 0 && (
                    <Alert status="error">
                      <AlertIcon />
                      {errorMessage}
                    </Alert>
                  )}
                </form>
              )}
            </Formik>
          )}

          {
          (
            method === "social" && 
            emailType === "new" &&
            phoneType !== "existing"
          ) && (
            <Formik
              // initialValues={{
              //   industryRole: "",
              //   brokerage: "",
              //   title: "",
              // }}
              initialValues={{}}
              // validationSchema={SocialCompleteSchema}
              onSubmit={async (values, { setSubmitting }) => {
                // To avoid user initiating function twice
                if (signupStatus !== "loading") {
                  setCompleteStatus("loading");

                  Object.keys(values).map((key) => {
                    if (values[key] === "" || values[key] === null) {
                      delete values[key];
                    }
                  });                  
                  isPossiblePhoneNumber(phoneData) ? (values["phone"] = phoneData) : null;

                  setUserValues(values);
                  // setValidate(true);

                  // verifFetch();
                  findUserByPhone()

                
                }

                setSubmitting(false);
              }}
            >
              {({
                isSubmitting,
                isValid,
                handleChange,
                values,
                handleSubmit,
                errors,
                touched,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Tester />

          

                  <MarginContainer>
                    <MyFormLabel>Phone number (for verification)</MyFormLabel>
                    <PhoneNumberInput
                      autoFocus={true}
                      value={phoneData}
                      onChange={(phone) => setPhoneData(!!phone ? phone : "")}
                    />
                  </MarginContainer>

                  <Button
                    mt="6px"
                    w="100%"
                    colorScheme="green"
                    isDisabled={
                      !isValid ||
                      phoneIsInvalid ||
                      ((values["industryRole"] === "Realtor" ||
                        values["industryRole"] === "Business Broker") &&
                        (values["title"] === "" || values["brokerage"] === ""))
                    }
                    isLoading={isSubmitting || signupStatus === "loading" || completeStatus == 'loading'}
                    type="submit"
                  >
                    Verify
                  </Button>

                  {signupStatus == "error" && errorMessage?.length > 0 && (
                    <Alert status="error">
                      <AlertIcon />
                      {errorMessage}
                    </Alert>
                  )}
                </form>
              )}
            </Formik>
          )}

          {
            phoneType === 'existing' && (
              <Box>
                <Text>
                  An account with the phone number <strong>{phoneData}</strong> already exists with an email of <strong>{existingAccountEmail}</strong> <Button
                    onClick={() => {
                      // sign out user if they're signed in
                      auth.signOut();
                      setEmailType("existing");
                      setEmailValue(existingAccountEmail)
                      setPhoneType(null)
                      setMethod("email")
                    }}
                    variant={'link'}
                    color={'green.500'}
                    textDecor="underline"
                  >Login here</Button>
                </Text>
              </Box>
            )
          }

          {(method === "" ||
            (method === "email" && emailType === "existing")) && (
            <Button
              colorScheme="green"
              isDisabled={
                (method === "email" && emailValue.length == 0) ||
                (emailType == "existing" && passwordValue.length == 0)
              }
              isLoading={fetchingUser || loginStatus == "loading"}
              onClick={() => {
                method === "email"
                  ? emailType === ""
                    ? setFetchingUser(true)
                    : logInUser()
                  : setMethod("email");
              }}
            >
              {method === ""
                ? "Continue with Email"
                : emailType === ""
                ? "Continue"
                : emailType === "existing"
                ? "Log in"
                : "Sign up"}
            </Button>
          )}

          {method === "email" &&
            emailType === "existing" &&
            loginStatus == "error" &&
            errorMessage?.length > 0 && (
              <Alert status="error">
                <AlertIcon />
                {errorMessage}
              </Alert>
            )}
          {method === "email" && emailType === "existing" && (
            <>
              {magicLinkStatus !== "success" ? (
                <>
                  <Box h="30px" />
                  <ImportantExplainer>
                    New: get a magic link sent to your email that will log you
                    in instantly! No password needed.
                  </ImportantExplainer>
                  <Button
                    onClick={sendMagicLink}
                    colorScheme="blue"
                    isLoading={magicLinkStatus == "loading"}
                    loadingText="Sending"
                    variant="solid"
                    leftIcon={<LinkIcon />}
                  >
                    Send magic link
                  </Button>

                  <Box h="30px" />

                  <Button
                    variant="link"
                    size="xs"
                    textDecoration="underline"
                    onClick={() => {
                      setMethod("forgot");
                    }}
                  >
                    Forgot password?
                  </Button>
                </>
              ) : (
                <>
                  <Alert status="success" variant="subtle" marginTop="20px">
                    <AlertIcon />
                    Sent! Be sure to check spam/junk.
                  </Alert>

                  {emailButtons}
                </>
              )}
            </>
          )}
          {method === "" && (
            <BottomExplainer>
              By signing up, you agree to our{" "}
              <Link href="/terms" passHref>
                <TermsLink target="_blank"> Terms of Use</TermsLink>
              </Link>{" "}
              and{" "}
              <Link href="/privacy" passHref>
                <TermsLink target="_blank"> Privacy Policy</TermsLink>
              </Link>
              .
            </BottomExplainer>
          )}

          {method === "forgot" && <RecoverPassword email={emailValue} />}

          {method == "magicSignin" && (
            <Flex
              w="100%"
              height={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Spinner mt="20px" size="xl" color="green.800" />
            </Flex>
          )}

          {method == "verify" && (
            <>
              <Box margin="16px 0">
                <Input
                  type="text"
                  value={verifyCode}
                  isDisabled={
                    validateStatus == "loading" || signupStatus == "loading"
                  }
                  placeholder="Enter verification code"
                  onChange={(e) => {
                    setVerifyCode(e.target.value);

                    if (e.target.value.toString().length == 6) {
                      // Set load status loading
                      setCodeIncorrect(false);

                      //   Verify code here
                      setCheckCode(true);
                    }
                  }}
                  autoFocus
                  focusBorderColor="green.500"
                />
              </Box>

              {(validateStatus == "loading" || signupStatus == "loading") &&
              !resendCode ? (
                <Flex justifyContent={"center"} width="100%" marginY="24px">
                  <Spinner size="lg" color="green.800" />
                </Flex>
              ) : (
                <>
                  <Box marginY="24px">
                    <SmallFancy>
                      Didn't get a code?{" "}
                      <Button
                        onClick={() => {
                          setResendCode(true);
                          // Reset the captcha
                        }}
                        isDisabled={validateStatus == "loading"}
                        fontWeight={"500"}
                        verticalAlign={"inherit"}
                        paddingY="0px"
                        isLoading={resendCode && validateStatus == "loading"}
                        loadingText="Resending..."
                        marginY="0px"
                        ml="6px"
                        display="inline"
                        variant="link"
                        colorScheme="green"
                      >
                        Re-send code
                      </Button>
                    </SmallFancy>
                  </Box>
                </>
              )}
            </>
          )}
        </LeftGrid>

        <Box margin="16px auto 0px" width="200px" tabIndex={-1}>
          <a
           tabIndex={-1}
            rel="noopener noreferrer"
            onClick={() => {
              clientAnalytics.track("Review link visited on signup modal");
            }}
            href="https://goo.gl/maps/YYngTFK3k7PVwHfm7"
            target="_blank"
          >
            <Image
             tabIndex={-1}
              src={`/images/signup/rating.png`}
              // width={400}
              // height={50}
              // layout="responsive"
            />
          </a>
        </Box>
      </LeftSide>

      {size !== "small" && (
        <RightSide>
          <TrustedText>
            {" "}
            Join thousands of Canadians finding commercial real estate and sale
            of business opportunities each month on Zonado.
          </TrustedText>

          <Stack spacing={4} color="white" margin="45px 0px">
            <Text>✔ Non-MLS® listings you'll find nowhere else</Text>
            <Text>✔ Connect directly with owners and agents</Text>
            <Text>✔ Save searches & listings across devices</Text>
          </Stack>

          <BottomSpan>
            {" "}
            Made with{" "}
            <FaHeart
              style={{ display: "inline", margin: "0 4px" }}
              color="#fff"
            />{" "}
            in Toronto. Trusted by 10,000+ Canadians.
          </BottomSpan>


          {/* <Box>
                        <Image src={logos} height='auto'
                            margin='32px auto'
                            padding='0 24px'
                            display='flex'
                            justifyContent='center'
                            alignItems='center'
                        />
                    </Box> */}
        </RightSide>
      )}
    </Overall>
  );
};

export default SignUpComponent;
