import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Box, Button, Text, Flex } from "@chakra-ui/react";
import Image from "next/image";
import Link from "next/link";
import React from "react";

const ProfessionalsCard = ({
  item: { title, body, img, route },
  whoweHelp,
  last,
}) => {
  return (
    <Box
      width={{ base: whoweHelp ? "100%" : "unset", md: "48%", lg: "32%" }}
      mb={{ base: whoweHelp ? "30px" : "0" }}
    >
      <Link href={`/who-we-help${route}`} passHref>
        <a>
          <Flex
            px={{ base: "45px" }}
            py={{ base: "40px" }}
            bg={"#F7F8FA"}
            shadow={"0"}
            width={{
              base: whoweHelp ? "100%" : "312px",
              md: whoweHelp ? "100%" : "100%",
            }}
            height={{ base: "100%" }}
            mr={{
              base: whoweHelp ? 0 : last ? 0 : "20px",
              md: whoweHelp ? 0 : 0,
            }}
            flexDir={"column"}
            justifyContent={"space-between"}
            alignItems={"flex-start"}
          >
            <Box>
              <Text
                fontSize={{ base: "25px" }}
                fontWeight={"500"}
                fontStyle={"medium"}
                lineHeight={{ base: "36px" }}
              >
                {title}
              </Text>
              <Text
                color={"gray.500"}
                mt={"10px"}
                mb={"18px"}
                fontSize={{ base: "14px", md: "16px" }}
                fontWeight={"500"}
                lineHeight={{ base: "22px", md: "28px" }}
              >
                {body}
              </Text>
            </Box>

            <Box>
              <Button
                variant={"link"}
                color={"brand.800"}
                mb={"14px"}
                outline={"0"}
              >
                Learn More
                <ArrowForwardIcon w={"20px"} h={"20px"} ml={"2"} />
              </Button>

              <Box rounded={"10px"}>
                <Image
                  src={img}
                  width={"292px"}
                  height={"257px"}
                  alt={`for ${title}`}
                />
              </Box>
            </Box>
          </Flex>
        </a>
      </Link>
    </Box>
  );
};

export { ProfessionalsCard };
