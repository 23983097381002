import styled from 'styled-components';

export const OverallLarge = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 100%;
    width: 100vw;

`

export const Overall = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 100%;
    padding: 0 24px;
`

export const ListingLink = styled.a`
margin-right: ${props => props.last ? "0px" : "20px"};

@media (min-width: 32rem) {
   width: 100%
}

@media (min-width: 48rem) {
   width: 32%;
}

@media (min-width: 62rem) {
    width: 26%;
}
`

export const HeaderLineContainer = styled.section`
    font-family: Inter;
    max-width: 931px;
    margin: 0 auto;
    margin-top: 10vh;  
`

export const HeaderLine = styled.h1`
    font-style: normal;
    font-weight: bold;
    font-size: 3.4rem;
    line-height: 4.1rem;
    text-align: center;

    @media (max-width: 767px) {
        font-size: 32px;
        line-height: 1.25;
    }
`

export const SecondaryLine = styled.h2`
    font-style: normal;
    font-weight: normal;
    font-size: 1.25rem;
    line-height: 2rem;
    /* or 150% */
    margin-top: 40px;

    text-align: center;

    color: #8A94A6;

    @media (max-width: 767px) {
        font-size: 16px;
        line-height: 1.5;
    }
`

export const MainCta = styled.div`
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background: ${(props) => props.type === 'filled' ? '#125f3f' : '#FFF'};
    color: ${(props) => props.type === 'filled' ? '#FFF' : '#125f3f'};
    display: block;
    padding: 10px 60px;
    border-radius: 4px;
    border: 1px solid #125f3f;
    transition: all 0.3s ease;
    white-space: nowrap;

    :hover {
        cursor: pointer;
    }
`

export const CTAHolder = styled.div`
	display: grid;
	grid-column-gap: 20px;
	grid-template-columns: 1fr 1fr;
	max-width: 500px;
	margin: 0 auto;
	margin-top: 60px;

    @media (max-width: 521px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        grid-row-gap: 20px
    }
`

export const Bolder = styled.span`
    font-weight: bold;
`

export const SectionFancyText = styled.p`
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 150% */

    text-align: center;
    letter-spacing: 0.4em;

    color: #000000;
    margin-top: 70px;
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : "70px"};

`
export const NumberLine = styled.span`
    height:  ${(props) => props.height ? props.height : "100px"};
    background: ${(props) => `linear-gradient(${props.startColor} , ${props.endColor})`} ;
    padding-right: 1px;
    width: 1px;
    margin: 0 auto;
    display: ${(props) => props.onlyOnTablet ? "none" : "inherit"} ;

    @media (max-width: 959px) {
        height: 100px;
        display: ${(props) => props.hidenOnTablet ? "none" : "inherit"} ;
    }
`

export const SectionLabelNumber = styled.span`
	display: inline-flex;
	border-radius: 100%;
	color: white;
	width: 40px;
	height: 40px;
	align-items: center;
	justify-content: center;
	font-weight: 700;
	margin-bottom: 24px;
	background: ${(props) => `linear-gradient(90deg,${props.startColor},${props.endColor})`};
    
    
`

export const SectionLabel = styled.h3`
    font-size: 2rem;
    letter-spacing: -.05em;
    margin: 0;
    font-weight: 700;
    text-align: center;
`

export const SectionLabelText = styled.span`
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	padding-left: 0.2em;
	padding-right: 0.2em;
	background-image: ${(props) => `linear-gradient(90deg,${props.startColor},${props.endColor})`};
`

export const SectionTitle = styled.h4`
	text-align: center;
	margin: 0 0 24px;
	padding-bottom: 12px;
	font-weight: 700;
	letter-spacing: -.05em;
	font-size: 3.75rem;

    @media (max-width: 600px) {
        font-size: 2.5rem;
    }
`
export const ParaOverallContainer = styled.div`
    display: flex;
    position: relative;
    min-width: 1px;
    flex: 1;
    justify-content: flex-start;
    align-items: stretch;   
	flex-direction: row;
	max-width: 100%;
    margin-bottom: 64px;

    @media (max-width: 800px) {
        flex-direction: column;
    }
`

export const ParaPara = styled.p`
    line-height: 1.6;
    letter-spacing: -.02em;
    margin: 0;
    color: #666;
    text-align: center;

    @media (min-width: 950px) {
        text-align: ${(props) => props.center ? "center" : "left"};
    }
`

export const Wrapper = styled.div`
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    width: 1048px;
    /* padding-left: 24px;
    padding-right: 24px; */
`

export const ListingMemo = styled.div`
	display: grid;
	width: calc(1048px + 48px * 2);
	padding-left: 24px;
	padding-right: 24px;
	max-width: 100%;
	margin-left: auto;
	margin-right: auto;
    margin-bottom: ${(props) => props.marginBottom ? props.marginBottom : "0px"};

    @media (min-width: 950px) {
        grid-template-columns: repeat(2,minmax(0,1fr));
    }
`

export const ListingMemoBenefits = styled.div`
    padding: 0;
    margin-top: ${(props) => props.marginTop ? props.marginTop : 0};

    @media (min-width: 950px) {
        padding-left: 64px;
        padding-right: 48px;
    }
`

export const ListingMemoBenefitItem = styled.div`
    margin-bottom: 64px;
    text-align: left;
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
`

export const SmallHeading = styled.h5`
    font-size: 1.5rem;
    letter-spacing: -.05em;
    margin: 0 0 12px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 700;
    text-align: center;

    @media (min-width: 950px) {
        text-align: left;
    }
`

export const ListingMemoImage = styled.div`
    max-width: 90%;
`
export const ListingMemoImages = styled.div`
    width: 100%;
    max-width: 540px;
	margin-left: auto;
	margin-right: auto;
    margin-bottom: 20px;

    @media (min-width: 950px) {
        margin-bottom: -40px;

    }
`

export const Brokerages = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 64px;
    max-width: 100%;
    filter: grayscale(100%);
`
export const BrokerageImg = styled.div`
    width: ${(props) => props.width ? props.width : "85px"};
    margin: 12px 18px;
`
export const ParaContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    min-width: 1px;
    max-width: 100%;
    flex: 1;
    justify-content: flex-start;
    align-items: stretch;
    margin-left: ${(props) => props.second ? "24px" : 0};

    @media (max-width: 800px) {
        margin-top: ${(props) => props.second ? "24px" : 0};
        margin-left: 0;
    }
`

export const SectionDescripion = styled.div`
	max-width: 45rem;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
    padding-bottom: 8px;
    margin-bottom: 64px;
`

export const LongSection = styled.div`
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    width: calc(1048px + 48px * 2);
    padding-left: 24px;
    padding-right: 24px;

    @media (max-width: 959px) {
        padding: 0px;
    }
`

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    min-width: 1px;
    max-width: 100%;
    flex: 1;
    justify-content: flex-start;
    align-items: stretch;
`

export const LongSectionWrapper = styled.div`
    display: flex;
    width: 100%;

    @media (max-width: 959px) {
        flex-direction: column;
    }
`

export const LongSectionImageCorner = styled.div`
    flex: 1 1;
    max-width: 90%;

    @media (max-width: 959px) {
        order: 2;
        padding-bottom: 48px;
        max-width: 100%;
    }
`

export const LongSectionLineCorner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 959px) {
        order: 0;
        align-items: center;
        /* height: 100px; */
    }
`

export const PathCircle = styled.span`
    width: 11px;
    height: 11px;
    box-shadow: 0 0 0 1.5px #999 inset;
    border-radius: 50%;
`

export const PathLine = styled.span`
    width: 1px;
    align-self: center;
    border-right: 1px dashed #999;
    flex: 1 1;

    @media (max-width: 959px) {
        display: ${(props) => props.hidenOnTablet ? "none" : "inherit"};
    }
`

export const LongSectionDescriptionCorner = styled.div`
    display: flex;
    flex: 1 1;
`

export const LongSectionDescriptionInner = styled.div`
    padding-left: 59px;
    padding-right: 48px;
    margin-top: -8px;
    padding-bottom: 256px;
    margin-bottom: 24px;

    @media (max-width: 959px) {
        width: 480px;
        max-width: 100%;
        text-align: center;
        padding: 24px 0px;
        margin: auto;
    }
`

export const ImageTranslator = styled.div`
    transform: translateY(-45%);

    @media (max-width: 959px) {
        width: 480px;
        max-width: 100%;
        margin: auto;
        padding-right: 0;
        transform: translateY(0%);
    }
`

export const ButtonBox = styled.div`
    width: 240px;
    margin: 0 auto;
    text-align: center;
`

export const FooterWrapper = styled.div`
    background: rgba(250, 250, 250, 1);
    padding-top: 70px;
    padding-bottom: 32px;
    color: rgba(0, 0, 0, 1);

`

export const FooterContainer = styled.div`
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 50px;
    padding-left: 50px;

    @media (max-width: 991px) {
        padding-right: 30px;
        padding-left: 30px; 
    }
`

export const FooterGrid = styled.div`
    display: flex;
    justify-content: space-between;


    @media (max-width: 991px) {
        display: grid;
        grid-auto-columns: 1fr;
        grid-column-gap: 40px;
        grid-row-gap: 16px;
        grid-template-columns: auto 1fr;
        grid-template-rows: auto;
    }

    @media (max-width: 767px) {
        grid-row-gap: 80px;
        grid-template-columns: 1fr;
    }

`


export const BottomWrapper = styled.div`
	display: grid;
	margin-top: 0;
	padding-top: 30px;
	justify-content: space-between;
	grid-auto-columns: 1fr;
	grid-column-gap: 16px;
	grid-row-gap: 10px;
	grid-template-columns: 1fr auto;
	grid-template-rows: auto auto;
	border-top: 1px solid rgba(0, 0, 0, 0.13);

    @media (max-width: 767px) {
        margin-top: 60px;
        padding-top: 50px;
        grid-row-gap: 24px;
        grid-template-columns: 1fr;
        text-align: center;
    }
`

export const FooterBrand = styled.a`
	margin-bottom: 0px;
	max-width: 100%;
	display: inline-block;
`

export const FooterBrandContainer = styled.div`
    display: flex;
    flex-direction: column;
`

export const FooterTagline = styled.div`
	font-size: 14px;
	letter-spacing: 1px;
    text-align: center;
    // width: 191px;
`

export const FooterNavGrid = styled.div`
    display: flex;
    /* width: 680px; */
    margin-left: 60px;flex: 0 0 auto;

    @media (max-width: 991px) {
        width: auto;
        justify-content: flex-end;
    }

    @media (max-width: 767px) {
        display: grid;
        margin-left: 0;
        grid-auto-columns: 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 24px;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto;
    }

    @media (max-width: 525px) {
        margin-left: 0;
        grid-column-gap: 30px;
        grid-template-columns: 1fr auto;
    }
`

export const FooterNavCol = styled.div`
    margin-right: 100px;

    @media (max-width: 991px) {
        margin-right: 50px;
        flex: 0 0 auto;
    }

    @media (max-width: 767px) {
        margin-right: 20px;
    }

    @media (max-width: 525px) {
        margin-right: 0px;
    }
`

export const FooterNavHeading = styled.h5`
	margin-bottom: 32px;
	font-size: 14px;
	font-weight: 600;
	letter-spacing: 1.4px;
	text-transform: uppercase;
    margin-top: 0;
    line-height: 1.2;
`

export const FooterNavLink = styled.a`
	display: block;
	margin-bottom: 20px;
	-webkit-transition: opacity .2s;
	transition: opacity .2s;
	/* color: #fff; */
	font-size: 14px;
	letter-spacing: .7000000000000001px;
`

export const FooterNavText = styled.p`
	display: block;
	margin-bottom: 20px;
	-webkit-transition: opacity .2s;
	transition: opacity .2s;
	/* color: #fff; */
	font-size: 14px;
	letter-spacing: .7000000000000001px;
`

export const FooterLegalLinks = styled.div`
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 14px;

    @media (max-width: 767px) {
        display: block;
    }
`

export const FooterCopyright = styled.div`
    @media (max-width: 767px) {
        margin-bottom: 24px;
    }
`

export const FooterLegalLink = styled.a`
    margin-left: 20px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    transition: border .2s;

    :hover {
        border-bottom-color: rgba(0, 0, 0, 1);
    }
`

export const SocialLinksWrapper = styled.div`
    margin-right: -10px;
    margin-left: -10px;
    justify-self: end;

    @media (max-width: 767px) {
        justify-content: center;
        margin-bottom: 10px;
        margin: 0 auto;
    }
`

export const SocialLink = styled.a`
    margin-right: 10px;
    margin-left: 10px;
    transition: opacity .2s;
    max-width: 100%;
    display: inline-block;

    :hover {
        opacity: 50%
    }
`

export const SocialLinkIcon = styled.img`
    border: 0;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
`

export const FooterLegalText = styled.div`
    font-size: 12px;
    line-height: 14px;
    color: #0000005e;

    @media(max-width: 991px) {
        max-width: 525px;
        margin: 0 auto;
    }
`