import React from 'react'
import Image from 'next/image'
import { Box, Flex, Image as ImageComponent, Text } from '@chakra-ui/react'

export const RatingBanner = ({ ratingHeader, review, reviewer, ratingImg }) => {
    return (
        <Flex bg={"brand.100"} px={{ base: "15px", lg: "122px" }} flexDir={{ base: "column", md: "row" }} justifyContent={"space-between"} mx={{ base: "-15px", lg: "inherit" }}>

            <Flex w={{ md: "50%" }}>
                <ImageComponent
                    src={ratingImg}
                    // width={"1000%"} height={"1000%"}
                    placeholder={"blur"}
                    w={["100%", "350px", "100%"]} mx={"auto"}
                    objectFit={"contain"}
                    blurDataURL={<Image src={"/images/newLanding/adsPlaceholder.png"} />}
                />
            </Flex>

            <Flex flexDir={"column"} justifyContent={"center"} py={"80px"} w={{ md: "40%" }}>
                <ImageComponent src={"/images/newLanding/ratingsIcons.svg"} height={"30px"} width={"100px"} mx={["auto", "auto", "0"]} />

                <Text mt={"28px"} fontWeight={"500"} textAlign={["center", "center", "start"]} fontSize={"18px"}>“{ratingHeader}”</Text>

                <Text
                    color={"gray.500"} fontSize={{ base: "14px", lg: "16px" }} fontWeight={"500"}
                    lineHeight={{ base: "24px", lg: "28px" }} my={"32px"}
                    textAlign={{ base: "center", md: "left" }}
                >
                    {review}
                </Text>

                <Text fontWeight={"500"} textAlign={["center", "center", "start"]} fontSize={"18px"}>{reviewer}</Text>

                <Box my={"20px"} borderTopWidth={"1px"} borderTopColor={"gray.300"} />

                <a rel="noopener noreferrer" href="https://ca.trustpilot.com/review/zonado.com" target="_blank">

                    <Flex justifyContent={"space-between"} alignItems={"start"} >
                        <ImageComponent src={"/images/newLanding/label.svg"} objectFit={"contain"} w={"40%"} mt={{ base: "13px", md: "6px" }} />
                        <ImageComponent src={"/images/newLanding/rate.svg"} objectFit={"contain"} w={"40%"} />
                    </Flex>
                </a>
            </Flex>

        </Flex >
    )
}
