import React from 'react';
import { Box, Flex, Text, Image as ImageComponent } from '@chakra-ui/react'
import { RealtorsHeader, RealtorsSemiHeader } from '../../styles/typography'

export const PerformanceBanner = ({ noHeader }) => {
    return (
        <>
            {!noHeader && <Box mt={"96px"}>
                <RealtorsHeader
                    text={"Canada’s fastest growing platform 100% dedicated to Businesses and Commercial Real Estate"}
                    textAlign={"center"}
                />
                <RealtorsSemiHeader
                    text={<> Commercial real estate doesn’t work like the residential market. <br />
                        So why use limited platforms built for residential to buy and sell commercial assets.</>}
                    textAlign={"center"}
                    fontWeight={"normal"}
                />
            </Box>}

            <Flex my={"96px"} flexDir={{ base: "column-reverse", md: "row" }} justifyContent={"space-between"} alignItems={"center"}>

                <Box w={{ md: "55%" }} mt={{ base: "50px", md: "0" }}>
                    <ImageComponent src={"/images/newLanding/professionalsListingActivityImage.png"} w={["100%", "350px", "100%"]} mx={"auto"} />
                </Box>
                <Box w={{ md: "40%" }}>
                    <Text fontWeight={"500"} fontSize={{ base: "24px", md: "30px" }} lineHeight={{ base: "36px", md: "40px" }} textAlign={{ base: "center", md: "start" }}>Performance data and transparency like never before</Text>
                    <Text fontWeight={"400"} fontSize={"16px"} lineHeight={"26px"} mt={"30px"} color={"gray.500"} textAlign={{ base: "center", md: "start" }}>Get detailed report on listing performance, impressions, who viewed your listing, accessed phone number and signed the NDA. No guess work, proactively reach out to leads and be armed for stronger negotiations.</Text>
                </Box>
            </Flex>
        </>
    )
}