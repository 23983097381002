import { query } from "firebase/firestore";
import { useRouter } from "next/router";
import { useState, createContext, useEffect } from "react";

interface IQueryStateContext {
    queryState: any,
    setQueryState: any,
    propertyTypeChecked: any,
    setPropertyTypeChecked: any
    subTypeChecked: any
    setSubTypeChecked: any
    expandedSubTypes: any
    setExpandedSubTypes: any,
    showMap: boolean,
    setShowMap: any
    recordingURL?: string
}

export const QueryStateContext = createContext<IQueryStateContext>(null)
export function GlobalStateProvider({ recordingURL, children }) {
    const router = useRouter()
    
    let initialValues = {
        placeId: router.query["placeId"] || [],
        offMarket: router.query["offMarket"] == "true" ? true : [],
        brokerCoop: router.query["brokerCoop"] == "true" ? true : [],
        regions: router.query["regions"] || [],
        propertyType: router.query["propertyType"] || [],
        subType: router.query["subType"] || [],
        type: router.query["type"] || [],
        cap: router.query["cap"] || [],
        businessType: router.query["businessType"] || [],
        businessCategory: router.query["businessCategory"] || [],
        price: router.query["price"] || [],
        sqft: router.query["sqft"] || [],
        acres: router.query["acres"] || [],
        keyword: router.query["keyword"] || [],
        timeline: router.query["timeline"] || [],
        sortBy: router.query["sortBy"] || [],
        user: router.query["user"] || [],
        name: router.query["name"] || [],
        postedBy: router.query["postedBy"] || [],
        page: parseInt(router.query["page"] as string) || [],
        bounds: router.query["bounds"] || [],
        province: router.query['province'] || [], // For preserving the province filter when viewing next page of /listings/<provinceSlug>
        city: router.query['city'] || []
    };


    const formattedInitialValues = Object.keys(initialValues).reduce((acc, key) => {
        const queryValue = initialValues[key]
        // Don't convert offMarker and brokerCoop to an array
        
        const propertiesAsArray = [
            'propertyType',
            'subType',
            'type',
            'businessType',
            'businessCategory',
            'province',
            'city',
            'placeId'
        ]

        if (
            (
                propertiesAsArray.includes(key)
            ) && 
            !Array.isArray(queryValue)
        ) {
            acc[key] = [queryValue]
        }
        else {
            acc[key] = queryValue
            // return [router.query[key]]
        }
        return acc
    }, {})


    const [queryState, setQueryState] = useState(formattedInitialValues)
    const [propertyTypeChecked, setPropertyTypeChecked] = useState({});
    const [subTypeChecked, setSubTypeChecked] = useState({});
    const [expandedSubTypes, setExpandedSubTypes] = useState({});
    const [showMap, setShowMap] = useState(true);

   
    return ( 
        <QueryStateContext.Provider 
            value={{
                queryState,
                recordingURL: recordingURL,
                setQueryState,
                propertyTypeChecked,
                setPropertyTypeChecked,
                subTypeChecked,
                setSubTypeChecked,
                expandedSubTypes,
                setExpandedSubTypes,
                showMap,
                setShowMap
            }}
        >
            {children}
        </QueryStateContext.Provider>
    )
}