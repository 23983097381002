import styled from 'styled-components'

export const Overall = styled.div`
    display: grid;
    grid-template-columns: ${props => props.size === 'small' ? '1fr' : "1fr 1fr"};
    height: 100%;
    width: 100%;
    max-width: 1000px;
    margin: auto 0;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        flex: 1;
    }
`

export const LeftGrid = styled.div`
    display: grid;
    grid-row-gap: 16px;
    grid-template-columns: minmax(0,1fr);
`
export const RightSide = styled.div`
    padding: 50px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #125f3f;

     @media (max-width: 768px) {
       display: none;
    }
`

export const LeftSide  = styled.div`
    padding: 30px;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 300px;

    @media (max-width: 768px) {
        padding: 15px;
}        `

export const TopTextGrid = styled.div`
    display: grid;
    grid-row-gap: 16px;
`


export const TopHeader = styled.div`
    font-family: 'Jarkarta', sans-serif;
    font-size: 24px;
    letter-spacing: -.3px;
    line-height: 1.3;
    font-weight: 500;
    display: flex;
    align-items: center;
`

export const TopExplainer = styled.div`
    font-weight: 400;
    line-height: 1.6;
    font-size: 14px;
    color: rgba(13,18,22,.7);
    text-align: left;
`

export const ImportantExplainer = styled.div`
    font-weight: 500;
    line-height: 1.6;
    font-size: 14px;
    color: rgba(13,18,22,.7);
    text-align: center;
`

export const BottomExplainer = styled.div`
    font-weight: 400;
    line-height: 1.6;
    font-size: 12px;
    color: rgba(13,18,22,.7);
    text-align: center;
`

export const BottomSpan = styled.p`
    font-weight: 400;
    line-height: 1.6;
    font-size: 13px;
    color: rgba(13,18,22,.7);
    text-align: center;
    color: white;
    margin-top: 25px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 22px;
`

export const TermsLink = styled.a`
    text-decoration: underline;
`

export const FullContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`

export const TrustedText = styled.p`
    font-family: 'Segoe UI',sans-serif;
    color: white;
    font-size: 17px;
    text-align: left;
    font-weight: 600;
    line-height: 1.5;
`