import React, { useState } from 'react';
import { Text, Flex, Button } from "@chakra-ui/react"
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { SubscribeEmail } from '../UI/Forms/Input/Input'
import { httpsCallable } from 'firebase/functions'
import { functions } from '../../src/config/firebase'
import { clientError } from '../../utils/utilties';

export const FooterBanner = () => {

    const [subscribeStatus, setSubscribeStatus] = useState('idle');

    const emailSubscribe = (email) => {
        const addSubscriber = httpsCallable(functions, 'addSubscriber') 
        addSubscriber({ email: email })
            .then((result) => {
                setSubscribeStatus('success');
            })
            .catch((error) => {
                // Getting the Error details.
                clientError(error);
                // ...
            });
    }

    const EmailSchema = Yup.object().shape({
        email: Yup.string()
            .email('Invalid email')
            .required('Email is required')
    });

    return (
        <Flex bg={"brand.900"} px={{ base: "44px", md: "48px", lg: "118px" }} py={{ base: "60px", lg: "80px" }} mx={{ base: "-25px", lg: "0" }} flexDir={{ base: "column", md: "row" }} alignItems={"center"} mb={"120px"}>
            <Flex w={{ md: "50%" }} flexDir={"column"} mr={{ md: "50px" }}>
                <Text color={"white"} fontSize={{ base: "30px", md: "32px", lg: "40px" }} lineHeight={{ base: "36px", lg: "45px" }} fontWeight={"500"} textAlign={{ base: 'center', md: "start" }}>Be the first to know</Text>
                <Text w={{ md: "80%" }} mt={"15px"} color={"gray.200"} fontSize={"16px"} lineHeight={"24px"} fontWeight={"500"} textAlign={{ base: 'center', md: "start" }}>The latest news, data, trends and resources, sent straight to you.</Text>
            </Flex>

            {subscribeStatus !== 'success' &&
                <Formik
                    initialValues={{
                        email: '',
                    }}
                    validationSchema={EmailSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                        await setTimeout(() => {
                            if (subscribeStatus !== 'loading') {
                                setSubscribeStatus('loading')
                                emailSubscribe(values.email);
                            }

                        }, 100);

                        setSubmitting(false);
                    }}
                >

                    {({ isSubmitting, isValid, handleChange, values, handleSubmit, errors, touched }) => (

                        <Flex w={{ base: "100%", md: "50%" }} mt={{ base: "60px", md: "0" }}>
                            <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                                <Flex position={"relative"}>
                                    <Field
                                        type='email'
                                        name="email"
                                        placeholder="Your email"
                                        rounded={"22px"}
                                        component={SubscribeEmail}
                                    />
                                    {/* <Box
                                    > */}
                                    <Button
                                        // ml={"-102px"}
                                        type="submit"
                                        isLoading={isSubmitting}
                                        loadingText={"Loading"}
                                        disabled={!isValid || isSubmitting || subscribeStatus === "loading"}
                                        bg={"brand.800"}
                                        color={"white"}
                                        rounded={"22px"}
                                        fontWeight={"400"}
                                        px={"25px"}
                                        py={"13px"}
                                        fontSize={"15px"}
                                        borderWidth={"1px"}
                                        position={"absolute"}
                                        right={"0"}
                                        borderColor={"transparent"}
                                        _hover={{
                                            bg: "brand.900",
                                            borderColor: "brand.800"
                                        }}
                                    >
                                        {subscribeStatus === "loading" ? "Submitting..." : "Submit"}
                                    </Button>
                                    {/* </Box> */}
                                </Flex>
                            </form>
                        </Flex>
                    )}

                </Formik>
            }

            {subscribeStatus === 'success' &&
                <Text color="white" my={{ base: "30px", md: "0" }} textAlign={"center"}>You’re all set, look out for our next newsletter!</Text>
            }
        </Flex>
    )
}