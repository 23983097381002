import { Box, Button, Flex, Icon, Image, Select, Text, Tooltip, useToast } from "@chakra-ui/react";
import clusterIcon from "../public/images/search/cluster2.svg";
import { BLOCKS } from "@contentful/rich-text-types";
import { QuestionIcon, SmallCloseIcon } from "@chakra-ui/icons";
import { formatPhoneNumberIntl, Value } from "react-phone-number-input";
import LogRocket from 'logrocket';
import * as Sentry from "@sentry/nextjs";
import { datadogRum } from '@datadog/browser-rum';
import usePlacesAutocomplete, { getGeocode } from "use-places-autocomplete";
import { MyFormLabel } from "./clientUtilities";
import ReactSelect from 'react-select'

export const urlify = (text) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.split(urlRegex).map((part) => {
    if (part.match(urlRegex)) {
      return (
        <a href={part} target="_blank" style={{textDecoration: "underline"}}>
          {part}
        </a>
      );
    }
    return part;
  });
};

export const truncateString = (str, num) => {
  if (!str) {
    return "";
  }
  // If the length of str is less than or equal to num
  // just return str--don't truncate it.
  else if (str?.length <= num) {
    return str;
  }
  // Return str truncated with '...' concatenated to the end of str.
  const strChuncks = str.split(" ");
  const slicedStr = str.slice(0, num).trim();
  const slicedStrChunks = slicedStr.split(" ");
  const lastSlicedStrChunkIndex = slicedStrChunks.length - 1;
  const lastChunkInSlicedStr = slicedStrChunks[lastSlicedStrChunkIndex];
  const lastChunkInStr = strChuncks[lastSlicedStrChunkIndex];
  if (lastChunkInStr === lastChunkInSlicedStr) {
    const endsWithPeriod = slicedStr.slice(-1) === "."
    return `${slicedStr}${endsWithPeriod ? ".." : "..."}`;
  } else {
    const truncatedStr = slicedStrChunks.slice(0, lastSlicedStrChunkIndex).join(" ")
    const endsWithPeriod = truncatedStr.slice(-1) === '.'
    return `${truncatedStr}${endsWithPeriod ? ".." : "..."}`;
  }
};

export const StateSvg = ({ svg, heading, subHeading, ...rest }) => {
  return (
    <Flex
      my="20px"
      mx="auto"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      w="100%"
      maxW="800px"
    >
      <Box
        border="1px solid #efefef"
        borderRadius="4px"
        marginBottom="48px"
        backgroundColor="#fdfdfd"
        position="relative"
        padding="20px"
      >
        <Box>
          <Image
            src={svg}
            width={rest?.size == "sm" ? "200px" : "300px"}
            height="auto"
            margin="32px auto"
            padding="0 24px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          />
        </Box>
        <Text
          textAlign="center"
          margin="0 8px 16px"
          fontSize="16px"
          fontWeight="bold"
          mb="10px"
        >
          {heading}
        </Text>
        <Text textAlign="center" margin="0 8px 16px" fontSize="16px">
          {subHeading}
        </Text>
        {rest["children"]}
      </Box>
    </Flex>
  );
};

export const formatMoney = (n) => {
  if (n < 1e3) return "$" + n;
  if (n >= 1e3 && n < 1e6) return "$" + (n / 1e3).toFixed(1) + "K";
  if (n == 1e7) return "$" + (n / 1e6).toFixed(1) + "M+";
  if (n >= 1e6 && n < 1e9) return "$" + (n / 1e6).toFixed(1) + "M";
  if (n >= 1e9 && n < 1e12) return "$" + (n / 1e9).toFixed(1) + "B";
  if (n >= 1e12) return "$" + (n / 1e12).toFixed(1) + "T";
};

export const clusterStyles = [
  {
    textColor: "white",
    url: clusterIcon,
    height: 30,
    width: 30,
  },
  {
    textColor: "white",
    url: clusterIcon,
    height: 50,
    width: 50,
  },
  {
    textColor: "white",
    url: clusterIcon,
    height: 65,
    width: 65,
  },
];

export const options = {
  disableDefaultUI: true,
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: true,
  gestureHandling: "greedy",
  // styles: mapStyles
};
export const env_check = process.env.NODE_ENV === "production";

export const isProduction = (host) => {
  if (
    !host.includes("internal") &&
    host.includes("zonado.com")
  ) {
    return true;
  } else {
    return false;
  }
};

export const clientAnalytics = {
  track(...args) {
    // @ts-ignore
    if (env_check && isProduction(window.location.hostname)) {
      // @ts-ignore
    window?.analytics?.track(...args); LogRocket.track(...args); datadogRum.addAction(...args);
    }
  },
  identify(...args) {
   
    if (env_check && isProduction(window.location.hostname)) {
      // @ts-ignore
      window?.analytics?.identify(...args); LogRocket.identify(...args);
      datadogRum.setUser({
        id: args[0],
        ...args.slice(1)
      })
    }
  }
}

export const clientError = (e) => {
  try {
    LogRocket.captureException(e)
    Sentry.captureException(e);
    
  }

  catch (e) {
    console.error(e);
  }
}
export const StreetViewoptions = {
  disableDefaultUI: true,
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,
  gestureHandling: "greedy",
  // styles: mapStyles
};
export const addListingOptions = {
  disableDefaultUI: true,
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: true,
  rotateControl: false,
  // gestureHandling: "greedy"
  // styles: mapStyles
};

export const checkRender = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return (
        <p className="mb-6 md:mb-8 font-normal text-gray-500 leading-7">
          {children.map((item, index) => {
            if (item.type && item.type === "a") {
              const { children, href } = item.props;
              return (
                <a
                  key={index}
                  href={href}
                  className={"text-primary underline"}
                  target="_blank"
                >
                  {children?.map((item) => item)}
                </a>
              );
            }
            return item;
          })}
        </p>
      );
    },

    [BLOCKS.HEADING_5]: (node, children) => {
      return (
        <h3 className="mb-6 mt-10 font-semibold leading-tight">
          {children.map((item) => item)}
        </h3>
      );
    },
    [BLOCKS.HEADING_4]: (node, children) => {
      return (
        <h3 className="mb-6 mt-10 text-xl font-semibold leading-tight">
          {children.map((item) => item)}
        </h3>
      );
    },
    [BLOCKS.HEADING_3]: (node, children) => {
      return (
        <h3 className="mb-6 mt-10 text-xl font-semibold leading-tight">
          {children.map((item) => item)}
        </h3>
      );
    },

    [BLOCKS.HEADING_2]: (node, children) => {
      return (
        <h2 className="mb-6 mt-10 text-2xl font-semibold leading-tight">
          {children.map((item) => item)}
        </h2>
      );
    },

    [BLOCKS.HEADING_1]: (node, children) => {
      return (
        <h1 className="mb-6 mt-10 text-3xl font-bold leading-tight">
          {children.map((item) => item)}
        </h1>
      );
    },

    [BLOCKS.UL_LIST]: (node, children) => {
      return <ul className="mb-12 list-disc ml-6 py-6">{children}</ul>;
    },

    [BLOCKS.OL_LIST]: (node, children) => {
      return <ol className="mb-12 list-disc ml-6 pb-6 pt-0">{children}</ol>;
    },

    [BLOCKS.LIST_ITEM]: (node, children) => {
      return <li className={"-mb-6"}>{children}</li>;
    },

    [BLOCKS.QUOTE]: (node, children) => {
      return (
        <div
          className={
            "border-l-8 border-primary rounded-lg py-12 px-20 bg-gray-100 items-center flex-col mb-6 lg:-mx-12"
          }
        >
          <p className={"font-medium leading-relaxe text-black text-xl"}>
            "{children[0].props.children[0]}"
          </p>
        </div>
      );
    },

    [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
      if (node.data.target.fields) {
        return (
          <div className="border border-gray-300 mb-12 rounded md:-mx-12 lg:-mx-24">
            <Image
              src={`https:${node.data.target.fields?.file.url}`}
              // height={node.data.target.fields?.file.details.image.height}
              // width={node.data.target.fields?.file.details.image.width}
            />
          </div>
        );
      }
    },
  },
};

export const mapContainerStyle = {
  height: "100%",
  width: "100%",
};

export const defaultCenter = {
  lat: 52.130366,
  lng: -95.346771,
};

Date.prototype["addDays"] = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date.toISOString().split("T")[0];
};

Date.prototype["shortener"] = function (theDate) {
  var date = new Date(theDate);
  if (date) {
    return date.toISOString().split("T")[0];
  } else {
    console.error("couldnt return");
  }
};

export const date = new Date();

export const ytIdExtractor = (url) => {
  var regExp =
    /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  var match = url.match(regExp);
  if (match && match[2].length == 11) {
    return match[2];
  } else {
    return null;
  }
};

export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const itemCleaner = (item, value) => {
  if (value?.length > 0 || typeof value == "boolean") {
    if (item == "type") return "Type: " + value;
    if (item == "price")
      return (
        "Price range: " +
        currencyFormatter.format(value[0]) +
        " - " +
        currencyFormatter.format(value[1])
      );
    if (item == "cap")
      return "CAP range: " + value[0] + "% - " + value[1] + "%";
    if (item == "sqft")
      return (
        "Sq Ft range: " +
        value[0].toLocaleString() +
        " - " +
        value[1].toLocaleString()
      );
    if (item == "acres")
      return (
        "Acreage: " +
        value[0].toLocaleString() +
        " - " +
        value[1].toLocaleString()
      );

    if (item == "propertyType") return "Property types: " + value.join(", ");
    if (item == "subType") return "Sub types: " + value.join(", ");
    if (item == "province") return "Province: " + value;
    if (item == "city") return "City: " + value;

    if (item == "keyword") return "Keywords: " + value.join(", ");
    if (item == "offMarket") return "Off-market listings only";
    if (item == "brokerCoop") return "Broker co-op";
    if (item == "postedBy") return "Posted by: " + value;

    if (item == "regions") {
      let nameArray = [];
      value.forEach((item) => {
        if (item.description) {
          nameArray.push(item["description"]);
        } 
      });
      if (nameArray.length > 0) {
        return "Regions: " + nameArray.join(", ");
      }
      else return ''
    }
  }
};

export const month = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const ToolTipCreator = ({ body, ...rest }) => {
  return (
    <span>
      <Tooltip
        label={body}
        placement="auto"
        aria-label={body}
        whiteSpace={"pre-line"}
        zIndex={5000}
      >
        <QuestionIcon
          // color="myGray.300"
          // name={<QuestionIcon />}
          w="16px"
          h="16px"
          marginLeft="0px"
          marginBottom="5px"
          {...rest}
        />
      </Tooltip>
    </span>
  );
};

export const ModToday = () => {
  let date = Date.now();
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  const modifiedToday = mm + "/" + dd + "/" + yyyy;
  return modifiedToday;
};

export const generatePass = () => {
  var chars = "0123456789abcdefghijklmnopqrstuvwxyz-ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  var pass = "";

  for (let i = 0; i < 32; i++) {
    pass += chars[Math.floor(Math.random() * chars.length)];
  }

  return pass;
};

export const formatMessageFileSize = (size: number) => {
  const byteLength = size.toString().length;
  let formattedSize = ''
    if (byteLength <= 6) {
        formattedSize = `${(size / 1e3).toFixed(2)} KB`
    } else {
        formattedSize = `${(size / 1e6).toFixed(2)} MB`
    }

    return formattedSize
};

export const trimLongFileName = (fileName: string, trimAtCharLength: number): string => {
  if (fileName.length >= trimAtCharLength) {
    const trimLengthStart = (trimAtCharLength / 2) - 2
    const trimLengthEnd = fileName.length - trimLengthStart
    return `${fileName.substring(0, trimLengthStart)}...${fileName.substring(trimLengthEnd)}`
  }

  return fileName
}

export function formatMessageTimeStamp(dateParam: Date) {
// const formattedDate = dayjs(dateParam).format('MMM DD hh m a')
// const dateChunks = formattedDate.split(' ')
// // date.setMinutes(date.getMinutes() - date.getTimezoneOffset())
// const month = dateChunks[0]
// const day = dateChunks[1]
// const hours = dateChunks[2]
// const minutes = dateChunks[3]
// const amOrPm = dateChunks[4]

const date = new Date(dateParam)
// date.setMinutes(date.getMinutes() - date.getTimezoneOffset())
const dateChunks = date.toDateString().split(" ")
const month = dateChunks[1]
const day = dateChunks[2]
const year = dateChunks[3]
let hours: string | number = date.getHours()
let minutes: string | number = date.getMinutes()

const amOrPm = hours >= 12 ? "pm" : "am"

hours = 
    (hours === 0 || hours === 12) ? "12" :
    (hours % 12).toString()
minutes = minutes < 10 ? `0${minutes}` : minutes.toString()

return {
    day,
    time: `${hours}:${minutes} ${amOrPm}`,
    displayDate: `${month} ${day}, ${hours}:${minutes} ${amOrPm}`,
    dayMonthYear: `${day}-${month}-${year}`,
    monthDay: `${month} ${day}`
  }
}

export const PhoneNumberFormat = (props: { phone: Value }) => {
  const { phone } = props
  return <span>{formatPhoneNumberIntl(phone)}</span>
}

export const CitySearch = ({ cityData, setCityData }) => {
  const toast = useToast();

  const {
    ready,
    value,
    suggestions: { loading, status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ["(cities)"],
      componentRestrictions: { country: "ca" },
    },
  });

  const handleInput = (e) => {
    setValue(e);
  };

  const handleSelect = async (address) => {
    // const cityClone = [...cityData]
    setValue(address, false);

    try {
      const results = await getGeocode({ address });

      const addressComponents = results[0]["address_components"];

      // Map through the address components, find the object where the types array contains 'locality' and use the long_name of that object to set the city
      let cityName = addressComponents.find(i => i.types.includes("locality") || i.types.includes("administrative_area_level_2") || i.types.includes("administrative_area_level_3")).long_name

      let provinceShort = addressComponents.find(i => i.types.includes("administrative_area_level_1")).short_name
      setCityData({ label: `${cityName}, ${provinceShort}`, value: `${cityName}, ${provinceShort}` });
    } catch (error) {
      clientError(error);
    }
  };

  let optionsArray = [];

  data.forEach((element) => {
    let croppedDesc = element.description.replace(', Canada', '')
    optionsArray.push({
      label: croppedDesc,
      value: croppedDesc,
    });
  });

  return (
    <Box marginBottom="20px" minWidth="250px">
      <Flex
        alignItems={'center'}
        justifyContent="space-between"
      >
        <MyFormLabel htmlFor="area">City</MyFormLabel>
        {!!cityData?.value &&
          <Button
            size="sm"
            leftIcon={<SmallCloseIcon />}
            variant={'link'}
            colorScheme='green'
            onClick={() => {
              setCityData(null)
            }}
          >
            Clear
          </Button>
        }
      </Flex>
      <ReactSelect
        value={cityData}
        name="area"
          // @ts-ignore

        clearable={true}
        searchable
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "#125F3F",
            primary25: "#b1cab3",
            primary50: "#b1cab3",
          },
        })}
        options={optionsArray}
        onChange={(selectedOption) => {
          // @ts-ignore
          handleSelect(selectedOption.value);
        }}
        onInputChange={(e) => {
          e.length > 0 ? handleInput(e) : null;
        }}
        noOptionsMessage={() =>
          loading && status === "OK"
            ? "Loading"
            : value.length < 1
              ? "Begin typing"
              : "No cities found"
        }
      />
    </Box>
  );
};

