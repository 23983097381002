import { Box, Flex, Text, Image } from '@chakra-ui/react'
import React, { useState } from 'react'
import { RealtorsSemiHeader } from '../../styles/typography';
import { OutlineButton, PrimaryButton } from '../button'

export const UploadListingBanner = ({ withHeader, bannerHeader, bannerBody, bannerImage, btnText }) => {

    const [showUploadButton, setShowUploadButton] = useState(false);

    return (
        <Flex flexDir={"column"} my={{ base: "70px", md: "80px", lg: "100px" }}>
            {
                withHeader ?
                    <Text color={"brand.black"} fontSize={{ base: "24px", md: "28px", lg: "32px" }} lineHeight={{ base: "31px", md: "46px", lg: "46px" }} textAlign={"center"} fontWeight={"500"} mb={{ base: "70px", md: "60px", lg: "40px" }}>Let us upload your listing for you</Text>
                    : <></>
            }

            <Flex flexDir={{ base: "column-reverse", md: 'row' }} w={{ md: "90%" }} mx={{ lg: "auto" }} alignItems={"center"} justifyContent={"space-between"}>
                <Box w={{ md: "50%" }} display={"flex"} flexDir={"column"} >
                    {
                        bannerHeader &&
                        <Text w={{ md: "70%" }} color={"black"} textAlign={{ base: "center", md: "start" }} fontWeight={"500"} fontSize={["24px", "24px", "32px"]} lineHeight={"46px"} mb={"30px"}>
                            {bannerHeader}
                        </Text>
                    }
                    <RealtorsSemiHeader text={bannerBody} textAlign={["center", "center", "start"]} w={["100%", "70%"]} ml={["auto", "auto", "0"]} />

                    {!showUploadButton ?
                        <OutlineButton
                            label={btnText || "Upload my Listing Details"}
                            mt={"40px"}
                            ml={{ base: "auto", md: "0" }}
                            mr={{ base: "auto" }}
                            onClick={() => setShowUploadButton(prev => !prev)}
                        />
                        :
                        <Flex
                            mt={"40px"}
                            ml={{ base: "auto", md: "0" }}
                            mr={{ base: "auto" }}
                        >
                            <a href={"mailto:newlisting@zonado.com"}>
                                <PrimaryButton
                                    label={"Email us: newlisting@zonado.com"}
                                    px={"25px"}
                                />
                            </a>
                        </Flex>
                    }

                </Box>
                <Flex w={{ md: "45%" }} justifyContent={"center"} mb={{ base: "30px", md: "0" }} >
                    <Image
                        src={bannerImage}
                        objectFit={"contain"}
                        // width={"402px"} 
                        height={"402px"}
                        w={["100%", "350px", "402px"]} mx={"auto"}
                    />
                </Flex>
            </Flex >
        </Flex>
    )
}